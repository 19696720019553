import "../contentHeader.css";
import { Col, Row } from "react-bootstrap";

//This is the header for the list componenet and the form componenet
const RLContentHeader = ({
  title,
  subtitle,
  children,
  leftButtons,
  rightButtons,
  relatedListTitles,
  handleChangeSelectedRL,
}) => {
  let rlClasses =
    "rl-tab rounded-tl-2xl rounded-tr-2xl z-40 relative shadow-sj font-bold text-white mr-1 lg:mr-2 cursor-pointer px-4 py-3 lg:px-5 lg:py-4 text-sm lg:text-base";

  return (
    <>
      {/* Tabs */}
      <div className='ml-5 mt-5'>
        {relatedListTitles.map((tab) => {
          return (
            <span
              key={tab.title}
              className={
                tab.isSelected
                  ? rlClasses + " sj-bg-primary rl-tab-circle"
                  : rlClasses + " sj-bg-primary-light"
              }
              onClick={() => {
                handleChangeSelectedRL(tab.index);
              }}
            >
              {tab.title}
            </span>
          );
        })}
      </div>
      <div className={"content-header z-40 relative rounded-t-sj"}>
        <Row className='hidden lg:flex mx-4 h-full'>
          <Col md={4} className='flex items-center'>
            {leftButtons}
          </Col>
          <Col md={4} className='flex items-center justify-center'>
            <div className='text-center leading-3'>
              <p className='font-bold text-2xl m-0'>{title}</p>
              <p className='m-0'>for</p>
              <p className='font-bold m-0' style={{ lineHeight: "1.5rem" }}>
                {subtitle}
              </p>
            </div>
          </Col>
          <Col md={4} className='flex items-center justify-end grow'>
            {rightButtons}
          </Col>
        </Row>
        {/* mobile */}
        <div className='flex lg:hidden rounded-t-sj content-header h-[210px] relative z-40 flex justify-between flex-col'>
          <div className='text-center'>
            <p className='font-bold text-2xl m-0 mt-1'>{title}</p>
            <p className='m-0'>for</p>
            <p className='font-bold m-0' style={{ lineHeight: "1.5rem" }}>
              {subtitle}
            </p>
          </div>

          <div className='mx-2 my-4 flex items-center justify-center grow'>
            {rightButtons}
          </div>
          <div className='mb-4 mx-3 flex items-center justify-left grow'>
            {leftButtons}
          </div>
        </div>
      </div>
    </>
  );
};

export default RLContentHeader;
