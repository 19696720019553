import { Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCreateTicketMutation } from "../store/index";

import { addMessage } from "../store/slices/messageSlice";
import requireAuth from "../requireAuth";

const Support = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [submitTicket, ticketResults] = useCreateTicketMutation();

  useEffect(() => {
    if (ticketResults.data) {
      navigate("/");
      dispatch(
        addMessage({
          variant: "success",
          message:
            ticketResults.data?.message || "Ticket Submitted Successfully",
        })
      );
    } else if (ticketResults.isError) {
      dispatch(
        addMessage({
          variant: "danger",
          message: ticketResults.error?.data?.message || "An error occurred",
        })
      );
    }
  }, [ticketResults, dispatch, navigate]);

  const [stepsToReproduce, setStepsToReproduce] = useState("");
  const [expectedBehavior, setExpectedBehavior] = useState("");
  const [description, setDescription] = useState("");

  const [brokenOrQuestion, setBrokenOrQuestion] = useState("question");

  const [question, setquestion] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const [errors, setErrors] = useState({});

  const handleRadioChange = (value) => {
    setBrokenOrQuestion(value);
    setErrors({});
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setSelectedFile(null);
      setErrors({
        ...errors,
        file: undefined,
      });
      return;
    }

    const fileSizeInMB = file.size / (1024 * 1024); // size in MB
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/jpg",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "video/quicktime",
      "video/mp4",
      "video/mpeg",
    ]; // allowed file types

    if (fileSizeInMB > 5) {
      setErrors({
        ...errors,
        file: "File size exceeds 5MB. Please choose a smaller file.",
      });
      setInputKey(Date.now());
    } else {
      if (!allowedFileTypes.includes(file.type)) {
        setErrors({
          ...errors,
          file: "Invalid file type. Please choose a valid file type. Allowed file types are: jpg, jpeg, png, csv, xls, xlsx, doc, docx, mov, mp4, mpeg.",
        });
      } else {
        setErrors({
          ...errors,
          file: undefined,
        });
        setSelectedFile(file);
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const questionVal = { question };
    const brokenVal = { stepsToReproduce, expectedBehavior };

    const values = brokenOrQuestion === "question" ? questionVal : brokenVal;

    try {
      await validationSchema.validate(values, { abortEarly: false });
      setErrors({});

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("type", brokenOrQuestion);

      if (brokenOrQuestion === "question") {
        formData.append("question", question);
      } else {
        formData.append("description", description);
        formData.append("stepsToReproduce", stepsToReproduce);
        formData.append("expectedBehavior", expectedBehavior);
      }

      await submitTicket(formData).unwrap();
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  let yupShape = {
    stepsToReproduce: Yup.string().required("Steps to reproduce is Required"),
    expectedBehavior: Yup.string().required("Expected behavior is Required"),
  };

  if (brokenOrQuestion === "question") {
    yupShape = {
      question: Yup.string().required("Question is Required"),
    };
  }

  const validationSchema = Yup.object().shape(yupShape);

  const content = (
    <>
      <div className='flex flex-row justify-center grow text-2xl mt-10'>
        <div className='flex flex-col justify-center text-center'>
          <div>Get Support</div>
          <div className='text-base'>Tell us about what&#39;s happening</div>
        </div>
      </div>
      <Form
        onSubmit={submitHandler}
        className='max-w-md flex justify-center flex-col mx-auto py-10 px-5'
      >
        <Form.Group className='mb-5' controlId='brokenOrQuestion'>
          <Form.Check
            type='radio'
            label={`I have a Question`}
            id={`question`}
            checked={brokenOrQuestion === "question"}
            onChange={() => handleRadioChange("question")}
          />
          <Form.Check
            type='radio'
            label={`Something is Broken`}
            id={`broken`}
            checked={brokenOrQuestion === "broken"}
            onChange={() => handleRadioChange("broken")}
          />
        </Form.Group>
        {brokenOrQuestion === "question" && (
          <>
            <Form.Group className='mb-3' controlId='question'>
              <Form.Label>
                Question
                {errors.question?.indexOf("Required") !== -1 && (
                  <span className='required font-bold'> *</span>
                )}
              </Form.Label>
              <Form.Control
                type='textarea'
                as='textarea'
                value={question}
                onChange={(e) => {
                  setquestion(e.target.value);
                }}
                className='rounded-lg'
              />
              {errors.question && (
                <span className='form-field-error'>{errors.question}</span>
              )}
            </Form.Group>
          </>
        )}
        {brokenOrQuestion === "broken" && (
          <>
            <Form.Group className='mb-5' controlId='description'>
              <Form.Label>
                Description
                {errors.description?.indexOf("Required") !== -1 && (
                  <span className='required font-bold'> *</span>
                )}
              </Form.Label>
              <Form.Control
                type='textarea'
                as='textarea'
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className='rounded-lg'
              />
              {errors.description && (
                <span className='form-field-error'>{errors.description}</span>
              )}
            </Form.Group>
            <Form.Group className='mb-5' controlId='stepsToReproduce'>
              <Form.Label>
                Steps to Reproduce
                {errors.stepsToReproduce?.indexOf("Required") !== -1 && (
                  <span className='required font-bold'> *</span>
                )}
              </Form.Label>
              <Form.Control
                type='textarea'
                as='textarea'
                value={stepsToReproduce}
                onChange={(e) => {
                  setStepsToReproduce(e.target.value);
                }}
                placeholder={`1) Go to the home page.\n2) Click on the login button.`}
                className='rounded-lg'
              />
              {errors.stepsToReproduce && (
                <span className='form-field-error'>
                  {errors.stepsToReproduce}
                </span>
              )}
            </Form.Group>
            <Form.Group className='mb-5' controlId='expectedBehavior'>
              <Form.Label>
                Expected Behavior
                {errors.expectedBehavior?.indexOf("Required") !== -1 && (
                  <span className='required font-bold'> *</span>
                )}
              </Form.Label>
              <Form.Control
                type='textarea'
                as='textarea'
                value={expectedBehavior}
                onChange={(e) => {
                  setExpectedBehavior(e.target.value);
                }}
                className='rounded-lg'
              />
              {errors.expectedBehavior && (
                <span className='form-field-error'>
                  {errors.expectedBehavior}
                </span>
              )}
            </Form.Group>{" "}
          </>
        )}
        <Form.Group controlId='attachment'>
          <Form.Label>Attachment</Form.Label>
          <Form.Control
            type='file'
            className='rounded-lg'
            onChange={handleFileChange}
            key={inputKey}
          />
          {errors.file && (
            <span className='form-field-error'>{errors.file}</span>
          )}
        </Form.Group>
        <Button
          variant='primary'
          type='submit'
          disabled={ticketResults.isLoading}
          className='w-full my-5 primary-light-btn'
        >
          {ticketResults.isLoading ? "Loading ... " : "Submit"}
        </Button>
      </Form>
    </>
  );
  return content;
};

export default requireAuth(Support);
