import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { titleApi } from "../store/apis/titleApi";
import { useFetchTitlesQuery, useTitleExportListQuery } from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
import { useFinalListConfig } from "../hooks/listHooks";

const TitleList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("title");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (title) => `/title/${title._id}`,
    },
    {
      label: "Number",
      render: (title) => title.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String",
    },
    {
      label: "Title Name",
      render: (title) => title.name,
      sortValue: "name",
      filterValue: "name",
      filterType: "String",
    },

    {
      label: "Department",
      render: (title) => {
        let myValue = "";
        if (title.department) {
          myValue = title?.department?.name;
        }

        return myValue;
      },
      sortValue: "department",
      filterValue: "department",
      filterType: "String",
      inputType: "reference",
    },
    {
      label: "Active", //header label
      render: (title) => title.active.toString(), //How to render the cell
      sortValue: "active",
      filterValue: "active",
      filterType: "Boolean",
    },
    {
      label: "Base Rate", //header label
      render: (title) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(title.baseRate), //How to render the cell
      sortValue: "baseRate",
      filterValue: "baseRate",
      filterType: "Number",
    },
    {
      label: "Base Cost Rate", //header label
      render: (title) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(title.baseCostRate), //How to render the cell
      sortValue: "baseCostRate",
      filterValue: "baseCostRate",
      filterType: "Number",
    },
    {
      label: "Created At", //header label
      render: (title) => {
        return new Date(title.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },
    {
      label: "Updated At", //header label
      render: (title) => {
        return new Date(title.updatedAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "updatedAt",
      filterValue: "updatedAt",
      filterType: "Date",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (title) => {
    return title._id;
  };

  const recordNames = {
    title: "Titles",
    formLink: "title",
    listLink: "title", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={titleApi}
        useFetchQuery={useFetchTitlesQuery}
        useExportQuery={useTitleExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(TitleList);
