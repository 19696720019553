import Modal from "react-bootstrap/Modal";

function ModalSJ({ onClose, actionBar, children, title }) {
  return (
    <>
      <Modal show={true} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>{actionBar}</Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalSJ;
