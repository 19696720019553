import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

//User Specific imports
import { userApi } from "../store/apis/userApi";
import { useFetchUsersQuery } from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery, useUserExportListQuery } from "../store";
import { useFinalListConfig, formatDate } from "../hooks/listHooks";

const UserList = () => {
  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("user");

  //Checks to see if the user is logged in and has access to this page
  //This is only needed if you are using the list view
  useCheckAuth(error, listViewApi);

  //****You have to also setup the list view in the Backend*****
  ////////Config Columns/Headers
  // Columns
  const config = [
    {
      label: "i",
      render: (user) => `/user/${user._id}`,
    },
    {
      label: "Name",
      render: (user) => user.name,
      sortValue: "name",
      filterValue: "name",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Department",
      render: (user) => user?.department?.name || "",
      sortValue: "department",
      filterValue: "department",
      filterType: "String",
      inputType: "reference",
    },
    {
      label: "Manager",
      render: (user) => user?.manager?.name || "",
      sortValue: "manager",
      filterValue: "manager",
      filterType: "String",
      inputType: "reference",
    },
    {
      label: "Email",
      render: (user) => user.email,
      sortValue: "email",
      // render: (user) => <div className={`p-3 m-2 ${user.color}`}></div>,
      filterValue: "email",
      filterType: "String",
    },
    {
      label: "Title",
      render: (user) => user?.title?.name || "",
      sortValue: "title",
      filterValue: "title",
      filterType: "String",
      inputType: "reference",
    },
    {
      label: "Active", //header label
      render: (user) => user.active.toString(), //How to render the cell
      sortValue: "active",
      filterValue: "active",
      filterType: "Boolean",
    },
    {
      label: "Number",
      render: (user) => user.number,
      sortValue: "number",
      // render: (user) => <div className={`p-3 m-2 ${user.color}`}></div>,
      filterValue: "number",
      filterType: "String",
    },
    // {
    //   label: "Created", //header label
    //   render: (user) => user.createdAt, //How to render the cell
    //   sortValue: "createdAt",
    //   filterValue: "createdAt",
    //   filterType: "Date",
    // },
    {
      label: "Created", //header label
      render: (user) => {
        return new Date(user.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },
    {
      label: "Start Date", //header label
      render: (user) => {
        return formatDate(user.startDate) || "";
        // return new Date(user.startDate).toString();
      }, //How to render the cell
      sortValue: "startDate",
      filterValue: "startDate",
      filterType: "Date",
    },
    {
      label: "End Date", //header label
      render: (user) => {
        return formatDate(user.endDate) || "";
        // return new Date(user.endDate).toString();
      }, //How to render the cell
      sortValue: "endDate",
      filterValue: "endDate",
      filterType: "Date",
    },
    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?

  const keyFn = (user) => {
    return user._id;
  };

  const recordNames = {
    title: "Users",
    formLink: "user",
    listLink: "user", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={userApi}
        useFetchQuery={useFetchUsersQuery}
        useExportQuery={useUserExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(UserList);
