import Modal from "react-bootstrap/Modal";
import CloseButton from "./CloseButton";

function ReferenceModal({ onClose, children }) {
  return (
    <>
      <Modal show={true} onHide={onClose} size='xl'>
        {/* <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className='p-0'>
          <CloseButton onClose={onClose} />
          {children}
        </Modal.Body>
        {/* <Modal.Footer>{actionBar}</Modal.Footer> */}
      </Modal>
    </>
  );
}

export default ReferenceModal;
