import List from "../components/List";
import requireAuth from "../requireAuth";

import { tableSecurityLevelApi } from "../store/apis/tableSecurityLevelApi";
import {
  useFetchTableSecurityLevelQuery,
  useTableSecurityLevelExportListQuery,
} from "../store";

const TableSecurityLevel = () => {
  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (tableSecurityLevel) =>
        `/tableSecurityLevel/${tableSecurityLevel._id}`,
    },
    {
      label: "Table",
      render: (tableSecurityLevel) => tableSecurityLevel?.table?.name || "",
      sortValue: "table",
      filterValue: "table",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Action",
      render: (tableSecurityLevel) => tableSecurityLevel.action,
      sortValue: "action",
      filterValue: "action",
      filterType: "String",
    },
  ];

  //What is the key for the data?
  const keyFn = (tableSecurityLevel) => {
    return tableSecurityLevel._id;
  };

  const recordNames = {
    title: "Table Level",
    formLink: "tableSecurityLevel",
    listLink: "tableSecurityLevel", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  return (
    <List
      config={config}
      keyFn={keyFn}
      listApi={tableSecurityLevelApi}
      useFetchQuery={useFetchTableSecurityLevelQuery}
      useExportQuery={useTableSecurityLevelExportListQuery}
      recordNames={recordNames}
    ></List>
  );
};

export default requireAuth(TableSecurityLevel);
