import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const impersonateApi = createApi({
  reducerPath: "impersonate",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/impersonate`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchImpersonateUsers: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "impersonate" }, { type: "impersonateList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),

      Impersonate: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "ImpersonateList" }];
        },
        query: (data) => {
          return {
            url: `/`,
            method: "POST",
            body: data,
          };
        },
      }),
    };
  },
});

export const { useFetchImpersonateUsersQuery, useImpersonateMutation } =
  impersonateApi;
export { impersonateApi };
