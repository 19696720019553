import React from "react";
import "../contentHeader.css";
import "./timeSheetStyles.css";
import { Tooltip, Form, OverlayTrigger, Button } from "react-bootstrap";
import moment from "moment";
import { useState } from "react";

const TimeEntry = ({
  timeEntry,
  timeEntryHeaders,
  handleDayChange,
  isUserActive,
  canUpdate,
  handleDeleteTimeEntry,
  disabledDays,
}) => {
  let content = <></>;

  const [extraClasses, setExtraClasses] = useState("");

  const handleDelete = (timeEntry) => {
    // Call dismiss after 5 seconds
    setTimeout(() => {
      setExtraClasses("");
      handleDeleteTimeEntry(timeEntry);
    }, 600);

    //remove fadeout from class after 5 seconds
    setTimeout(() => {
      setExtraClasses("shift-right");
    }, 100);
  };

  const handleOnFocus = (e) => {
    if (window.matchMedia("(max-width: 1024px)").matches) {
      e.target.select();
    }
  };

  if (timeEntry) {
    let projectTaskClosed = false;
    const localDisabledDays = { ...disabledDays };
    if (timeEntry.isWorkingTime) {
      if (
        timeEntry.project?.active === false ||
        timeEntry.task?.active === false ||
        timeEntry.project?.allowTimeEntry === false ||
        timeEntry.task?.allowTimeEntry === false
      ) {
        projectTaskClosed = true;
      }

      //Get disabled days based on user start and end date
      const projectStartDate = moment.utc(timeEntry.project.startDate);
      const projectEndDate = moment.utc(timeEntry.project.endDate);
      const taskStartDate = moment.utc(timeEntry.task.startDate);
      const taskEndDate = moment.utc(timeEntry.task.endDate);

      for (let i = 0; i < timeEntry.timeEntryDays.length; i++) {
        const dayOfWeek = moment.utc(timeEntry.timeEntryDays[i].date);

        if (dayOfWeek < projectStartDate) {
          localDisabledDays[timeEntry.timeEntryDays[i].date] =
            "This date is before the Project start date";
        } else if (dayOfWeek > projectEndDate) {
          localDisabledDays[timeEntry.timeEntryDays[i].date] =
            "This date is after the Project end date";
        } else if (dayOfWeek < taskStartDate) {
          localDisabledDays[timeEntry.timeEntryDays[i].date] =
            "This date is before the Task start date";
        } else if (dayOfWeek > taskEndDate) {
          localDisabledDays[timeEntry.timeEntryDays[i].date] =
            "This date is after the Task end date";
        }
      }
    }

    let timeEntryTotal = 0;
    let timeEntryDays = timeEntry.timeEntryDays.map((x, index) => {
      timeEntryTotal += parseFloat(x.hours) || 0;
      let myStyle = { backgroundColor: "white" };

      let isDisabled = false;
      if (
        projectTaskClosed ||
        !canUpdate ||
        localDisabledDays[x.date] !== undefined
      ) {
        isDisabled = true;
        myStyle = {
          border: "none",
          backgroundColor:
            timeEntry?.nonWorkingCategory === "Holiday" ? "#CDCDCD" : "#AFAFAF",
          color: "white",
        };
      }

      return (
        <td className='text-center w-7th' key={index} style={myStyle}>
          <OverlayTrigger
            placement='top'
            overlay={
              (isDisabled && !projectTaskClosed && (
                <Tooltip>{localDisabledDays[x.date]}</Tooltip>
              )) || <></>
            }
          >
            <Form.Control
              disabled={isDisabled}
              className='timeEntryDayNumber border-0 lg:border-2 border-solid border-white'
              style={myStyle}
              key={index}
              type={"number"}
              min={0}
              max={24}
              as={"input"}
              onFocus={handleOnFocus}
              name={index}
              // This is needed to be able to update the state of the timeEntryDays array correctly
              // The id is passed as part of the event trigger
              id={timeEntry._id.toString() + "^" + index.toString()}
              value={x.hours.toString() || 0}
              onChange={handleDayChange}
              onWheel={(e) => e.target.blur()}
            ></Form.Control>
          </OverlayTrigger>
        </td>
      );
    });

    let timeEntryDetails = <></>;

    //capitalize the first letter of a string
    const capitalize = (s) => {
      s = s.toString();
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    let nonWorkingClasses = "";
    if (timeEntry.isWorkingTime) {
      timeEntryDetails = (
        <>
          <div>
            <strong>Client</strong> : {timeEntry?.client?.number} -{" "}
            {timeEntry?.client?.name}
          </div>
          <div className='flex flex-col lg:flex-row'>
            <div className='overflow-hidden whitespace-nowrap'>
              <strong>Project</strong> : {timeEntry?.project?.number} -{" "}
              {timeEntry?.project?.name}{" "}
              {!timeEntry?.project?.active ||
              !timeEntry?.project?.allowTimeEntry ? (
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      {`Active : ${capitalize(timeEntry?.project?.active)}`}
                      <br />
                      {`Allow Time Entry : ${capitalize(
                        timeEntry?.project?.allowTimeEntry
                      )}`}
                    </Tooltip>
                  }
                >
                  <img
                    src='/images/Closed.svg'
                    alt='Task Closed'
                    className='moveUp5 mx-2 inline'
                  />
                </OverlayTrigger>
              ) : (
                ""
              )}
            </div>
            <div className='lg:px-3'>
              <strong>Task</strong> : {timeEntry?.task?.number} -{" "}
              {timeEntry?.task?.name}
              {!timeEntry?.task?.active || !timeEntry?.task?.allowTimeEntry ? (
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      {`Active : ${capitalize(timeEntry?.task?.active)}`}
                      <br />
                      {`Allow Time Entry : ${capitalize(
                        timeEntry?.task?.allowTimeEntry
                      )}`}
                    </Tooltip>
                  }
                >
                  <img
                    src='/images/Closed.svg'
                    alt='Task Closed'
                    className='moveUp5 mx-2 inline'
                  />
                </OverlayTrigger>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      );
    } else {
      timeEntryDetails = (
        <>
          <span>
            <strong>Non Working Time</strong>
          </span>
          <p>
            <strong>Reason</strong> : {timeEntry?.nonWorkingCategory}
          </p>
        </>
      );
      if (timeEntry?.nonWorkingCategory === "Holiday")
        nonWorkingClasses = "holiday";
      else nonWorkingClasses = "nonWorkingTime";
    }

    content = (
      <div
        className={`flex items-center justify-between grow my-2 ${extraClasses}`}
      >
        <div></div>
        <div
          className={`content-header-title timeEntry w-[95%] xl:w-[75%] h-[210px] lg:h-[170px] z-40 px-1 md:px-3 my-4 relative ${nonWorkingClasses}`}
        >
          <Button
            disabled={projectTaskClosed || !isUserActive}
            variant='danger'
            className='btn-filter absolute flex justify-center content-center btn-danger-modal shadow-sj-btm'
            style={{ top: "0px", right: "0px" }}
            onClick={() => {
              handleDelete(timeEntry);
            }}
          >
            <img src='/images/X.svg' alt='' className='btn-delete' />
          </Button>
          <div className='timeEntryText mt-3 mb-1 px-3'>{timeEntryDetails}</div>
          <div>
            <div className='timeEntryText'>
              <table className='border-none'>
                <tbody>
                  <tr className={`timeEntryDayHeaders ${nonWorkingClasses}`}>
                    {timeEntryHeaders}
                  </tr>
                </tbody>
              </table>
              <table className='overflow-hidden rounded-2xl border-none shadow-sj-btm'>
                <tbody>
                  <tr className='leading-8 text-center'>{timeEntryDays}</tr>
                </tbody>
              </table>
            </div>
            <div className='absolute content-header-title h-[30px] w-[100px] top-[94%] left-[76%] md:left-[85%] xl:left-[90%]  rounded-sj text-lg'>
              Total{" "}
              {Number.isInteger(timeEntryTotal)
                ? timeEntryTotal
                : timeEntryTotal.toFixed(2)}
            </div>
          </div>
        </div>
        <div></div>
      </div>
    );
  }

  return content;
};

export default TimeEntry;
