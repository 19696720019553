import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const reportingApi = createApi({
  reducerPath: "reporting",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/reporting`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchHomePageData: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "HomePageData" }];
        },
        query: () => {
          return {
            url: "/homepagedata",
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { useFetchHomePageDataQuery } = reportingApi;
export { reportingApi };
