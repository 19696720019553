import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const clientApi = createApi({
  reducerPath: "clients",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/client`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchClients: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "Client" }, { type: "ClientList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchClientById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "Client" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateClientById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "Client" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
      deleteClientById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "ClientList" }];
        },
        query: (id) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
      }),
      createClient: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "ClientList" }];
        },
        query: (data) => {
          return {
            url: `/`,
            method: "POST",
            body: data,
          };
        },
      }),
      clientExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "Client" }, { type: "ClientList" }];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchClientsQuery,
  useFetchClientByIdQuery,
  useUpdateClientByIdMutation,
  useDeleteClientByIdMutation,
  useCreateClientMutation,
  useClientExportListQuery,
} = clientApi;
export { clientApi };
