import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const authApi = createApi({
  reducerPath: "authenticate",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/auth`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchAuth: builder.query({
        query: () => {
          return {
            url: "/",
            method: "GET",
          };
        },
      }),
      //This is just an example protected endpoint
      fetchSecret: builder.query({
        query: () => {
          return {
            url: "/secret",
            method: "GET",
          };
        },
      }),
      registerUser: builder.mutation({
        query: (user) => {
          return {
            url: "/",
            method: "POST",
            body: {
              email: user.email,
              password: user.password,
              confirmPassword: user.confirmPassword,
              firstname: user.firstname,
              lastname: user.lastname,
              company: user.company,
              zipCode: user.zipCode,
              registrationCode: user.registrationCode,
            },
          };
        },
      }),
      loginUser: builder.mutation({
        query: (user) => {
          return {
            url: "/login",
            method: "POST",
            body: {
              email: user.email,
              password: user.password,
            },
          };
        },
      }),
      logoutUser: builder.mutation({
        query: () => {
          return {
            url: "/logout",
            method: "POST",
            body: {},
          };
        },
      }),
      forgotPassword: builder.mutation({
        query: (user) => {
          return {
            url: "/forgotpassword",
            method: "POST",
            body: {
              email: user.email,
            },
          };
        },
      }),
      fetchResetToken: builder.query({
        query: (id) => {
          return {
            url: "/reset/" + id,
            method: "GET",
          };
        },
      }),
      resetPassword: builder.mutation({
        query: (obj) => {
          return {
            url: "/reset",
            method: "POST",
            body: {
              id: obj.id,
              password: obj.password,
              confirmPassword: obj.confirmPassword,
            },
          };
        },
      }),
      verifyEmail: builder.mutation({
        query: (obj) => {
          return {
            url: "/verify",
            method: "POST",
            body: {
              email: obj.email,
            },
          };
        },
      }),
      verifyCode: builder.mutation({
        query: (obj) => {
          return {
            url: "/verifyCode",
            method: "POST",
            body: {
              email: obj.email,
              code: obj.code,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchAuthQuery,
  useFetchSecretQuery,
  useRegisterUserMutation,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useFetchResetTokenQuery,
  useResetPasswordMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation,
  useVerifyCodeMutation,
} = authApi;
export { authApi };
