import List from "../components/List";
import requireAuth from "../requireAuth";

import { listViewApi } from "../store/apis/listViewApi";
import { useFetchListViewQuery, useListViewExportListQuery } from "../store";

const ListViewList = () => {
  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (listView) => `/listView/${listView._id}`,
    },
    {
      label: "Table",
      render: (listView) => listView?.table?.name || "",
      sortValue: "table",
      filterValue: "table",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Column",
      render: (listView) => listView?.column?.name || "",
      sortValue: "column",
      filterValue: "column",
      filterType: "String",
    },
    {
      label: "Label",
      render: (listView) => listView.label,
      sortValue: "label",
      filterValue: "label",
      filterType: "String",
    },
    {
      label: "Order",
      render: (listView) => listView.order,
      sortValue: "order",
      // render: (listView) => <div className={`p-3 m-2 ${listView.color}`}></div>,
      filterValue: "order",
      filterType: "String",
    },
    {
      label: "Active", //header label
      render: (listView) => listView.active.toString(), //How to render the cell
      sortValue: "active",
      filterValue: "active",
      filterType: "Boolean",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (listView) => {
    return listView._id;
  };

  const recordNames = {
    title: "List View",
    formLink: "listView",
    listLink: "listView", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  return (
    <List
      config={config}
      keyFn={keyFn}
      listApi={listViewApi}
      useFetchQuery={useFetchListViewQuery}
      useExportQuery={useListViewExportListQuery}
      recordNames={recordNames}
    ></List>
  );
};

export default requireAuth(ListViewList);
