import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchTitleByIdQuery,
  useUpdateTitleByIdMutation,
  useDeleteTitleByIdMutation,
  useCreateTitleMutation,
  useFetchDepartmentsQuery,
  useFetchUsersQuery,
} from "../store";
import { titleApi } from "../store/apis/titleApi";
import { departmentApi } from "../store/apis/departmentApi";
import { userApi } from "../store/apis/userApi";
import { formViewApi } from "../store/apis/formViewApi";

import { useParams } from "react-router";
import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

const TitleForm = () => {
  const storeApi = {
    fetch: useFetchTitleByIdQuery,
    update: useUpdateTitleByIdMutation,
    delete: useDeleteTitleByIdMutation,
    create: useCreateTitleMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchFormViewByTableQuery("title");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel, secLevelUser] = useSecLevel(["title", "user"]);

  const config = [
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Title Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Title name must be at least 2 characters")
        .required("Title name is required"),
    },
    {
      label: "Department",
      field: "department",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => {
        let myDepartment = "";
        if (data.department) {
          myDepartment = data.department?.name;
        }
        return myDepartment;
      },
      id: (data) => data?.department?._id || "",
      useFetchQuery: useFetchDepartmentsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid department",
          excludeEmptyString: true,
        })
        .required("Department is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (department) => department.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },

          {
            label: "Department Id", //header label
            render: (department) => department.departmentId, //How to render the cell
            sortValue: "departmentId",
            filterValue: "departmentId",
            filterType: "String",
          },
        ],
        keyFn: (department) => {
          return department._id;
        },
        recordNames: {
          title: "Select a Department",
          formLink: "department",
          listLink: "departments",
        },
        listApi: departmentApi,
        useFetchQuery: useFetchDepartmentsQuery,
      },
    },
    {
      label: "Active",
      field: "active",
      render: (data) => data.active,
      defaultValue: true,
      inputType: "checkbox",
    },
    {
      label: "Base Rate",
      field: "baseRate",
      defaultValue: 0,
      inputType: "number",
      render: (data) => data.baseRate,
      validation: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, "Base Rate cannot be negative")
        .required("Base Rate is required"),
    },
    {
      label: "Base Cost",
      field: "baseCostRate",
      defaultValue: 0,
      inputType: "number",
      render: (data) => data.baseCostRate,
      validation: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, "Base Cost Rate cannot be negative")
        .required("Base Cost Rate is required"),
    },
  ];

  const { id } = useParams();

  let relatedListConfig = [
    //User Roles Related List
    {
      secLevel: secLevelUser,

      //Function used as the key on the list
      keyFn: (user) => {
        return user._id;
      },

      recordNames: {
        title: "Users",
        formLink: "user",
        listLink: "user",
      },
      listApi: userApi,

      useFetchQuery: useFetchUsersQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `title._id=${id}^`,
      config: [
        {
          label: "i",
          render: (user) => `/user/${user._id}`,
        },
        {
          label: "User Name",
          render: (user) => user.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Email",
          render: (user) => user.email,
          sortValue: "email",
          filterValue: "email",
          filterType: "String",
        },
        {
          label: "Active",
          render: (user) => user.active.toString(),
          sortValue: "active",
          filterValue: "active",
          filterType: "Boolean",
        },
      ],
    },
  ];

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={titleApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={relatedListConfig}
        secLevel={secLevel}
        formTitle='Title'
        table='title'
        redirect='title'
      />
    ))
  );
};

export default requireAuth(TitleForm);
