import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { timeSheetApi } from "../store/apis/timeSheetApi";
import { useFetchTimeSheetsQuery, useTimeSheetExportListQuery } from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
import { useFinalListConfig, formatDate } from "../hooks/listHooks";

const TimeSheetList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("timeSheet");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (timeSheet) => `/timeSheet/${timeSheet._id}`,
    },
    {
      label: "Number",
      render: (timeSheet) => timeSheet.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String",
    },
    {
      label: "Status",
      render: (timeSheet) => timeSheet.status,
      sortValue: "status",
      filterValue: "status",
      filterType: "String",
    },

    {
      label: "Week Starts", //header label
      render: (timeSheet) => {
        return formatDate(timeSheet.weekStarts) || "";
        // return new Date(timeSheet.startDate).toString();
      }, //How to render the cell
      sortValue: "weekStarts",
      filterValue: "weekStarts",
      filterType: "Date",
    },

    {
      label: "Active", //header label
      render: (timeSheet) => timeSheet.active.toString(), //How to render the cell
      sortValue: "active",
      filterValue: "active",
      filterType: "Boolean",
    },
    {
      label: "User",
      render: (timeSheet) => timeSheet?.user?.name || "",
      sortValue: "user",
      filterValue: "user",
      filterType: "String",
    },
    {
      label: "Total Hours",
      render: (timeSheet) => timeSheet.totalHours,
      sortValue: "totalHours",
      filterValue: "totalHours",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Total Billable Hours",
      render: (timeSheet) => timeSheet.totalBillableHours,
      sortValue: "totalBillableHours",
      filterValue: "totalBillableHours",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Total Non-Billable Hours",
      render: (timeSheet) => timeSheet.totalNonBillableHours,
      sortValue: "totalNonBillableHours",
      filterValue: "totalNonBillableHours",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },

    {
      label: "Created At", //header label
      render: (timeSheet) => {
        return new Date(timeSheet.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },

    {
      label: "Updated At", //header label
      render: (timeSheet) => {
        return new Date(timeSheet.updatedAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "updatedAt",
      filterValue: "updatedAt",
      filterType: "Date",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (timeSheet) => {
    return timeSheet._id;
  };

  const recordNames = {
    title: "Time Sheets",
    formLink: "timeSheet",
    listLink: "timeSheet", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={timeSheetApi}
        useFetchQuery={useFetchTimeSheetsQuery}
        useExportQuery={useTimeSheetExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(TimeSheetList);
