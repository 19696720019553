import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchUsersRoleByIdQuery,
  useUpdateUsersRoleByIdMutation,
  useDeleteUsersRoleByIdMutation,
  useCreateUsersRoleMutation,
  useFetchUsersQuery,
  useFetchRoleQuery,
} from "../store";

import * as Yup from "yup";
import { useSelector } from "react-redux";
import { usersRoleApi } from "../store/apis/usersRoleApi";
import { userApi } from "../store/apis/userApi";
import { roleApi } from "../store/apis/roleApi";

const UsersRoleForm = () => {
  const storeApi = {
    fetch: useFetchUsersRoleByIdQuery,
    update: useUpdateUsersRoleByIdMutation,
    delete: useDeleteUsersRoleByIdMutation,
    create: useCreateUsersRoleMutation,
  };

  //Get front end security from authSlice
  const { secLevel } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      secLevel: state.auth.secLevel.usersRole || false, //use this to limit access to the form
    };
  });

  const config = [
    {
      label: "User",
      field: "user",
      defaultValue: "",
      inputType: "reference",
      refTable: "user",
      refDisplayField: "name",
      render: (data) => data?.user?.name || "",
      id: (data) => data?.user?._id || "",
      useFetchQuery: useFetchUsersQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, "Please select a valid user")
        .required("User is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (user) => user.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Email",
            render: (user) => user.email,
            sortValue: "email",
            // render: (user) => <div className={`p-3 m-2 ${user.color}`}></div>,
            filterValue: "email",
            filterType: "String",
          },
          {
            label: "Active", //header label
            render: (user) => user.active.toString(), //How to render the cell
            sortValue: "active",
            filterValue: "active",
            filterType: "Boolean",
          },
        ],
        keyFn: (user) => {
          return user._id;
        },
        recordNames: {
          title: "Select a User",
          formLink: "user",
          listLink: "users",
        },
        listApi: userApi,
        useFetchQuery: useFetchUsersQuery,
      },
    },

    {
      label: "Role",
      field: "role",
      defaultValue: "",
      inputType: "reference",
      refTable: "role",
      refDisplayField: "name",
      render: (data) => data?.role?.name || "",
      id: (data) => data?.role?._id || "",
      useFetchQuery: useFetchRoleQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, "Please select a valid role")
        .required("Role is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (role) => role.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
        ],
        keyFn: (role) => {
          return role._id;
        },
        recordNames: {
          title: "Select a Role",
          formLink: "role",
          listLink: "role",
        },
        listApi: roleApi,
        useFetchQuery: useFetchRoleQuery,
      },
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  return (
    <FormSJ
      storeApi={storeApi}
      apiToReset={usersRoleApi}
      config={config}
      validationSchema={validationSchema}
      relatedListConfig={[]}
      secLevel={secLevel}
      formTitle="User's Role"
      table='usersRole'
      redirect='usersRole'
    />
  );
};

export default requireAuth(UsersRoleForm);
