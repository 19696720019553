import List from "../components/List";
import requireAuth from "../requireAuth";

import { fieldSecurityLevelApi } from "../store/apis/fieldSecurityLevelApi";
import {
  useFetchFieldSecurityLevelQuery,
  useFieldSecurityLevelExportListQuery,
} from "../store";

const FieldSecurityLevel = () => {
  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (fieldSecurityLevel) =>
        `/fieldSecurityLevel/${fieldSecurityLevel._id}`,
    },
    {
      label: "Table",
      render: (fieldSecurityLevel) => fieldSecurityLevel?.table?.name || "",
      sortValue: "table",
      filterValue: "table",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Column",
      render: (fieldSecurityLevel) => fieldSecurityLevel?.column?.name || "",
      sortValue: "column",
      filterValue: "column",
      filterType: "String",
    },
    {
      label: "Action",
      render: (fieldSecurityLevel) => fieldSecurityLevel.action,
      sortValue: "action",
      filterValue: "action",
      filterType: "String",
    },
  ];

  //What is the key for the data?
  const keyFn = (fieldSecurityLevel) => {
    return fieldSecurityLevel._id;
  };

  const recordNames = {
    title: "Field Level",
    formLink: "fieldSecurityLevel",
    listLink: "fieldSecurityLevel", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  return (
    <List
      config={config}
      keyFn={keyFn}
      listApi={fieldSecurityLevelApi}
      useFetchQuery={useFetchFieldSecurityLevelQuery}
      useExportQuery={useFieldSecurityLevelExportListQuery}
      recordNames={recordNames}
    ></List>
  );
};

export default requireAuth(FieldSecurityLevel);
