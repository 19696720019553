// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "./header.css";
import { toggleMobileNav } from "../store/slices/utilSlice";
import { useLocation } from "react-router-dom";

const Header = () => {
  // const { name, roles, company } = useSelector((state) => {
  //   //state is the entire state object
  //   //You just need to reutnr the piece that you need
  //   return {
  //     name: state.auth.name,
  //     roles: state.auth.roles,
  //     company: state.auth.company,
  //   };
  // });
  const dispatch = useDispatch();
  const name = useSelector((state) => state.auth.name);
  const roles = useSelector((state) => state.auth.roles);
  const company = useSelector((state) => state.auth.company);
  const isMobileNavOpen = useSelector((state) => state.util.isMobileNavOpen);

  //This is used to populate the form with data from the api
  const location = useLocation();
  //http://localhost:3000/rateCard/66579c2991e32d69ff93f888
  //['', 'rateCard', '66579c2991e32d69ff93f888']
  const path = location.pathname.split("/");
  let recordType = path[1];

  const helpLinkMap = {
    timeSheet: "timesheets",
    client: "work",
    project: "work",
    task: "work",
    department: "deptandtitle",
    title: "deptandtitle",
    user: "users",
    rateCard: "revenue",
    rate: "revenue",
    holiday: "holidays",
    pivotReport: "reporting",
    numberMaintenance: "customizeinstance",
    listview: "customizeinstance",
    formview: "customizeinstance",
    role: "roles",
    tableSecurityLevel: "tableaccess",
    fieldSecurityLevel: "fieldaccess",
    securityLevel: "securitylevels",
  };

  const helpLinkBase = process.env.REACT_APP_HELP_URL;
  let helpLink = helpLinkBase + "/overview";

  if (helpLinkMap[recordType])
    helpLink = helpLinkBase + "/" + helpLinkMap[recordType];

  const handleToggleMobileNav = () => {
    dispatch(toggleMobileNav());
  };

  function getInitials(str) {
    const spaceIndex = str.indexOf(" ");

    if (spaceIndex === -1 || spaceIndex === str.length - 1) {
      if (str.charAt(0)) return str.charAt(0);
      return null;
    }

    return str.charAt(0) + str.charAt(spaceIndex + 1);
  }

  return (
    <div className='h-20'>
      <Navbar className='h-20 header sj-bg-primary-dark ml-auto pr-2 nav-bottom-border fixed top-0 left-0 z-100 w-full'>
        <LinkContainer to={company ? "/" : "/login"} className='cursor-pointer'>
          <div className='lg:min-w-[350px] min-w-[200px]'>
            <img
              className='ml-5 mb-2 lg:w-[190px]'
              src='../images/Logo.svg'
              alt='Logo'
            />
          </div>
        </LinkContainer>

        <div className='hidden w-full md:flex justify-center'>
          <div className='text-2xl font-bold text-white text-right'>
            {company}
          </div>
        </div>

        <div className='flex flex-row grow justify-end'>
          {roles && (
            <>
              <div className='flex flex-col justify-center mx-4 w-10'>
                <a href={helpLink} target='_blank' rel='noreferrer'>
                  <img
                    src='../images/Help Outline.svg'
                    alt='help'
                    className='w-8'
                  />
                </a>
              </div>
              <LinkContainer
                to='/support'
                className='hidden md:flex md:flex-col mx-4 justify-center hover:text-white hover:underline'
              >
                <Nav.Link className='header-link'>Support</Nav.Link>
              </LinkContainer>
              <LinkContainer
                to='/logout'
                className='hidden md:flex md:flex-col mx-4 justify-center hover:text-white hover:underline'
              >
                <Nav.Link className='header-link'>Logout</Nav.Link>
              </LinkContainer>
              <div className=' mx-5 hidden md:flex flex-col justify-center'>
                <div className='circle flex justify-center'>
                  {getInitials(name)}
                </div>
              </div>
              {/* Mobile */}
              {isMobileNavOpen && (
                <img
                  onClick={() => handleToggleMobileNav()}
                  className='flex mx-4 md:hidden cursor-pointer'
                  src='../images/Mobile - Close.svg'
                  alt='menu'
                />
              )}
              {!isMobileNavOpen && (
                <img
                  onClick={() => handleToggleMobileNav()}
                  className='flex mx-3 md:hidden cursor-pointer'
                  src='../images/Mobile - Hamburger.svg'
                  alt='menu'
                />
              )}
            </>
          )}
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
