import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const tableSecurityLevelApi = createApi({
  reducerPath: "tableSecurityLevel",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/tableSecurityLevel`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchTableSecurityLevel: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [
            { type: "TableSecurityLevel" },
            { type: "TableSecurityLevelList" },
          ];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchTableSecurityLevelById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "TableSecurityLevel" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateTableSecurityLevelById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "TableSecurityLevel" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
      tableSecurityLevelExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [
            { type: "TableSecurityLevel" },
            { type: "TableSecurityLevelList" },
          ];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchTableSecurityLevelQuery,
  useFetchTableSecurityLevelByIdQuery,
  useUpdateTableSecurityLevelByIdMutation,
  useFetchTableSecurityLevelByTableQuery,
  useTableSecurityLevelExportListQuery,
} = tableSecurityLevelApi;
export { tableSecurityLevelApi };
