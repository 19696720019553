import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useLogoutUserMutation } from "../store/apis/authApi";
import { reset } from "../store";
import { LinkContainer } from "react-router-bootstrap";

const Logout = () => {
  const dispatch = useDispatch();
  const [logoutUser] = useLogoutUserMutation();

  useEffect(() => {
    dispatch(reset());
    logoutUser();
  }, [dispatch, logoutUser]);

  return (
    <div className='flex flex-col text-center justify-center w-full my-20'>
      <LinkContainer to='/login'>
        <div className='flex w-full justify-center cursor-pointer'>
          <img
            className='logo mb-10'
            src='../images/Logo Dark.svg'
            alt='Logo'
          />
        </div>
      </LinkContainer>
      <>
        <h1 className='text-center text-2xl py-1'>Logout Successful</h1>
        <p className='text-center mb-5 pt-4'>
          Close your browser or click login below.
        </p>
        <LinkContainer to='/login'>
          <div className='hover-underline cursor-pointer text-blue-500 mt-1'>
            Login
          </div>
        </LinkContainer>
      </>
      {/* <div className='flex justify-center my-5'>
        <img
          src='/images/Logout.png'
          alt='Logout Successful'
          className='w-10/12 lg:w-8/12 xl:w-1/3'
        />
      </div> */}
    </div>
  );
};

export default Logout;
