import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ConditionalApp from "./components/ConditionalApp";

import Homepage from "./pages/Homepage";
import LogIn from "./pages/LogIn";
import Register from "./pages/Register";
import UserList from "./pages/UserList";
import UserForm from "./pages/UserForm";
import UsersRoleForm from "./pages/UsersRoleForm";
import PasswordResetConfirm from "./pages/PasswordResetConfirm";
import PasswordReset from "./pages/PasswordReset";
import DepartmentForm from "./pages/DepartmentForm";
import ListViewList from "./pages/ListViewList";
import DepartmentList from "./pages/DepartmentList";
import ListViewForm from "./pages/ListViewForm";
import FormViewList from "./pages/FormViewList";
import FormViewForm from "./pages/FormViewForm";
import FieldSecurityLevelList from "./pages/FieldSecurityLevelList";
import FieldSecurityLevelForm from "./pages/FieldSecurityLevelForm";
import FieldSecurityLevelsRoleForm from "./pages/FieldSecurityLevelsRoleForm";
import TableSecruityLevelList from "./pages/TableSecruityLevelList";
import TableSecurityLevelForm from "./pages/TableSecurityLevelForm";
import TableSecurityLevelsRoleForm from "./pages/TableSecurityLevelsRoleForm";
import RoleList from "./pages/RoleList";
import RoleForm from "./pages/RoleForm";
import ClientList from "./pages/ClientList";
import ClientForm from "./pages/ClientForm";
import ProjectList from "./pages/ProjectList";
import ProjectForm from "./pages/ProjectForm";
import TaskList from "./pages/TaskList";
import TaskForm from "./pages/TaskForm";
import TitleList from "./pages/TitleList";
import TitleForm from "./pages/TitleForm";
import RateCardList from "./pages/RateCardList";
import RateCardForm from "./pages/RateCardForm";
import RateList from "./pages/RateList";
import RateForm from "./pages/RateForm";
import NumberMaintenanceList from "./pages/NumberMaintenanceList";
import NumberMaintenanceForm from "./pages/NumberMaintenanceForm";
import TimeSheetList from "./pages/TimeSheetList";
import TimeSheetForm from "./pages/TimeSheetForm";
import HolidayForm from "./pages/HolidayForm";
import HolidayList from "./pages/HolidayList";
import PivotReport from "./pages/PivotReport";
import Logout from "./pages/Logout";
import ErrorBoundary from "./pages/ErrorBoundary";
import Support from "./pages/Support";
import SecurityLevelList from "./pages/SecurityLevelList";
import SecurityLevelForm from "./pages/SecurityLevelForm";

// import BlockerTest from "./pages/BlockerTest";
// import Test from "./pages/Test";
/*
https://getbootstrap.com/docs/5.2/getting-started/download/#npm

Bootstrap notes - I did npm install react-bootsrtap & npm install bootstrap@5.2.3 npm i react-router-bootstrap

added this to the head of the index.html file 
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4" crossorigin="anonymous"></script>

*/

/*
11/1/2023 - Updated to 6.4 router using the data router API
https://reactrouter.com/en/main/routers/picking-a-router#using-v64-data-apis

*/

import FourOFour from "./pages/FourOFour";
import Impersonate from "./pages/Impersonate";

const App = () => {
  //Changed the return value from an object to a single value so that the === operator will work
  //https://react-redux.js.org/api/hooks#equality-comparisons-and-updates

  //This was split out to reduce the number of re-renders.
  //Multiple useSelector Calls: You can call useSelector multiple times within
  //a single function component, with each call returning a single field value3.
  //This way, a change in one field won’t cause re-renders due to other fields.

  const routes = [
    { path: "/", element: <Homepage />, errorElement: <ErrorBoundary /> },
    { path: "/login", element: <LogIn />, errorElement: <ErrorBoundary /> },
    {
      path: "/register",
      element: <Register />,
      errorElement: <ErrorBoundary />,
    },
    { path: "/user", element: <UserList />, errorElement: <ErrorBoundary /> },
    {
      path: "/user/:id",
      element: <UserForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/usersRole",
      element: <UsersRoleForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/usersRole/:id",
      element: <UsersRoleForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/department/:id",
      element: <DepartmentForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/listView",
      element: <ListViewList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/department",
      element: <DepartmentList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/listView/:id",
      element: <ListViewForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/formView",
      element: <FormViewList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/formView/:id",
      element: <FormViewForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/fieldSecurityLevel",
      element: <FieldSecurityLevelList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/fieldSecurityLevel/:id",
      element: <FieldSecurityLevelForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/fieldSecurityLevelsRole/:id",
      element: <FieldSecurityLevelsRoleForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/tableSecurityLevel",
      element: <TableSecruityLevelList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/tableSecurityLevel/:id",
      element: <TableSecurityLevelForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/tableSecurityLevelsRole/:id",
      element: <TableSecurityLevelsRoleForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/securityLevel",
      element: <SecurityLevelList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/securityLevel/:id",
      element: <SecurityLevelForm />,
      errorElement: <ErrorBoundary />,
    },
    { path: "/role", element: <RoleList />, errorElement: <ErrorBoundary /> },
    {
      path: "/role/:id",
      element: <RoleForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/client",
      element: <ClientList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/client/:id",
      element: <ClientForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/project",
      element: <ProjectList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/project/:id",
      element: <ProjectForm />,
      errorElement: <ErrorBoundary />,
    },
    { path: "/task", element: <TaskList />, errorElement: <ErrorBoundary /> },
    {
      path: "/task/:id",
      element: <TaskForm />,
      errorElement: <ErrorBoundary />,
    },
    { path: "/title", element: <TitleList />, errorElement: <ErrorBoundary /> },
    {
      path: "/title/:id",
      element: <TitleForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/rateCard",
      element: <RateCardList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/rateCard/:id",
      element: <RateCardForm />,
      errorElement: <ErrorBoundary />,
    },
    { path: "/rate", element: <RateList />, errorElement: <ErrorBoundary /> },
    {
      path: "/rate/:id",
      element: <RateForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/timeSheet",
      element: <TimeSheetList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/timeSheet/:id",
      element: <TimeSheetForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/passwordresetconfirm",
      element: <PasswordResetConfirm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/reset/:id",
      element: <PasswordReset />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/numberMaintenance",
      element: <NumberMaintenanceList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/numberMaintenance/:id",
      element: <NumberMaintenanceForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/holiday",
      element: <HolidayList />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/holiday/:id",
      element: <HolidayForm />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/pivotReport/:id",
      element: <PivotReport />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/support",
      element: <Support />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/impersonate",
      element: <Impersonate />,
      errorElement: <ErrorBoundary />,
    },
    { path: "/logout", element: <Logout />, errorElement: <ErrorBoundary /> },

    // { path: "/example", element: <BlockerTest /> , errorElement : <ErrorBoundary/>},
    // { path: "/test", element: <Test />, errorElement: <ErrorBoundary /> },
    { path: "*", element: <FourOFour />, errorElement: <ErrorBoundary /> },
  ];

  const router = createBrowserRouter([
    {
      element: <ConditionalApp />,
      errorElement: <ErrorBoundary />,
      children: routes,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
