import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { clientApi } from "../store/apis/clientApi";
import { useFetchClientsQuery, useClientExportListQuery } from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
import { useFinalListConfig } from "../hooks/listHooks";

const ClientList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("client");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (client) => `/client/${client._id}`,
    },
    {
      label: "Name",
      render: (client) => client.name,
      sortValue: "name",
      filterValue: "name",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Client Id",
      render: (client) => client.clientId,
      sortValue: "clientId",
      filterValue: "clientId",
      filterType: "String",
    },
    {
      label: "Rate Card",
      render: (client) => client?.rateCard?.name || "",
      sortValue: "rateCard",
      filterValue: "rateCard",
      filterType: "String",
      inputType: "reference",
    },
    {
      label: "Number",
      render: (client) => client.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String",
    },
    {
      label: "Created", //header label
      render: (client) => {
        return new Date(client.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },
    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (client) => {
    return client._id;
  };

  const recordNames = {
    title: "Clients",
    formLink: "client",
    listLink: "client", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={clientApi}
        useFetchQuery={useFetchClientsQuery}
        useExportQuery={useClientExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(ClientList);
