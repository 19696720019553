import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchRateCardByIdQuery,
  useUpdateRateCardByIdMutation,
  useDeleteRateCardByIdMutation,
  useCreateRateCardMutation,
  useFetchClientsQuery,
  useFetchProjectsQuery,
  useFetchTasksQuery,
  useFetchRatesQuery,
} from "../store";
import { rateCardApi } from "../store/apis/rateCardApi";
import { clientApi } from "../store/apis/clientApi";
import { formViewApi } from "../store/apis/formViewApi";
import { projectApi } from "../store/apis/projectApi";
import { taskApi } from "../store/apis/taskApi";
import { rateApi } from "../store/apis/rateApi";

import { useParams } from "react-router";
import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";
import { Container } from "react-bootstrap";

import Loader from "../components/Loader";

const RateCardForm = () => {
  const storeApi = {
    fetch: useFetchRateCardByIdQuery,
    update: useUpdateRateCardByIdMutation,
    delete: useDeleteRateCardByIdMutation,
    create: useCreateRateCardMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchFormViewByTableQuery("rateCard");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [
    secLevel,
    secLevelClient,
    secLevelProject,
    secLevelTask,
    secLevelRate,
  ] = useSecLevel(["rateCard", "client", "project", "task", "rate"]);

  const config = [
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Rate Card Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "RateCard name must be at least 2 characters")
        .required("RateCard name is required"),
    },
    {
      label: "Active",
      field: "active",
      render: (data) => data.active,
      defaultValue: true,
      inputType: "checkbox",
    },
    {
      label: "Base Rate",
      field: "baseRate",
      defaultValue: 0,
      inputType: "number",
      render: (data) => data.baseRate,
      validation: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, "Base Rate cannot be negative")
        .nullable(),
    },
    {
      label: "Description",
      field: "description",
      defaultValue: "",
      inputType: "textarea",
      render: (data) => data.description,
    },
  ];

  const { id } = useParams();

  let relatedListConfig = [
    // Client Roles Related List
    {
      secLevel: secLevelClient,

      //Function used as the key on the list
      keyFn: (client) => {
        return client._id;
      },

      recordNames: {
        title: "Clients",
        formLink: "client",
        listLink: "client",
      },
      listApi: clientApi,

      useFetchQuery: useFetchClientsQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `rateCard._id=${id}^`,
      config: [
        {
          label: "i",
          render: (client) => `/client/${client._id}`,
        },
        {
          label: "Client Name",
          render: (client) => client.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Client ID",
          render: (client) => client.clientId,
          sortValue: "clientId",
          filterValue: "clientId",
          filterType: "String",
        },
        {
          label: "Number",
          render: (client) => client.number,
          sortValue: "number",
          filterValue: "number",
          filterType: "String",
        },
      ],
    },

    {
      secLevel: secLevelProject,

      //Function used as the key on the list
      keyFn: (project) => {
        return project._id;
      },

      recordNames: {
        title: "Projects",
        formLink: "project",
        listLink: "project",
      },
      listApi: projectApi,

      useFetchQuery: useFetchProjectsQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `rateCard._id=${id}^`,
      config: [
        {
          label: "i",
          render: (project) => `/project/${project._id}`,
        },
        {
          label: "Project Number",
          render: (project) => project.number,
          sortValue: "number",
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Project Name",
          render: (project) => project.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Status",
          render: (project) => project.status,
          sortValue: "status",
          filterValue: "status",
          filterType: "String",
        },
      ],
    },
    {
      secLevel: secLevelTask,

      //Function used as the key on the list
      keyFn: (task) => {
        return task._id;
      },

      recordNames: {
        title: "Tasks",
        formLink: "task",
        listLink: "task",
      },
      listApi: taskApi,

      useFetchQuery: useFetchTasksQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `rateCard._id=${id}^`,
      config: [
        {
          label: "i",
          render: (task) => `/task/${task._id}`,
        },
        {
          label: "Task Number",
          render: (task) => task.number,
          sortValue: "number",
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Task Name",
          render: (task) => task.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Status",
          render: (task) => task.status,
          sortValue: "status",
          filterValue: "status",
          filterType: "String",
        },
        {
          label: "Project Name",
          render: (task) => task?.project?.name,
          sortValue: "project",
          filterValue: "project",
          filterType: "String",
        },
      ],
    },
    {
      secLevel: secLevelRate,

      //Function used as the key on the list
      keyFn: (rate) => {
        return rate._id;
      },

      recordNames: {
        title: "Rates",
        formLink: "rate",
        listLink: "rate",
      },
      listApi: rateApi,

      useFetchQuery: useFetchRatesQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `rateCard._id=${id}^`,
      config: [
        {
          label: "i",
          render: (rate) => `/rate/${rate._id}`,
        },
        {
          label: "Number",
          render: (rate) => rate.number,
          sortValue: "number",
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Title",
          render: (rate) => rate?.title?.name,
          sortValue: "title",
          filterValue: "title",
          filterType: "String",
        },
        {
          label: "Rate",
          render: (rate) => rate.rate,
          sortValue: "rate",
          filterValue: "rate",
          filterType: "Number",
        },
      ],
    },
  ];

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={rateCardApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={relatedListConfig}
        secLevel={secLevel}
        formTitle='Rate Card'
        table='rateCard'
        redirect='rateCard'
      />
    ))
  );
};

export default requireAuth(RateCardForm);
