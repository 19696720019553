import { clientApi, useFetchClientsQuery } from "../store/apis/clientApi";
import { projectApi, useFetchProjectsQuery } from "../store/apis/projectApi";
import { taskApi, useFetchTasksQuery } from "../store/apis/taskApi";
import {
  departmentApi,
  useFetchDepartmentsQuery,
} from "../store/apis/departmentApi";
import { titleApi, useFetchTitlesQuery } from "../store/apis/titleApi";
import { useFetchUsersQuery, userApi } from "../store/apis/userApi";
import {
  useFetchPivotFiltersQuery,
  pivotFilterApi,
} from "../store/apis/pivotFilterApi";

const clientConfig = {
  name: "Clients",
  img: "Clients Icon.svg",
  isDisabled: false,
  refModalListConfig: {
    config: [
      {
        label: "Name",
        render: (client) => client.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Number",
        render: (client) => client.number,
        sortValue: "number",
        // render: (client) => <div className={`p-3 m-2 ${client.color}`}></div>,
        filterValue: "number",
        filterType: "String",
      },
      {
        label: "Client ID", //header label
        render: (client) => client.clientId, //How to render the cell
        sortValue: "clientId",
        filterValue: "clientId",
        filterType: "String",
      },
    ],
    keyFn: (client) => {
      return client._id;
    },
    recordNames: {
      title: "Select a Client",
      formLink: "client",
      listLink: "clients",
    },
    listApi: clientApi,
    useFetchQuery: useFetchClientsQuery,
  },
};

const projectConfig = {
  name: "Projects",
  img: "Projects Icon.svg",
  isDisabled: false,
  refModalListConfig: {
    config: [
      {
        label: "Number",
        render: (project) => project.number,
        sortValue: "number",
        // render: (project) => <div className={`p-3 m-2 ${project.color}`}></div>,
        filterValue: "number",
        filterType: "String",
      },
      {
        label: "Name",
        render: (project) => project.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },

      {
        label: "Client Name",
        render: (project) => project?.client?.name,
        sortValue: "client",
        filterValue: "client",
        filterType: "String",
      },
    ],
    keyFn: (project) => {
      return project._id;
    },
    recordNames: {
      title: "Select a Project",
      formLink: "project",
      listLink: "projects",
    },
    listApi: projectApi,
    useFetchQuery: useFetchProjectsQuery,
  },
};

const taskConfig = {
  name: "Tasks",
  img: "Tasks Icon.svg",
  isDisabled: false,
  refModalListConfig: {
    config: [
      {
        label: "Number",
        render: (task) => task.number,
        sortValue: "number",
        // render: (task) => <div className={`p-3 m-2 ${task.color}`}></div>,
        filterValue: "number",
        filterType: "String",
      },
      {
        label: "Name",
        render: (task) => task.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },

      {
        label: "Project Name",
        render: (task) => task?.project?.name,
        sortValue: "project",
        filterValue: "project",
        filterType: "String",
      },
    ],
    keyFn: (task) => {
      return task._id;
    },
    recordNames: {
      title: "Select a Task",
      formLink: "task",
      listLink: "tasks",
    },
    listApi: taskApi,
    useFetchQuery: useFetchTasksQuery,
  },
};

const departmentConfig = {
  name: "Departments",
  img: "Departments Icon.svg",
  isDisabled: false,
  refModalListConfig: {
    config: [
      {
        label: "Number",
        render: (department) => department.number,
        sortValue: "number",
        filterValue: "number",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Name",
        render: (department) => department.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
    ],
    keyFn: (department) => {
      return department._id;
    },
    recordNames: {
      title: "Select a Department",
      formLink: "department",
      listLink: "departments",
    },
    listApi: departmentApi,
    useFetchQuery: useFetchDepartmentsQuery,
  },
};

const titleConfig = {
  name: "Titles",
  img: "Titles Icon.svg",
  isDisabled: false,
  refModalListConfig: {
    config: [
      {
        label: "Number",
        render: (title) => title.number,
        sortValue: "number",
        filterValue: "number",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Name",
        render: (title) => title.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Department Name",
        render: (title) => title?.department?.name,
        sortValue: "department",
        filterValue: "department",
        filterType: "String",
      },
    ],
    keyFn: (title) => {
      return title._id;
    },
    recordNames: {
      title: "Select a Title",
      formLink: "title",
      listLink: "titles",
    },
    listApi: titleApi,
    useFetchQuery: useFetchTitlesQuery,
  },
};

const userConfig = {
  name: "Users",
  img: "Users Icon.svg",
  isDisabled: false,
  refModalListConfig: {
    config: [
      {
        label: "Number",
        render: (user) => user.number,
        sortValue: "number",
        filterValue: "number",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Name",
        render: (user) => user.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Title Name",
        render: (user) => user?.title?.name,
        sortValue: "title",
        filterValue: "title",
        filterType: "String",
      },
    ],
    keyFn: (user) => {
      return user._id;
    },
    recordNames: {
      title: "Select a User",
      formLink: "user",
      listLink: "users",
    },
    listApi: userApi,
    useFetchQuery: useFetchUsersQuery,
  },
};

const pivotFilterConfig = {
  name: "Pivot Filters",
  img: "PivotReport Icon.svg",
  isDisabled: false,
  refModalListConfig: {
    config: [
      {
        label: "Name",
        render: (pivotFilter) => pivotFilter.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      //If you want to add a timezone date you have to add it within the page b/c i cant use useSelector here to get the users timezone
    ],
    keyFn: (pivotFilter) => {
      return pivotFilter._id;
    },
    recordNames: {
      title: "Select a Filter",
      formLink: "pivotFilter",
      listLink: "pivotFilters",
    },
    listApi: pivotFilterApi,
    useFetchQuery: useFetchPivotFiltersQuery,
  },
};

export {
  clientConfig,
  projectConfig,
  taskConfig,
  departmentConfig,
  titleConfig,
  userConfig,
  pivotFilterConfig,
};
