const useEncodedFilter = (encodedFilter, config) => {
  //   console.log(encodedFilter);

  // name=1^ORnameISLIKE2^nameSTARTSWITH3^createdAt=2023-04-05
  let filterArray = [];
  try {
    const filters = encodedFilter.split("^");

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i];

      if (filter === "") continue;

      let filterObj = {};

      if (filter.startsWith("OR")) {
        filterObj.logic = "^OR";
        filter = filter.slice(2, filter.length);
      } else if (filter.startsWith("OOR")) {
        filterObj.logic = "^OOR";
        filter = filter.slice(3, filter.length);
      } else filterObj.logic = "^";

      const conditions = [
        "=",
        ">",
        "<",
        "!=",
        "NOTLIKE",
        "ISLIKE",
        "STARTSWITH",
        "ENDSWITH",
        "N=",
        "N>",
        "N<",
        "N!=",
      ];
      let minIndex = -1;

      for (let j = 0; j < conditions.length; j++) {
        const condition = conditions[j];

        let conditionIndex = filter.indexOf(condition);

        if (
          //eslint-disable-next-line
          conditionIndex != -1 &&
          (conditionIndex < minIndex || minIndex === -1)
        ) {
          filterObj.condition = condition;
        }
      }

      let conditionIndex = filter.indexOf(filterObj.condition);
      filterObj.field = filter.slice(0, conditionIndex);
      filterObj.value = filter.slice(
        conditionIndex + filterObj.condition.length,
        filter.length
      );

      const conditionsMap = {
        "=": "is",
        "!=": "is not",
        ISLIKE: "contains",
        NOTLIKE: "does not contain",
        STARTSWITH: "starts with",
        ENDSWITH: "ends with",
        ">": "after",
        "<": "before",
        "N=": "equals",
        "N!=": "does not equal",
        "N>": "is greater than",
        "N<": "is less than",
      };

      filterObj.comp = conditionsMap[filterObj.condition];

      let hasConfig = false;
      for (let j = 0; j < config.length; j++) {
        const item = config[j];
        if (item.filterValue === filterObj.field) {
          hasConfig = true;
          filterObj.field = {
            label: item.label,
            name: item.filterValue,
            type: item.filterType,
          };
          break;
        }
      }

      if (hasConfig) filterArray.push(filterObj);
    }

    filterArray[0].logic = "";

    return filterArray;
  } catch (error) {
    return [];
  }
};
export { useEncodedFilter };
