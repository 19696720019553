import React from "react";

const FourOFour = () => {
  return (
    <div className='flex justify-center w-full my-20'>
      <img
        src='/images/404.png'
        alt='Page not found'
        className='w-10/12 lg:w-8/12 xl:w-1/3'
      />
    </div>
  );
};

export default FourOFour;
