import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchFieldSecurityLevelByIdQuery,
  useUpdateFieldSecurityLevelByIdMutation,
} from "../store";
import { fieldSecurityLevelApi } from "../store/apis/fieldSecurityLevelApi";
import { useSecLevel } from "../hooks/formHooks";

import * as Yup from "yup";
import { useParams } from "react-router";

import { fieldSecurityLevelsRoleApi } from "../store/apis/fieldSecurityLevelsRoleApi";
import { useFetchFieldSecurityLevelsRoleQuery } from "../store";

const FieldSecurityLevelForm = () => {
  const storeApi = {
    fetch: useFetchFieldSecurityLevelByIdQuery,
    update: useUpdateFieldSecurityLevelByIdMutation,
    //I dont have a create or delete for this table so i am using a placeholder so formSJ doesnt break
    create: () => {
      const create = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [create, { data, error, isLoading }];
    },
    delete: () => {
      const deleteById = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [deleteById, { data, error, isLoading }];
    },
  };

  const [secLevel, secLevelfieldSecurityLevelsRole] = useSecLevel([
    "fieldSecurityLevel",
    "fieldSecurityLevelsRole",
  ]);

  const config = [
    {
      label: "Table",
      field: "table",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.table.name,
      //No validation this will always be read only
    },
    {
      label: "Column",
      field: "column",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.column.name,
    },
    {
      label: "Action",
      field: "action",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.action,
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  //This is an array of related lists. Each element will be a tab on the realted list
  const { id } = useParams();
  let relatedListConfig = [
    //User Roles Related List
    {
      secLevel: secLevelfieldSecurityLevelsRole,

      //Function used as the key on the list
      keyFn: (fieldSecurityLevelsRole) => {
        return fieldSecurityLevelsRole._id;
      },

      recordNames: {
        title: "Role Access",
        formLink: "fieldSecurityLevelsRole",
        listLink: "fieldSecurityLevelsRole",
      },
      listApi: fieldSecurityLevelsRoleApi,

      useFetchQuery: useFetchFieldSecurityLevelsRoleQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `fieldSecurityLevel._id=${id}^`,
      config: [
        {
          label: "i",
          render: (fslRole) => `/fieldSecurityLevelsRole/${fslRole._id}`,
        },
        {
          label: "Role",
          render: (fslRole) => fslRole.role.name,
          sortValue: "role.name",
          filterValue: "role",
          filterType: "String",
        },
      ],
    },
  ];
  return (
    <FormSJ
      storeApi={storeApi}
      apiToReset={fieldSecurityLevelApi}
      config={config}
      validationSchema={validationSchema}
      relatedListConfig={relatedListConfig}
      secLevel={secLevel}
      formTitle='Field Level'
      table='fieldSecurityLevel'
      redirect='fieldSecurityLevel'
    />
  );
};

export default requireAuth(FieldSecurityLevelForm);
