import { createSlice } from "@reduxjs/toolkit";

const utilSlice = createSlice({
  name: "util",
  initialState: {
    isLeftNavCollapsed: false,
  },
  reducers: {
    toggleLeftNavCollapse(state, action) {
      state.isLeftNavCollapsed = !state.isLeftNavCollapsed;
    },
    toggleMobileNav(state, action) {
      if (action.payload) state.isMobileNavOpen = action.payload;
      else state.isMobileNavOpen = !state.isMobileNavOpen;
    },
  },
});

export const { toggleLeftNavCollapse, toggleMobileNav } = utilSlice.actions;
export const utilReducer = utilSlice.reducer;
