import List from "../components/List";
import requireAuth from "../requireAuth";

import {
  roleApi,
  useFetchRoleQuery,
  useRoleExportListQuery,
} from "../store/apis/roleApi";

const RoleList = () => {
  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (role) => `/role/${role._id}`,
    },
    {
      label: "Name",
      render: (role) => role.name,
      sortValue: "name",
      filterValue: "name",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Description",
      render: (role) => role.description,
      sortValue: "description",
      filterValue: "description",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
  ];

  //What is the key for the data?
  const keyFn = (role) => {
    return role._id;
  };

  const recordNames = {
    title: "Roles",
    formLink: "role",
    listLink: "role", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  return (
    <List
      config={config}
      keyFn={keyFn}
      listApi={roleApi}
      useFetchQuery={useFetchRoleQuery}
      useExportQuery={useRoleExportListQuery}
      recordNames={recordNames}
    ></List>
  );
};

export default requireAuth(RoleList);
