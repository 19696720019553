import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//This is a higher order componenet that looks for the user token.
//If it is not there it redirects the user to login. This is used to protect
//front end pages

const requireAuth = (ChildComponent) => {
  const ComposedComponent = (props) => {
    // const { roles } = useSelector((state) => {
    //   //state is the entire state object
    //   //You just need to reutnr the piece that you need
    //   return { roles: state.auth.roles };
    // });

    const roles = useSelector((state) => {
      if (state.auth.roles && state.auth.roles[0]) {
        return state.auth.roles[0] || false;
      } else {
        return false;
      }
    });

    const navigate = useNavigate();

    useEffect(() => {
      if (!roles || roles === "") {
        localStorage.removeItem("userInfo");

        const path = window.location.pathname;
        if (path !== "/login") localStorage.setItem("redirect", path);

        navigate("/login");
        return;
      }
    }, [roles, navigate]);

    return <ChildComponent props={props} />;
  };

  return ComposedComponent;
};

export default requireAuth;
