import Modal from "react-bootstrap/Modal";
import CloseButton from "./CloseButton";
import { Button } from "react-bootstrap";
import List from "./List";

function ReferenceModal({
  onClose,
  title,
  selectedFilterItems,
  handleConfirm,
  refModalListConfig,
  handleRowSelect,
}) {
  const elementToAdd = {
    label: "checkbox",
    render: () => "",
  };

  return (
    <>
      <Modal show={true} onHide={onClose} size='xl'>
        <Modal.Body className='shadow-sj-btm z-50 p-0'>
          <CloseButton onClose={onClose} />
          <List
            handleClick={handleRowSelect}
            config={[elementToAdd, ...refModalListConfig.config]}
            keyFn={refModalListConfig.keyFn}
            listApi={refModalListConfig.listApi}
            // useFetchQuery={refModalListConfig.useFetchQuery}
            useFetchQuery={refModalListConfig.useFetchQuery}
            recordNames={refModalListConfig.recordNames}
            // defaultFilter={defaultFilter}
            // This was added to fix BUG0001 - Bug undefined on reference Dropdown.
            // I was resetting the listApi in the useCheckAuth hook but that was causing the data to be removed and return undefined
            isRefModal={true}
            selectedFilterItems={selectedFilterItems}
          ></List>
        </Modal.Body>
        <Modal.Footer className='sj-bg-grey-100 rounded-bottom-2xl border-none flex grow justify-between'>
          <div></div>
          <Button variant='primary' onClick={handleConfirm}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReferenceModal;
