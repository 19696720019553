import List from "../components/List";
import requireAuth from "../requireAuth";

import { formViewApi } from "../store/apis/formViewApi";
import { useFetchFormViewQuery, useFormViewExportListQuery } from "../store";

const FormViewList = () => {
  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (formView) => `/formView/${formView._id}`,
    },
    {
      label: "Table",
      render: (formView) => formView?.table?.name || "",
      sortValue: "table",
      filterValue: "table",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Column",
      render: (formView) => formView?.column?.name || "",
      sortValue: "column",
      filterValue: "column",
      filterType: "String",
    },
    {
      label: "Label",
      render: (formView) => formView.label,
      sortValue: "label",
      filterValue: "label",
      filterType: "String",
    },
    {
      label: "Tooltip",
      render: (formView) => formView.toolTip,
      sortValue: "toolTip",
      filterValue: "toolTip",
      filterType: "String",
    },
    {
      label: "Order",
      render: (formView) => formView.order,
      sortValue: "order",
      // render: (formView) => <div className={`p-3 m-2 ${formView.color}`}></div>,
      filterValue: "order",
      filterType: "String",
    },
    {
      label: "Active", //header label
      render: (formView) => formView.active.toString(), //How to render the cell
      sortValue: "active",
      filterValue: "active",
      filterType: "Boolean",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (formView) => {
    return formView._id;
  };

  const recordNames = {
    title: "Form View",
    formLink: "formView",
    listLink: "formView", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  return (
    <List
      config={config}
      keyFn={keyFn}
      listApi={formViewApi}
      useFetchQuery={useFetchFormViewQuery}
      useExportQuery={useFormViewExportListQuery}
      recordNames={recordNames}
    ></List>
  );
};

export default requireAuth(FormViewList);
