import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BackButton = ({ createData }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (createData) navigate(-2);
    else navigate(-1);
  };

  return (
    <>
      <Button onClick={handleGoBack} variant='primary' className='my-3 mx-3'>
        Back
      </Button>
    </>
  );
};

export default BackButton;
