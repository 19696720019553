import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const PasswordResetConfirm = () => {
  const navigate = useNavigate();
  return (
    <div className='my-20 px-16'>
      <div className='flex w-full justify-center'>
        <img className='logo mb-3' src='../images/Logo Dark.svg' alt='Logo' />
      </div>
      <div className='flex justify-center w-full'>
        <div className='flex justify-center flex-col max-w-md text-center'>
          <p className='text-2xl py-2'>Password Reset</p>
          <p className='py-5'>
            Please check your email for a link to reset your password.
          </p>

          <Button
            variant='primary'
            onClick={() => {
              navigate("/login");
            }}
            className='primary-light-btn w-full'
          >
            Return to Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetConfirm;
