import React from "react";
import Excel from "exceljs";
import { Button } from "react-bootstrap";

const ExportPivotToExcel = ({
  data,
  columns,
  aggregates,
  periods,
  fileName,
}) => {
  const exportToExcel = async () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    for (let i = 0; i < 26; i++) {
      let columnName = String.fromCharCode(65 + i); // 65 is the ASCII value for 'A'
      worksheet.getColumn(columnName).width = 20;
      worksheet.getColumn(`A${columnName}`).width = 20;
      worksheet.getColumn(`B${columnName}`).width = 20;
    }

    // Helper function to apply styles to a cell
    const applyStyles = (excelCell, className) => {
      if (className === "header") {
        excelCell.font = {
          name: "Calibri",
          size: 12,
          bold: true,
          color: { argb: "FFFFFF" },
        };

        //Center horizontally
        excelCell.alignment = { horizontal: "center", vertical: "middle" };

        //wdith
        excelCell.width = 200;

        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "567083" },
        };
      }

      if (className.includes("text-left")) {
        excelCell.alignment = { horizontal: "left", vertical: "middle" };
      }

      if (className.includes("Total")) {
        excelCell.font = {
          name: "Calibri",
          size: 12,
          color: { argb: "FFFFFF" },
        };

        let color = "153E5C";
        if (className.includes("prTotal1")) color = "1E547C";
        else if (className.includes("prTotal2")) color = "266C9E";
        else if (className.includes("prTotal3")) color = "398CC9";
        else if (className.includes("prTotal4")) color = "63A5D6";
        else if (className.includes("prTotal5")) color = "91BBD9";
        else if (className.includes("prTotal6")) color = "A6CBE7";

        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: color },
        };
      }

      if (className.includes("currency")) {
        excelCell.numFmt = "$#,##0.00; ($#,##0.00); -";
      }

      if (className.includes("number")) {
        excelCell.numFmt = "#,##0.00; (#,##0.00); -";
      }

      excelCell.border = {
        top: { style: "thin", color: { argb: "FFCCCCCC" } },
        left: { style: "thin", color: { argb: "FFCCCCCC" } },
        bottom: { style: "thin", color: { argb: "FFCCCCCC" } },
        right: { style: "thin", color: { argb: "FFCCCCCC" } },
      };
    };
    // Set to keep track of merged cells

    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      const excelCell = worksheet.getCell(1, i + 1);
      excelCell.value = column.name;

      const bottomRight = worksheet.getCell(2, i + 1);

      // Merge cells
      worksheet.mergeCells(`${excelCell.address}:${bottomRight.address}`);

      applyStyles(excelCell, "header");
    }

    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      const excelCell = worksheet.getCell(
        1,
        i * aggregates.length + 1 + columns.length
      );
      excelCell.value = period;

      const bottomRight = worksheet.getCell(
        1,
        i * aggregates.length + aggregates.length + columns.length
      );

      // Merge cells
      worksheet.mergeCells(`${excelCell.address}:${bottomRight.address}`);

      applyStyles(excelCell, "header");

      for (let j = 0; j < aggregates.length; j++) {
        const aggregate = aggregates[j];
        const excelCell = worksheet.getCell(
          2,
          i * aggregates.length + 1 + columns.length + j
        );
        excelCell.value = aggregate;

        applyStyles(excelCell, "header");
      }
    }

    const totalCell = worksheet.getCell(
      1,
      periods.length * aggregates.length + 1 + columns.length
    );
    totalCell.value = "Totals";
    if (aggregates.length > 1) {
      const bottomRight = worksheet.getCell(
        1,
        periods.length * aggregates.length + aggregates.length + columns.length
      );
      worksheet.mergeCells(`${totalCell.address}:${bottomRight.address}`);
    }
    applyStyles(totalCell, "header");

    for (let i = 0; i < aggregates.length; i++) {
      const aggregate = aggregates[i];
      const excelCell = worksheet.getCell(
        2,
        periods.length * aggregates.length + 1 + columns.length + i
      );
      excelCell.value = aggregate;

      applyStyles(excelCell, "header");
    }

    for (let i = 0; i < data.length; i++) {
      const row = data[i];

      for (let j = 0; j < columns.length; j++) {
        const column = columns[j];
        if (row.isTotal && row.totalDepth <= j) continue;

        const excelCell = worksheet.getCell(i + 3, j + 1);

        const className =
          row.isTotal && row.totalDepth <= j + 1
            ? `${row.className} text-left`
            : (row.totalDepth >= j + 1 && "prDetail") || "";

        const colSpan =
          (row.isTotal &&
            row.hasOwnProperty(column.name.toLowerCase()) &&
            columns.length - row.totalDepth + 1) ||
          1;

        if (
          row.hasOwnProperty(column.name.toLowerCase()) &&
          (row.totalDepth <= j || row.isTotal)
        ) {
          excelCell.value = row[column.name.toLowerCase()];
        } else excelCell.value = "";

        applyStyles(excelCell, className);
        if (colSpan > 1) {
          const bottomRight = worksheet.getCell(i + 3, j + colSpan);
          worksheet.mergeCells(`${excelCell.address}:${bottomRight.address}`);
        }
      }

      for (let j = 0; j < row.periods.length; j++) {
        const period = row.periods[j];
        for (let k = 0; k < aggregates.length; k++) {
          const aggregate = aggregates[k];

          const excelCell = worksheet.getCell(
            i + 3,
            j * aggregates.length + 1 + columns.length + k
          );

          let field = "actualHours";
          if (aggregate === "Actual Dollars") field = "actualDollars";

          let value = period[field];

          let className = row.isTotal ? `${row.className}` : "";

          if (value === 0) value = "";
          else if (field === "actualDollars") className += " currency";
          else className += " number";

          excelCell.value = value;
          applyStyles(excelCell, className);
        }
      }

      for (let j = 0; j < aggregates.length; j++) {
        const aggregate = aggregates[j];

        const excelCell = worksheet.getCell(
          i + 3,
          row.periods.length * aggregates.length + 1 + columns.length + j
        );

        let field = "actualHoursTotal";
        if (aggregate === "Actual Dollars") field = "actualDollarsTotal";

        let value = row[field];

        let className = row.isTotal ? `${row.className}` : "";

        if (value === 0) value = "";
        else if (field === "actualDollarsTotal") className += " currency";
        else className += " number";

        excelCell.value = value;
        applyStyles(excelCell, className);
      }
    }

    // Save the workbook to a file
    await workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.xlsx`;
      link.click();
    });
  };

  return (
    <div>
      <Button onClick={exportToExcel}>
        {" "}
        <img src='/images/Download Icon.svg' alt='export' />
      </Button>
    </div>
  );
};

export default ExportPivotToExcel;
