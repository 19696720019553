import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchHolidayByIdQuery,
  useUpdateHolidayByIdMutation,
  useDeleteHolidayByIdMutation,
  useCreateHolidayMutation,
} from "../store";
import { holidayApi } from "../store/apis/holidayApi";
import { formViewApi } from "../store/apis/formViewApi";

import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

const HolidayForm = () => {
  const storeApi = {
    fetch: useFetchHolidayByIdQuery,
    update: useUpdateHolidayByIdMutation,
    delete: useDeleteHolidayByIdMutation,
    create: useCreateHolidayMutation,
  };

  //Get the data from the form view. This will be used to configure the form
  const { data, error, isLoading } = useFetchFormViewByTableQuery("holiday");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel] = useSecLevel(["holiday"]);

  const config = [
    {
      label: "Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .required("Name is required"),
    },
    {
      label: "Date",
      field: "date",
      defaultValue: "",
      inputType: "date",
      render: (data) => {
        if (data.date) {
          return data?.date.toString().substring(0, 10);
        } else {
          return "";
        }
      },
      validation: Yup.date()
        .min(new Date(1900, 0, 1), "Date cannot be before the year 1900.")
        .max(new Date(2100, 0, 1), "Date cannot be after the year 2100.")
        .required("Date is required")
        .nullable()
        .typeError("Date is required"),
    },
  ];

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={holidayApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={[]}
        secLevel={secLevel}
        formTitle='Holiday'
        table='holiday'
        redirect='holiday'
      />
    ))
  );
};

export default requireAuth(HolidayForm);
