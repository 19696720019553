import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchTableSecurityLevelsRoleByIdQuery,
  useUpdateTableSecurityLevelsRoleByIdMutation,
  useDeleteTableSecurityLevelsRoleByIdMutation,
  useCreateTableSecurityLevelsRoleMutation,
  useFetchRoleQuery,
  useFetchTableSecurityLevelQuery,
} from "../store";
import * as Yup from "yup";
import { tableSecurityLevelsRoleApi } from "../store/apis/tableSecurityLevelsRoleApi";

import { roleApi } from "../store/apis/roleApi";

import { useSecLevel } from "../hooks/formHooks";

import { tableSecurityLevelApi } from "../store/apis/tableSecurityLevelApi";

const TableSecurityLevelsRoleForm = () => {
  const storeApi = {
    fetch: useFetchTableSecurityLevelsRoleByIdQuery,
    update: useUpdateTableSecurityLevelsRoleByIdMutation,
    delete: useDeleteTableSecurityLevelsRoleByIdMutation,
    create: useCreateTableSecurityLevelsRoleMutation,
  };

  //Get front end security from authSlice
  const [secLevel] = useSecLevel(["tableSecurityLevelsRole"]);

  // This is the config array for the fields that will be shown on the form.
  let config = [
    {
      label: "Table Level",
      field: "tableSecurityLevel",
      defaultValue: "",
      inputType: "reference",
      refTable: "tableSecurityLevel",
      refDisplayField: "name",
      render: (data) => data?.tableSecurityLevel?.name || "",
      id: (data) => data?.tableSecurityLevel?._id || "",
      useFetchQuery: useFetchTableSecurityLevelQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Pleaes select a valid field level",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Table",
            render: (fsl) => fsl?.table?.name,
            sortValue: "table",
            filterValue: "table",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Action",
            render: (fsl) => fsl.action,
            sortValue: "action",
            filterValue: "action",
            filterType: "String",
          },
        ],
        keyFn: (fsl) => {
          return fsl._id;
        },
        recordNames: {
          title: "Select a Table Level",
          formLink: "tableSecurityLevel",
          listLink: "tableSecurityLevel",
        },
        listApi: tableSecurityLevelApi,
        useFetchQuery: useFetchTableSecurityLevelQuery,
      },
    },
    {
      label: "Role",
      field: "role",
      defaultValue: "",
      inputType: "reference",
      refTable: "role",
      refDisplayField: "name",
      render: (data) => data?.role?.name || "",
      id: (data) => data?.role?._id || "",
      useFetchQuery: useFetchRoleQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Pleaes select a valid role",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (role) => role.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
        ],
        keyFn: (role) => {
          return role._id;
        },
        recordNames: {
          title: "Select a Role",
          formLink: "role",
          listLink: "roles",
        },
        listApi: roleApi,
        useFetchQuery: useFetchRoleQuery,
      },
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  return (
    <FormSJ
      storeApi={storeApi}
      apiToReset={tableSecurityLevelsRoleApi}
      config={config}
      validationSchema={validationSchema}
      relatedListConfig={[]}
      secLevel={secLevel}
      formTitle='Table Level Role'
      table='tableSecurityLevelsRole'
      redirect='tableSecurityLevelsRole'
    />
  );
};

export default requireAuth(TableSecurityLevelsRoleForm);
