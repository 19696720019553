import { Dropdown, Form, Button } from "react-bootstrap";
import "./recordFilter.css";

const RecordFilter = ({
  handleRunFilter,
  config,
  localFilter,
  handleLocalFilterChange,
  className,
}) => {
  //    {
  //   field: { label: "Email", name: "email", type: "String" },
  //   comp: "is",
  //   value: "",
  // },

  //This is just the list of options for the Label
  const fieldOptions = [];

  for (let i = 0; i < config.length; i++) {
    const option = config[i];
    if (option.filterValue)
      fieldOptions.push({
        label: option.label,
        name: option.filterValue,
        type: option.filterType,
      });
  }

  //Comparison Options
  const stringOptions = [
    "contains",
    "does not contain",
    "is",
    "is not",
    "starts with",
    "ends with",
  ];
  const boolOptions = ["is", "is not"];
  const dateOptions = ["on", "after", "before"];
  const numberOptions = [
    "equals",
    "does not equal",
    "is greater than",
    "is less than",
  ];

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleRunFilter();
    }
  };

  const getValueFieldHTML = (item) => {
    const valueClasses = "m-1 lg:m-2";

    const booleanHtml = (
      <Dropdown className={valueClasses} onKeyDown={handleKeyDown}>
        <Dropdown.Toggle variant='light' id='dropdown-basic'>
          {item.value}
        </Dropdown.Toggle>

        <Dropdown.Menu className={dropdownClasses}>
          {item.value === "False" && (
            <Dropdown.Item
              onClick={() => {
                handleValueChange(
                  { preventDefault: () => {}, target: { value: "True" } },
                  item
                );
              }}
            >
              True
            </Dropdown.Item>
          )}
          {item.value === "True" && (
            <Dropdown.Item
              onClick={() => {
                handleValueChange(
                  { preventDefault: () => {}, target: { value: "False" } },
                  item
                );
              }}
            >
              False
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );

    const stringHtml = (
      <Form.Group className={valueClasses}>
        <Form.Control
          type='text'
          placeholder=''
          value={item.value}
          onChange={(e) => {
            handleValueChange(e, item);
          }}
          aria-label='Set a filter'
          onKeyDown={handleKeyDown}
        ></Form.Control>
      </Form.Group>
    );

    const dateHtml = (
      <Form.Group md='6' className={valueClasses}>
        <Form.Control
          type='date'
          value={item.value}
          onChange={(e) => {
            handleValueChange(e, item);
          }}
          onKeyDown={handleKeyDown}
          placeholder='Choose a date'
          aria-label='Choose a date'
        ></Form.Control>
      </Form.Group>
    );

    const numberHtml = (
      <Form.Group md='6' className={valueClasses}>
        <Form.Control
          type='number'
          placeholder=''
          value={item.value}
          onChange={(e) => {
            handleValueChange(e, item);
          }}
          aria-label='Set a filter'
          onKeyDown={handleKeyDown}
        ></Form.Control>
      </Form.Group>
    );

    let myHtml = stringHtml;

    const type = item.field.type;
    if (type === "Boolean") myHtml = booleanHtml;
    else if (type === "Date") myHtml = dateHtml;
    else if (type === "Number") myHtml = numberHtml;

    return myHtml;
  };

  const getOptions = (type) => {
    let options = stringOptions;
    if (type === "Boolean") options = boolOptions;
    else if (type === "Date") options = dateOptions;
    else if (type === "Number") options = numberOptions;

    return options;
  };

  //Change the filter
  //    [{
  //   field: { label: "Email", name: "email", type: "String" },
  //   comp: "is",
  //   value: "",
  // }]
  const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

  const handleValueChange = (e, item) => {
    e.preventDefault();
    let newFilter = [];

    for (let i = 0; i < localFilter.length; i++) {
      const localItem = localFilter[i];
      const newItem = deepCopy(localItem);

      if (localItem === item) {
        newItem.value = e.target.value;
      }

      newFilter.push(newItem);
    }

    handleLocalFilterChange(newFilter);
  };

  const handleFieldAndCompChange = (newValue, isField, item) => {
    let newFilter = [];

    for (let i = 0; i < localFilter.length; i++) {
      const localItem = localFilter[i];
      const newItem = deepCopy(localItem);

      if (localItem === item) {
        if (isField) {
          newItem.field = deepCopy(newValue);
          const options = getOptions(newItem.field.type);
          if (newItem.field.type !== localItem.field.type)
            newItem.comp = options[0];

          if (newItem.field.type === "Boolean") newItem.value = "True";
          else if (
            localItem.field.type === "Boolean" &&
            newItem.field.type !== "Boolean"
          )
            newItem.value = "";
        } else {
          newItem.comp = newValue;
        }
      }

      // if (newItem.field.type !== "Boolean") {
      //   newItem.value = "";
      // }

      newFilter.push(newItem);
    }

    handleLocalFilterChange(newFilter);
  };

  const handleDelete = (item) => {
    let newFilter = [];

    for (let i = 0; i < localFilter.length; i++) {
      const localItem = localFilter[i];
      const newItem = deepCopy(localItem);

      if (localItem !== item) {
        newFilter.push(newItem);
      }
    }

    handleLocalFilterChange(newFilter);
  };

  const handleLogicClick = (logic, item, i) => {
    i = i || localFilter.length;

    let copiedItem = {};
    if (logic === "^OR" && item) {
      copiedItem = deepCopy(item);
    } else if (logic === "^ANDOOR" && localFilter[i - 1]) {
      copiedItem = deepCopy(localFilter[i - 1]);
      logic = "^";
    } else {
      copiedItem = deepCopy(localFilter[localFilter.length - 1]);
    }

    copiedItem.value = "";
    if (copiedItem.field.type === "Boolean") copiedItem.value = "True";

    copiedItem.logic = logic;

    let newFilter = deepCopy(localFilter);

    newFilter.splice(i, 0, copiedItem);

    handleLocalFilterChange(newFilter);
  };

  //These are the classes for the dropdowns
  const dropdownClasses =
    "w-2-3 shadow-sj border-none rounded-tl-2xl rounded-bl-2xl rounded-br-2xl rounded-tr-none py-0 z-100";

  const rowClasses = "flex items-center pl-14 w-full relative";
  const content = localFilter.map((item, i) => {
    const options = getOptions(item.field.type);
    const itemHtml = getValueFieldHTML(item);

    return (
      <span key={item.field.label + i.toString()}>
        {item.logic === "^OOR" && (
          <>
            <Button
              className='sj-bg-secondary-dark btn-and btn-logic leading-3 mb-3 ml-16'
              onClick={() => {
                handleLogicClick("^ANDOOR", undefined, i);
              }}
            >
              And
            </Button>
            <div className='oor'>
              <p className='oor-text'>OR</p>
            </div>
          </>
        )}
        <div className={`${rowClasses} ${item.logic === "^OR" && "or-row"}`}>
          {/* This is the braket for the and */}
          {item.logic === "^" && (
            <div className='and top-[-34%] left-[33px] lg:top-[-50%] lg:left-[38px]'>
              <p className='and-text'>AND</p>
            </div>
          )}

          {item.logic === "^OR" && (
            <div className='or top-[-16%] left-[31px] lg:top-[-20%] lg:left-[38px]'>
              <p className='or-text'>OR</p>
            </div>
          )}

          <div className='flex flex-col lg:flex-row'>
            {/* LABEL */}
            <Dropdown className='m-1 lg:m-2'>
              <Dropdown.Toggle variant='light' id='dropdown-basic'>
                {item.field.label}
              </Dropdown.Toggle>

              <Dropdown.Menu
                className={dropdownClasses}
                style={{ width: "82%" }}
              >
                {fieldOptions.map((option) => {
                  if (option.label !== item.field.label)
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          handleFieldAndCompChange(option, true, item);
                        }}
                        key={option.label + option.field}
                      >
                        {option.label}
                      </Dropdown.Item>
                    );
                  return "";
                })}
              </Dropdown.Menu>
            </Dropdown>

            {/* COMPARISON */}
            <Dropdown className='m-1 lg:m-2'>
              <Dropdown.Toggle variant='light' id='dropdown-basic'>
                {item.comp}
              </Dropdown.Toggle>

              <Dropdown.Menu
                className={dropdownClasses}
                style={{ width: "82%" }}
              >
                {
                  // see top of map for logic on options assignment
                  options.map((option) => {
                    if (option !== item.comp)
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            handleFieldAndCompChange(option, false, item);
                          }}
                          key={option}
                        >
                          {option}
                        </Dropdown.Item>
                      );
                    return "";
                  })
                }
              </Dropdown.Menu>
            </Dropdown>

            {/* VALUE */}
            <span className='hidden lg:inline'>{itemHtml}</span>
          </div>
          {/* ROW LOGIC OPTIONS - OR / DELETE */}

          <div className='flex flex-col lg:flex-row'>
            <Button
              className='my-1 ml-3 lg:ml-0 lg:my-0 sj-bg-secondary-dark btn-or mx-2 btn-logic leading-3'
              onClick={() => {
                handleLogicClick("^OR", item, i + 1);
              }}
            >
              Or
            </Button>
            <Button
              variant='danger'
              className='my-1 lg:my-0 btn-filter mx-2 flex justify-center content-center'
              onClick={() => {
                handleDelete(item);
              }}
            >
              <img src='/images/X.svg' alt='' className='btn-delete' />
            </Button>
          </div>
        </div>
        <div className='px-14 lg:hidden'>{itemHtml}</div>
      </span>
    );
  });

  return (
    <div
      className={
        className + " sj-bg-C3 w-full shadow-sj-btm z-30 relative py-2"
      }
    >
      {content}
      <Button
        className='sj-bg-secondary-dark btn-and btn-logic leading-3 mb-3 ml-16 mt-2 lg:mt-0'
        onClick={() => {
          handleLogicClick("^");
        }}
      >
        And
      </Button>
      <Button
        className='sj-bg-secondary-dark btn-and btn-logic leading-3 mb-3 ml-4 mt-2 lg:mt-0'
        onClick={() => {
          handleLogicClick("^OOR");
        }}
      >
        Or
      </Button>
    </div>
  );
};

export default RecordFilter;
