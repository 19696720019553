import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useLogoutUserMutation } from "../store/apis/authApi";
import { reset } from "../store";
import { addMessage } from "../store/slices/messageSlice";

export const useCheckAuth = (error, apiToReset, isRefModal) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logoutUser] = useLogoutUserMutation();

  //This is here b/c i need to redirect if they have a token but it has expired
  useEffect(() => {
    if (error && error.status === 401) {
      const path = window.location.pathname;
      if (path !== "/login") localStorage.setItem("redirect", path);

      //BUG0002 - Make the login redirect reset the authApi
      dispatch(
        addMessage({
          message: "You have been logged out.",
          variant: "warning",
        })
      );
      dispatch(reset());
      logoutUser();

      navigate("/login");
    }
    // eslint-disable-next-line
  }, [error]);

  //This is used to reset the listApi mainly the error so that it is not around when it is mounted again.
  useEffect(() => {
    return () => {
      //This piece of code is what make it so you dont have to login twic-e... but it also pulls the
      //data twice. I need to clear the error, isloading, data states without using this
      /* isRefMidal was added to fix BUG0001 - Bug undefined on reference Dropdown.
        I was resetting the listApi in the useCheckAuth hook but that was causing the data to be removed and return undefined*/
      if (!isRefModal) dispatch(apiToReset.util.resetApiState());
    };
    // eslint-disable-next-line
  }, []);
};
