import React from "react";
import LoadingButton from "./LoadingButton";
import { useState, useEffect } from "react";
import Excel from "exceljs";
import { useDispatch } from "react-redux";
import { addMessage } from "../store/slices/messageSlice";

const ExportListToExcel = ({
  useExportQuery,
  page,
  pageSize,
  sortOrder,
  sortBy,
  filter,
  config,
  title,
}) => {
  const dispatch = useDispatch();
  const [isExporting, setIsExporting] = useState(false);
  const handleExport = () => {
    setIsExporting(true);
  };

  const { data, error, isLoading, isFetching } = useExportQuery(
    {
      page,
      pageSize,
      sortOrder,
      sortBy,
      filter,
    },
    { skip: !isExporting }
  );

  useEffect(() => {
    const exportToExcel = async () => {
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");

      for (let i = 0; i < 26; i++) {
        let columnName = String.fromCharCode(65 + i); // 65 is the ASCII value for 'A'
        worksheet.getColumn(columnName).width = 20;
        worksheet.getColumn(`A${columnName}`).width = 20;
        worksheet.getColumn(`B${columnName}`).width = 20;
      }

      const columns = config;

      let i = 0;
      columns.forEach((column, index) => {
        if (column.label === "i") return;

        const excelCell = worksheet.getCell(1, i + 1);
        excelCell.value = column.label;

        applyStyles(excelCell, "header");

        for (let j = 0; j < data.length; j++) {
          const excelCell = worksheet.getCell(j + 2, i + 1);
          excelCell.value = column.render(data[j]);
          applyStyles(excelCell, "text-left");
        }
        i++;
      });

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${title}.xlsx`;
        link.click();
      });
    };

    // Helper function to apply styles to a cell
    const applyStyles = (excelCell, className) => {
      if (className === "header") {
        excelCell.font = {
          name: "Calibri",
          size: 12,
          bold: true,
          color: { argb: "FFFFFF" },
        };

        //Center horizontally
        excelCell.alignment = { horizontal: "center", vertical: "middle" };

        //wdith
        excelCell.width = 200;

        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "567083" },
        };
      }

      if (className.includes("text-left")) {
        excelCell.alignment = { horizontal: "left", vertical: "middle" };
      }

      if (className.includes("Total")) {
        excelCell.font = {
          name: "Calibri",
          size: 12,
          color: { argb: "FFFFFF" },
        };

        let color = "153E5C";
        if (className.includes("prTotal1")) color = "1E547C";
        else if (className.includes("prTotal2")) color = "266C9E";
        else if (className.includes("prTotal3")) color = "398CC9";
        else if (className.includes("prTotal4")) color = "63A5D6";
        else if (className.includes("prTotal5")) color = "91BBD9";
        else if (className.includes("prTotal6")) color = "A6CBE7";

        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: color },
        };
      }

      if (className.includes("currency")) {
        excelCell.numFmt = "$#,##0.00; ($#,##0.00); -";
      }

      if (className.includes("number")) {
        excelCell.numFmt = "#,##0.00; (#,##0.00); -";
      }

      excelCell.border = {
        top: { style: "thin", color: { argb: "FFCCCCCC" } },
        left: { style: "thin", color: { argb: "FFCCCCCC" } },
        bottom: { style: "thin", color: { argb: "FFCCCCCC" } },
        right: { style: "thin", color: { argb: "FFCCCCCC" } },
      };
    };

    if (!isLoading && !isFetching && isExporting) {
      setIsExporting(false);

      if (data) exportToExcel();
    }
  }, [isLoading, isExporting, data, config, title, isFetching]);

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: error?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    }
  }, [error, dispatch]);

  return (
    <LoadingButton
      className='px-3 mx-2'
      variant='primary'
      onClick={handleExport}
      isLoading={isLoading}
    >
      <img src='/images/Download Icon.svg' alt='export' />
    </LoadingButton>
  );
};

export default ExportListToExcel;
