import React from "react";
import { useRouteError } from "react-router";

const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);

  // Uncaught ReferenceError: path is not defined
  return (
    <div className='flex justify-center w-full my-20'>
      <img
        src='/images/Error.png'
        alt='Error Page'
        className='w-10/12 lg:w-8/12 xl:w-1/3'
      />
    </div>
  );
};

export default ErrorBoundary;
