import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { taskApi } from "../store/apis/taskApi";
import { useFetchTasksQuery, useTaskExportListQuery } from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
import { useFinalListConfig, formatDate } from "../hooks/listHooks";

const TaskList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("task");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (task) => `/task/${task._id}`,
    },
    {
      label: "Number",
      render: (task) => task.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String",
    },
    {
      label: "Status",
      render: (task) => task.status,
      sortValue: "status",
      filterValue: "status",
      filterType: "String",
    },

    {
      label: "Project",
      render: (task) => {
        let myValue = "";
        if (task.project) {
          myValue = task?.project?.number + " - " + task?.project?.name;
        }

        return myValue;
      },
      sortValue: "project",
      filterValue: "project",
      filterType: "String",
    },
    {
      label: "Task Name",
      render: (task) => task.name,
      sortValue: "name",
      filterValue: "name",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Start Date", //header label
      render: (task) => {
        return formatDate(task.startDate) || "";
        // return new Date(task.startDate).toString();
      }, //How to render the cell
      sortValue: "startDate",
      filterValue: "startDate",
      filterType: "Date",
    },
    {
      label: "End Date", //header label
      render: (task) => {
        return formatDate(task.endDate) || "";
        // return new Date(task.endDate).toString();
      }, //How to render the cell
      sortValue: "endDate",
      filterValue: "endDate",
      filterType: "Date",
    },
    {
      label: "Description",
      render: (task) => task.description,
      sortValue: "description",
      filterValue: "description",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Active", //header label
      render: (task) => task.active.toString(), //How to render the cell
      sortValue: "active",
      filterValue: "active",
      filterType: "Boolean",
    },
    {
      label: "Allow Time Entry", //header label
      render: (task) => task.allowTimeEntry.toString(), //How to render the cell
      sortValue: "allowTimeEntry",
      filterValue: "allowTimeEntry",
      filterType: "Boolean",
    },
    {
      label: "Created At", //header label
      render: (task) => {
        return new Date(task.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },
    {
      label: "Rate Card",
      render: (task) => task?.rateCard?.name || "",
      sortValue: "rateCard",
      filterValue: "rateCard",
      filterType: "String",
    },
    {
      label: "Updated At", //header label
      render: (task) => {
        return new Date(task.updatedAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "updatedAt",
      filterValue: "updatedAt",
      filterType: "Date",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (task) => {
    return task._id;
  };

  const recordNames = {
    title: "Tasks",
    formLink: "task",
    listLink: "task", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={taskApi}
        useFetchQuery={useFetchTasksQuery}
        useExportQuery={useTaskExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(TaskList);
