import { useState } from "react";

function useSort(startingSort, startingSortOrder) {
  const [sortOrder, setSortOrder] = useState(startingSortOrder);
  const [sortBy, setSortBy] = useState(startingSort);

  const setSortColumn = (label) => {
    if (sortBy && label !== sortBy) {
      setSortOrder("asc");
      setSortBy(label);
      return;
    }

    if (sortOrder === null || sortOrder === "desc") {
      setSortOrder("asc");
      setSortBy(label);
    } else {
      setSortOrder("desc");
      setSortBy(label);
    }
  };

  return {
    sortOrder,
    sortBy,
    setSortColumn,
  };
}

export default useSort;
