import { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  Form,
  InputGroup,
  OverlayTrigger,
  Col,
  Tooltip,
} from "react-bootstrap";

const SelectField = ({ item, canUpdate, handleChange, state, errors }) => {
  const [showMenu, setShowMenu] = useState(false);

  const divEl = useRef();

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleSelect = (eventKey) => {
    handleChange({ target: { name: [item.field], value: eventKey } });
    setShowMenu(false);
  };

  //This adds functionality so you can click anywhere and the dropdown will close
  useEffect(() => {
    const handler = (event) => {
      //Check to make sure the elemetn was rendered
      if (!divEl.current) {
        return;
      }

      //Did the click that just happened happen inside the divEl reference that we care abount?
      if (!divEl.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    //Watch for clicks on the entire document on the capture phase
    document.addEventListener("click", handler, true);

    //This function will be called at the start of the next useEffect call
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  let dropDownContent = "No options available";

  if (item.selectOptions) {
    dropDownContent = item.selectOptions.map((option) => {
      return (
        <Dropdown.Item eventKey={option} key={option}>
          {option}
        </Dropdown.Item>
      );
    });
  }

  return (
    <Dropdown
      show={showMenu}
      onSelect={handleSelect}
      as={Col}
      md='6'
      sm='11'
      xs='11'
      className='mx-4 md:mx-0 my-2'
    >
      <Form.Group
        controlId={item.field}
        key={item.field}
        aria-haspopup='true'
        aria-expanded={showMenu}
      >
        <Form.Label>
          {item.label}
          {errors[item.field] &&
            errors[item.field]?.indexOf("required") !== -1 && (
              <span className='required'> *</span>
            )}
        </Form.Label>
        <InputGroup>
          <OverlayTrigger
            placement='top'
            overlay={
              (item.toolTip && <Tooltip>{item?.toolTip}</Tooltip>) || <></>
            }
          >
            <Form.Control
              onClick={handleToggle}
              className='cursor-pointer'
              disabled={!canUpdate}
              type='select'
              placeholder={`Select ${item.label}`}
              name={item.field}
              value={state[item.field]}
              onChange={(e) => {
                if (!showMenu) setShowMenu(true);
              }}
            ></Form.Control>
          </OverlayTrigger>
          {canUpdate && (
            <InputGroup.Text
              id='down-arrow'
              className='sj-bg-btn-primary cursor-pointer shadow-sj rounded-tr-2xl rounded-br-2xl'
              onClick={handleToggle}
            >
              <img
                src='/images/Arrow Down.svg'
                alt='select'
                style={{ transform: `scale(1.5)` }}
              />
            </InputGroup.Text>
          )}
        </InputGroup>
        {errors[item.field] && (
          <span className='form-field-error'>{errors[item.field]}</span>
        )}
      </Form.Group>
      <Dropdown.Menu
        ref={divEl}
        className='w-2-3 my-1 shadow-sj border-none rounded-tl-2xl rounded-bl-2xl rounded-br-2xl rounded-tr-none py-0'
        style={{ width: "95%" }}
      >
        {dropDownContent}
        {/* <Dropdown.Item eventKey='option-1'>Option 1</Dropdown.Item>
    <Dropdown.Item eventKey='option-2'>Option 2</Dropdown.Item>
    <Dropdown.Item eventKey='option-3'>Option 3</Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectField;
