import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const timeSheetApi = createApi({
  reducerPath: "timeSheets",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/timeSheet`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchTimeSheets: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "TimeSheet" }, { type: "TimeSheetList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchTimeSheetById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "TimeSheet" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateTimeSheetById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "TimeSheet" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
      deleteTimeSheetById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "TimeSheetList" }];
        },
        query: (id) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
      }),
      createTimeSheet: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "TimeSheetList" }];
        },
        query: (data) => {
          return {
            url: `/`,
            method: "POST",
            body: data,
          };
        },
      }),
      timeSheetExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "TimeSheet" }, { type: "TimeSheetList" }];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchTimeSheetsQuery,
  useFetchTimeSheetByIdQuery,
  useUpdateTimeSheetByIdMutation,
  useDeleteTimeSheetByIdMutation,
  useCreateTimeSheetMutation,
  useTimeSheetExportListQuery,
} = timeSheetApi;
export { timeSheetApi };
