import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchRoleByIdQuery,
  useUpdateRoleByIdMutation,
  useCreateRoleMutation,
  useDeleteRoleByIdMutation,
  useFetchUsersRoleQuery,
  useFetchSecurityLevelsQuery,
} from "../store";
import { usersRoleApi } from "../store/apis/usersRoleApi";
import { securityLevelApi } from "../store/apis/securityLevelApi";
import { useEffect, useState } from "react";
import ModalSJ from "../components/ModalSJ";
import { Button } from "react-bootstrap";

import * as Yup from "yup";
import { roleApi, useResetRoleByIdMutation } from "../store/apis/roleApi";
import { useParams } from "react-router-dom";
import { useSecLevel } from "../hooks/formHooks";
import { useDispatch } from "react-redux";
import { addMessage } from "../store/slices/messageSlice";

const RoleForm = () => {
  const dispatch = useDispatch();
  const storeApi = {
    fetch: useFetchRoleByIdQuery,
    update: useUpdateRoleByIdMutation,
    create: useCreateRoleMutation,
    delete: useDeleteRoleByIdMutation,
  };

  const [secLevel, secLevelUsersRole, securityLevel] = useSecLevel([
    "role",
    "usersRole",
    "securityLevel",
  ]);

  const config = [
    {
      label: "Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Name must be at least 2 characters")
        .required("Name is required"),
    },
    {
      label: "Description",
      field: "description",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.description,
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  const { id } = useParams();

  let relatedListConfig = [
    //User Roles Related List
    {
      secLevel: secLevelUsersRole,

      //Function used as the key on the list
      keyFn: (usersRole) => {
        return usersRole._id;
      },

      recordNames: {
        title: "User Roles",
        formLink: "usersRole",
        listLink: "usersRole",
      },
      listApi: usersRoleApi,

      useFetchQuery: useFetchUsersRoleQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `role._id=${id}^`, //user.active=true^`,

      config: [
        {
          label: "i",
          render: (userRole) => `/usersRole/${userRole._id}`,
        },
        {
          label: "User",
          render: (userRole) => userRole.user.name,
          sortValue: "user.name",
          filterValue: "user",
          filterType: "String",
        },
        {
          label: "User Active", //header label
          render: (userRole) => userRole.user.active.toString(), //How to render the cell
          sortValue: "user.active",
          filterValue: "user.active",
          filterType: "Boolean",
        },
        {
          label: "Role",
          render: (userRole) => userRole.role.name,
          sortValue: "role.name",
          filterValue: "role",
          filterType: "String",
        },
      ],
    },
    //Security Level
    {
      secLevel: securityLevel,

      //Function used as the key on the list
      keyFn: (securityLevel) => {
        return securityLevel._id;
      },

      recordNames: {
        title: "Security Level",
        formLink: "securityLevel",
        listLink: "securityLevel",
      },
      listApi: securityLevelApi,

      useFetchQuery: useFetchSecurityLevelsQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `role._id=${id}^`, //user.active=true^`,

      config: [
        {
          label: "i",
          render: (securityLevel) => `/securityLevel/${securityLevel._id}`,
        },
        {
          label: "Table",
          render: (securityLevel) => securityLevel.table.label,
          sortValue: "table.label",
          filterValue: "table",
          filterType: "String",
        },
        {
          label: "Role",
          render: (securityLevel) => securityLevel.role.name,
          sortValue: "role.name",
          filterValue: "role",
          filterType: "String",
        },
      ],
    },
  ];

  //Are you sure you want to reset modal
  const [
    resetRole,
    { data: resetData, isLoading: resetLoading, error: resetError },
  ] = useResetRoleByIdMutation();

  useEffect(() => {
    if (resetError) {
      dispatch(
        addMessage({
          message: resetError?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    } else if (resetData) {
      dispatch(
        addMessage({
          message: resetData.message || "Role Reset",
          variant: "success",
        })
      );
    }
  }, [resetData, resetError, dispatch]);

  const handleReset = async () => {
    try {
      await resetRole({ id }).unwrap();

      //await deleteById(id).unwrap(); //Without unwrap the error wont be caught
    } catch (err) {
      // console.error("Error:", err);
    }
    handleClose();
  };

  ////////////Modal Section
  const [showModal, setShowModal] = useState(false);
  const handleModalClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  //---- Buttons on Modal
  const actionBar = (
    <div>
      <Button variant='primary' onClick={handleClose}>
        Cancel
      </Button>
      <Button variant='danger' className={"mx-3"} onClick={handleReset}>
        Reset
      </Button>
    </div>
  );

  //--Content of modal and title
  const modal = (
    <ModalSJ
      onClose={handleClose}
      actionBar={actionBar}
      title={"Confirm Reset"}
    >
      <p>
        Are you sure you want to reset this role? All changes to will be
        reverted.
      </p>
    </ModalSJ>
  );

  const uiActions = [
    {
      show: (data) =>
        (data?.systemRole && data.name !== "Company Admin") || false,
      label: "Reset Role to Default",
      action: async (id) => {
        handleModalClick();
      },
      isLoading: resetLoading,
      loadingMessage: "Resetting Role...",
    },
  ];

  return (
    <>
      {showModal && modal}
      <FormSJ
        storeApi={storeApi}
        apiToReset={roleApi}
        config={config}
        validationSchema={validationSchema}
        relatedListConfig={relatedListConfig}
        secLevel={secLevel}
        formTitle='Role'
        table='role'
        redirect='role'
        uiActions={uiActions}
      />
    </>
  );
};

export default requireAuth(RoleForm);
