import requireAuth from "../requireAuth";
import {
  useFetchTimeSheetByIdQuery,
  useUpdateTimeSheetByIdMutation,
  useDeleteTimeSheetByIdMutation,
  useCreateTimeSheetMutation,
} from "../store";
import "../components/timeSheet/timeSheetStyles.css";
import { timeSheetApi } from "../store/apis/timeSheetApi";

import { useSecLevel } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import Loader from "../components/Loader";
import { Button, Placeholder } from "react-bootstrap";
import ContentHeaderTS from "../components/timeSheet/ContentHeaderTS";
import TimeEntry from "../components/timeSheet/TimeEntry";
import moment from "moment";
import TimeEntryModal from "../components/timeSheet/TimeEntryModal";
import NewTimeSheetModal from "../components/timeSheet/NewTimeSheetModal";
import ModalSJ from "../components/ModalSJ";
import Prompt from "../components/Prompt";

import { useDispatch } from "react-redux";
import { addMessage } from "../store/slices/messageSlice";

//Custom hooks to prompt the user if they try to leave the page with unsaved changes
// import { useWindowPromptAndBlocker } from "../components/usePrompt";

const TimeSheetForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);

  const storeApi = {
    fetch: useFetchTimeSheetByIdQuery,
    update: useUpdateTimeSheetByIdMutation,
    delete: useDeleteTimeSheetByIdMutation,
    create: useCreateTimeSheetMutation,
  };

  ///Get APIs from the storey
  const [
    create,
    {
      data: createData,
      error: createError,
      isLoading: createIsLoading,
      reset: resetCreate,
    },
  ] = storeApi.create();

  const [
    updateById,
    { data: updateData, error: updateError, isLoading: updateIsloading },
  ] = storeApi.update();

  const [deleteById, { isLoading: deleteIsLoading }] = storeApi.delete();

  const { data, error, isFetching, refetch } = storeApi.fetch({
    id,
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, timeSheetApi);

  const handleSave = async () => {
    //Check TimeEntry Day against data.recrods.timeEntries to see if there are any changes
    //If there are changes, then pass the changes to the updateById function

    const updatedTimeSheet = { timeEntries: [] };

    for (let i = 0; i < timeSheetState.timeEntries.length; i++) {
      const timeEntry = timeSheetState.timeEntries[i];

      //check if the timeentry id starts with -1
      if (timeEntry._id.toString().startsWith("-1")) {
        //This is a new timeEntry

        const myNewTimeEntry = {
          _id: "-1",
          //Don't send timeEntryDays that have 0 hours if its a new time entry
          timeEntryDays: timeEntry.timeEntryDays.filter((x) => {
            return x.hours > 0;
          }),
        };

        if (timeEntry.isWorkingTime) {
          myNewTimeEntry.task = timeEntry.task._id;
          myNewTimeEntry.isWorkingTime = true;
        } else {
          myNewTimeEntry.nonWorkingCategory = timeEntry.nonWorkingCategory;
          myNewTimeEntry.isWorkingTime = false;
        }

        updatedTimeSheet.timeEntries.push(myNewTimeEntry);

        continue;
      }

      const newTimeEntry = {
        _id: timeEntry._id,
        delete: timeEntry.delete || false,
        timeEntryDays: [],
      };

      let hasChanges = false;
      for (let di = 0; di < data.records.timeEntries.length; di++) {
        const dTimeEntry = data.records.timeEntries[di];

        //Timeentry was sent with the initial data
        if (timeEntry._id === dTimeEntry._id) {
          for (let dj = 0; dj < dTimeEntry.timeEntryDays.length; dj++) {
            const dataDay = dTimeEntry.timeEntryDays[dj];
            const day = timeEntry.timeEntryDays[dj];

            if (day.hours !== dataDay.hours) {
              //This day has changed
              newTimeEntry.timeEntryDays.push(day);
              hasChanges = true;
            }
          }
        }
      }

      if (hasChanges || timeEntry.delete) {
        updatedTimeSheet.timeEntries.push(newTimeEntry);
      }
    }

    if (updatedTimeSheet.timeEntries.length === 0) {
      // setDisplayMessage("No changes to save");
      dispatch(
        addMessage({ message: "No changes to save", variant: "warning" })
      );
      // //After 3 seconds clear the message
      // setTimeout(() => {
      //   setDisplayMessage("");
      // }, 3000);
      return;
    }

    await updateById({
      body: updatedTimeSheet,
      id,
    }).unwrap();

    setIsDirty(false);
    //setUnsaved(false);
    refetch();
  };

  //DELETE
  const handleDelete = async () => {
    try {
      await deleteById(id).unwrap(); //Without unwrap the error wont be caught
      navigate(-1);
    } catch (error) {
      //Error here is the same as deleteError but its before its assigned in the next render.... i think
      // console.log("catch ", error);
      dispatch(
        addMessage({
          message: error?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    }

    handleClose();
  };

  ////////////Modal Section
  const [showModal, setShowModal] = useState(false);
  const handleModalClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  //---- Buttons on Modal
  const actionBar = (
    <div>
      <Button variant='primary' onClick={handleClose}>
        Cancel
      </Button>
      <Button variant='danger' className={"mx-3"} onClick={handleDelete}>
        Delete
      </Button>
    </div>
  );

  //--Content of modal and title
  const modal = (
    <ModalSJ
      onClose={handleClose}
      actionBar={actionBar}
      title={"Confirm Delete"}
    >
      <p>Are you sure you want to delete this Time Sheet?</p>
    </ModalSJ>
  );

  //END DELETE

  // const clearMessage = () => {
  //   setDisplayMessage("");
  // };

  const [secLevel] = useSecLevel(["timeSheet"]);

  //Get front end security from authSlice
  // eslint-disable-next-line
  const [canUpdate, setCanUpdate] = useState(secLevel.update);
  // eslint-disable-next-line
  const [canDelete, setCanDelete] = useState(secLevel.delete);

  // const [displayMessage, setDisplayMessage] = useState("");

  let content;
  let displayError = error || updateError || createError;
  let displaySuccess = updateData || createData;

  //Put the piece of the data that are going to change into a piece of state
  const [timeSheetState, setTimeSheetState] = useState({});
  const [originalTimeSheet, setOriginalTimeSheet] = useState({});
  const [isNewTimeSheet, setIsNewTimeSheet] = useState(false);
  useEffect(() => {
    if (data) {
      passDataToState(data);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (updateData) {
      if (updateData.warningMessage) {
        dispatch(
          addMessage({ message: updateData.warningMessage, variant: "warning" })
        );
        // setDisplayMessage(updateData.warningMessage);
      }
    }
    // else {
    //   setDisplayMessage("");
    // }
  }, [updateData, dispatch]);

  const passDataToState = useCallback(
    (data) => {
      const newTimeSheetState = {
        timeEntries: [],
        number: data.records.number,
        weekStarts: data.records.weekStarts,
        user: data.records.user,
        disabledDays: {},
      };

      if (id === "-1") {
        setIsNewTimeSheet(true);
        setShowNewTimeSheetModal(true);
        return;
      }

      //Get disabled days based on user start and end date
      const userStartDate = moment.utc(data.records.user.startDate);
      const userEndDate = moment.utc(data.records.user.endDate);
      const isUserActive = data.isUserActive || false;

      for (let i = 0; i < data.timeSheetUtil.emptyWeek.length; i++) {
        const dayOfWeek = moment.utc(data.timeSheetUtil.emptyWeek[i].date);

        if (!isUserActive) {
          newTimeSheetState.disabledDays[data.timeSheetUtil.emptyWeek[i].date] =
            "User is inactive. Time entries cannot be adjusted on this time sheet.";
        } else if (dayOfWeek < userStartDate) {
          newTimeSheetState.disabledDays[data.timeSheetUtil.emptyWeek[i].date] =
            "This date is before the User's start date";
        } else if (dayOfWeek > userEndDate) {
          newTimeSheetState.disabledDays[data.timeSheetUtil.emptyWeek[i].date] =
            "This date is after the User's end date";
        }
      }

      for (let i = 0; i < data.records.timeEntries.length; i++) {
        const timeEntry = data.records.timeEntries[i];

        //This is how the data object is structured
        const newTimeEntry = {
          _id: timeEntry._id,
          isWorkingTime: timeEntry.isWorkingTime,
          timeEntryDays: timeEntry.timeEntryDays.map((x) => {
            return { id: x._id, hours: x.hours, rate: x.rate, date: x.date };
          }),
        };

        if (timeEntry.isWorkingTime) {
          newTimeEntry.task = {
            _id: timeEntry.task._id,
            name: timeEntry.task.name,
            allowTimeEntry: timeEntry.task.allowTimeEntry,
            active: timeEntry.task.active,
            number: timeEntry.task.number,
            startDate: timeEntry.task.startDate,
            endDate: timeEntry.task.endDate,
          };
          newTimeEntry.project = {
            name: timeEntry.task.project.name,
            active: timeEntry.task.project.active,
            allowTimeEntry: timeEntry.task.project.allowTimeEntry,
            number: timeEntry.task.project.number,
            startDate: timeEntry.task.project.startDate,
            endDate: timeEntry.task.project.endDate,
          };
          newTimeEntry.client = {
            name: timeEntry.task.project.client.name,
            number: timeEntry.task.project.client.number,
          };
        } else {
          newTimeEntry.nonWorkingCategory = timeEntry.nonWorkingCategory;
        }

        newTimeSheetState.timeEntries.push(newTimeEntry);
      }

      //sort data.records.timesheet by client, task, project
      newTimeSheetState.timeEntries.sort((a, b) => {
        if (a.isWorkingTime && !b.isWorkingTime) return -1;
        else if (a.isWorkingTime && b.isWorkingTime) {
          if (a.client.name < b.client.name) {
            return -1;
          } else if (a.client.name > b.client.name) {
            return 1;
          } else {
            if (a.project.number < b.project.number) {
              return -1;
            } else if (a.project.number > b.project.number) {
              return 1;
            } else {
              if (a.task.number < b.task.number) {
                return -1;
              } else if (a.task.number > b.task.number) {
                return 1;
              } else {
                return 0;
              }
            }
          }
        } else if (!a.isWorkingTime && !b.isWorkingTime) {
          if (a.nonWorkingTime < b.nonWorkingTime) {
            return -1;
          } else if (a.nonWorkingTime > b.nonWorkingTime) {
            return 1;
          } else {
            return 0;
          }
        }
        return 0;
      });

      setIsDirty(false);
      setOriginalTimeSheet(cloneDeep(newTimeSheetState));
      setTimeSheetState(newTimeSheetState);
    },
    [id]
  );

  //This is passed to the timeentry to handle the change to the state
  const handleDayChange = (e) => {
    //name is the index of the timeentryday
    //id is the id of the timeentry
    let { name, value, id } = e.target;

    if (value > 24) {
      dispatch(
        addMessage({
          message: "Hours cannot be greater than 24",
          variant: "warning",
        })
      );
      // setDisplayMessage("Hours cannot be greater than 24");
      return;
    } else if (value < 0) {
      dispatch(
        addMessage({
          message: "Hours cannot be less than 0",
          variant: "warning",
        })
      );
      // setDisplayMessage("Hours cannot be less than 0");
      return;
    }

    //console.log(parseFloat(value).toFixed(2));
    //trim value to 2 decimal places
    value = parseFloat(value) || 0;
    value = Math.floor(parseInt(value * 100, 10)) / 100;

    id = id.split("^")[0];

    let day = "";
    const newTimeSheetState = cloneDeep(timeSheetState);
    for (let i = 0; i < newTimeSheetState.timeEntries.length; i++) {
      const myId = newTimeSheetState.timeEntries[i]._id;

      if (myId.toString() === id.toString()) {
        newTimeSheetState.timeEntries[i].timeEntryDays[name].hours = value;
        day = newTimeSheetState.timeEntries[i].timeEntryDays[name].date;
      }
    }

    let totalHours = 0;
    //Check if a day has more than 24 hours across all time entries
    for (let i = 0; i < newTimeSheetState.timeEntries.length; i++) {
      const timeEntry = newTimeSheetState.timeEntries[i];

      for (let j = 0; j < timeEntry.timeEntryDays.length; j++) {
        if (timeEntry.timeEntryDays[j].date === day) {
          totalHours += timeEntry.timeEntryDays[j].hours;
          continue;
        }
      }
    }
    if (totalHours > 24) {
      dispatch(
        addMessage({
          message: `Hours for ${day
            .toString()
            .substring(0, 10)} cannot be greater than 24`,
          variant: "warning",
        })
      );
      // setDisplayMessage(
      //   `Hours for ${day.toString().substring(0, 10)} cannot be greater than 24`
      // );
      return;
    }

    // console.log("originalTimeSheet ", originalTimeSheet);
    // console.log("newTimeSheetState ", newTimeSheetState);

    handleIsDirty(newTimeSheetState);
    //setUnsaved(true);
    // setDisplayMessage("");
    setTimeSheetState(newTimeSheetState);
  };

  const handleIsDirty = (newTimeSheetState) => {
    // console.log("originalTimeSheet", originalTimeSheet);
    // console.log("newTimeSheetState", newTimeSheetState);
    setIsDirty(!isEqual(originalTimeSheet, newTimeSheetState));
  };

  const handleDeleteTimeEntry = (timeEntry) => {
    //If the id starts with -1 just remove it from the state because it is a new time entry and not synced with the database yet
    if (timeEntry._id.toString().startsWith("-1")) {
      const newTimeSheetState = cloneDeep(timeSheetState);
      newTimeSheetState.timeEntries = newTimeSheetState.timeEntries.filter(
        (x) => x._id !== timeEntry._id
      );
      //setUnsaved(true);
      handleIsDirty(newTimeSheetState);
      setTimeSheetState(newTimeSheetState);
      return;
    }

    //if the id does not start with -1 then flag it as delete
    const newTimeSheetState = cloneDeep(timeSheetState);
    for (let i = 0; i < newTimeSheetState.timeEntries.length; i++) {
      const myId = newTimeSheetState.timeEntries[i]._id;

      if (myId.toString() === timeEntry._id.toString()) {
        newTimeSheetState.timeEntries[i].delete = true;

        for (
          let j = 0;
          j < newTimeSheetState.timeEntries[i].timeEntryDays.length;
          j++
        ) {
          const timeEntryDay =
            newTimeSheetState.timeEntries[i].timeEntryDays[j];
          timeEntryDay.hours = 0;
        }

        handleIsDirty(newTimeSheetState);
        setTimeSheetState(newTimeSheetState);
        return;
      }
    }
  };

  //New Time Entry Section
  const [showNewTimeEntryModal, setShowNewTimeEntryModal] = useState(false);
  const handleNewTimeEntryClick = () => {
    // setDisplayMessage("");
    setShowNewTimeEntryModal(true);
  };

  const handleNewTimeEntryModalClose = () => {
    setShowNewTimeEntryModal(false);
  };

  const handleCreateNewTimeEntry = (newTimeEntry) => {
    const newTimeSheetState = { ...timeSheetState };

    //Check the task._id to see if it is in the timeSheetState. If it is throw an error/message
    for (let i = 0; i < newTimeSheetState.timeEntries.length; i++) {
      const timeEntry = newTimeSheetState.timeEntries[i];
      if (
        newTimeEntry.isWorkingTime &&
        timeEntry.isWorkingTime &&
        timeEntry.task._id === newTimeEntry.task._id
      ) {
        dispatch(
          addMessage({
            message: `"${newTimeEntry.task.number} - ${newTimeEntry.task.name}" already has a time entry in this time sheet.`,
            variant: "warning",
          })
        );
        // setDisplayMessage(
        //   `"${newTimeEntry.task.number} - ${newTimeEntry.task.name}" already has a time entry in this time sheet.`
        // );
        return;
      } else if (
        !newTimeEntry.isWorkingTime &&
        !timeEntry.isWorkingTime &&
        timeEntry?.nonWorkingCategory === newTimeEntry?.nonWorkingCategory
      ) {
        dispatch(
          addMessage({
            message: `"${newTimeEntry.nonWorkingCategory}" already has a time entry in this time sheet.`,
            variant: "warning",
          })
        );
        // setDisplayMessage(
        //   `"${newTimeEntry.nonWorkingCategory}" already has a time entry in this time sheet.`
        // );
        return;
      }
    }

    newTimeEntry.timeEntryDays = data.timeSheetUtil.emptyWeek.map((x) => {
      return { hours: 0, rate: 0, date: x.date };
    });

    newTimeSheetState.timeEntries.push(newTimeEntry);
    //setUnsaved(true);
    handleIsDirty(newTimeSheetState);
    setTimeSheetState(newTimeSheetState);
  };

  //--Content of modal and title
  const newTimeEntryModal = (
    <TimeEntryModal
      onClose={handleNewTimeEntryModalClose}
      // actionBar={actionBar}
      title={"New Time Entry"}
      timeSheetState={timeSheetState}
      handleCreateNewTimeEntry={handleCreateNewTimeEntry}
    ></TimeEntryModal>
  );

  //New Time Sheet Section
  const [showNewTimeSheetModal, setShowNewTimeSheetModal] = useState(
    id === "-1"
  );

  //This is the data that is returned from the create api
  //Assumtion is that if this is populated then a new record was just created and you should navigate to iy.
  let loading =
    isFetching || updateIsloading || deleteIsLoading || createIsLoading;

  useEffect(() => {
    if (createData && createData.id) {
      if (id !== createData.id) {
        setShowNewTimeSheetModal(false);
        resetCreate();
        navigate(`/timesheet/${createData.id}`);
      }
    } else if (createError) {
      //after 2 seconds naviage back to the timeSheet page
      setTimeout(() => {
        navigate(`/timeSheet`);
      }, 2000);
    } else if (!showNewTimeSheetModal && id === "-1" && !loading) {
      navigate("/timesheet");
    }
  }, [
    createData,
    id,
    createError,
    navigate,
    showNewTimeSheetModal,
    loading,
    resetCreate,
  ]);

  const handleNewTimeSheetModalClose = () => {
    setShowNewTimeSheetModal(false);
  };

  const handleCreateNewTimeSheet = async (newTimeSheet) => {
    await create(newTimeSheet).unwrap();
  };

  const newTimeSheetModal = (
    <NewTimeSheetModal
      onClose={handleNewTimeSheetModalClose}
      // actionBar={actionBar}
      title={"New Time Sheet"}
      timeSheetState={timeSheetState}
      handleCreateNew={handleCreateNewTimeSheet}
      data={data}
    ></NewTimeSheetModal>
  );

  const loadingContent = (
    <>
      <Loader />
      <Placeholder animation='glow' className='flex grow justify-center'>
        <Placeholder className='my-4 timeEntry' />
      </Placeholder>
      <Placeholder animation='glow' className='flex grow justify-center'>
        <Placeholder className='my-4 timeEntry' />
      </Placeholder>
    </>
  );

  useEffect(() => {
    if (displayError) {
      dispatch(
        addMessage({
          message: displayError?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    }
  }, [displayError, dispatch]);

  useEffect(() => {
    if (displaySuccess) {
      dispatch(
        addMessage({
          message: displaySuccess.message,
          variant: "success",
        })
      );
    }
  }, [displaySuccess, dispatch]);

  // if (loading && !data) {
  if (loading && !data) {
    content = loadingContent;
  } else if (
    data &&
    timeSheetState.timeEntries &&
    data.timeSheetUtil &&
    !displayError
  ) {
    //Populate timeEntries
    const timeEntries = timeSheetState.timeEntries;

    const timeEntryHeaders = data.timeSheetUtil.emptyWeek.map((x, index) => {
      return (
        <th className='font-normal w-7th' key={index}>
          {/* {moment.utc(x.date).format("ddd") +
            " - " +
            moment.utc(x.date).format("M/D")} */}
          <div className='text-center leading-normal lg:leading-loose'>
            <span>{moment.utc(x.date).format("ddd")}</span>
            <span className='hidden lg:inline'> - </span>
            <span className='hidden lg:inline'>
              {moment.utc(x.date).format("M/D")}
            </span>
            <div className='block lg:hidden'>
              {moment.utc(x.date).format("M/D")}
            </div>
          </div>
        </th>
      );
    });

    const timeEntryContent = timeEntries.map((timeEntry, index) => {
      if (timeEntry.delete) return null;

      return (
        <TimeEntry
          key={timeEntry._id}
          timeEntry={timeEntry}
          timeEntryHeaders={timeEntryHeaders}
          handleDayChange={handleDayChange}
          handleDeleteTimeEntry={handleDeleteTimeEntry}
          isUserActive={data?.isUserActive || false}
          canUpdate={canUpdate}
          disabledDays={timeSheetState.disabledDays}
        />
      );
    });

    content = (
      <>
        {loading && <Loader />}

        {/* I didnt want to remove this. There is so much surgery to do here to move messages to the slice */}
        {/* {displayMessage && (
          <Message variant='warning' clearMessage={clearMessage} i={0}>
            {displayMessage}
          </Message>
        )} */}

        {timeEntryContent}
        {/* {timeEntryContent && isDirty && <Prompt />} */}
        {timeEntryContent && <Prompt isDirty={isDirty} />}
      </>
    );
  } else if (loading) {
    content = <Loader />;
  }
  // isEqual(originalTimeSheet, timeSheetState)

  return (
    <>
      {showNewTimeEntryModal && newTimeEntryModal}
      {showNewTimeSheetModal && data && data.user && newTimeSheetModal}
      {showModal && modal}
      <div className='min-h-screen min-w-full'>
        <ContentHeaderTS
          title='Time Sheet'
          data={data}
          createData={createData}
          timeSheetState={timeSheetState}
          handleSave={handleSave}
          canUpdate={canUpdate}
          isLoading={loading}
          isBlocking={isDirty}
          handleNewTimeEntryClick={handleNewTimeEntryClick}
          showNewTimeSheetModal={showNewTimeSheetModal}
        ></ContentHeaderTS>

        <div className='py-2 -translate-y-[50px]'>
          {content}
          {!loading &&
            !isNewTimeSheet &&
            !showNewTimeSheetModal &&
            data &&
            canDelete && (
              <div className='justify-center flex grow my-3'>
                <Button
                  onClick={handleModalClick}
                  variant='danger'
                  className='my-3'
                >
                  Delete Time Sheet
                </Button>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default requireAuth(TimeSheetForm);
