import { useSelector } from "react-redux";
import * as Yup from "yup";

const useSecLevel = (pageArray) => {
  const secLevel = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need

    const secArray = [];

    for (let i = 0; i < pageArray.length; i++) {
      const page = pageArray[i];

      secArray.push(state.auth.secLevel[page] || false);
    }
    return secArray;
  });

  return secLevel;
};

const useFinalConfig = (data, config) => {
  if (!data) {
    return { validationSchema: Yup.object().shape({}), finalConfig: [] };
  }

  let finalConfig = [];
  for (let j = 0; j < data.records.length; j++) {
    const dataItem = data.records[j];

    for (let i = 0; i < config.length; i++) {
      const configItem = config[i];

      if (dataItem.column.name === configItem.field) {
        configItem.label = dataItem.label;
        configItem.toolTip = dataItem.toolTip || "";

        //Add the reference table name to the configItem
        if (dataItem.column.type === "Reference") {
          configItem.inputType = "reference";
          configItem.refTable = dataItem.column.referenceTable.name;
        }

        finalConfig.push(configItem);
        break;
      }
    }
  }

  let yupShape = {};
  for (let i = 0; i < finalConfig.length; i++) {
    const item = finalConfig[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  return { validationSchema, finalConfig };
};

export { useSecLevel, useFinalConfig };
