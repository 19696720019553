import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { rateApi } from "../store/apis/rateApi";
import { useFetchRatesQuery, useRateExportListQuery } from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
import { useFinalListConfig } from "../hooks/listHooks";

const RateList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("rate");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (rate) => `/rate/${rate._id}`,
    },
    {
      label: "Number",
      render: (rate) => rate.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String",
    },
    {
      label: "Rate Card",
      render: (rate) => rate?.rateCard?.name || "",
      sortValue: "rateCard",
      filterValue: "rateCard",
      filterType: "String", //This is only used for the comparison logic on the front end.
      inputType: "reference",
    },
    {
      label: "Title",
      render: (rate) => rate?.title?.name || "",
      sortValue: "title",
      filterValue: "title",
      filterType: "String", //This is only used for the comparison logic on the front end.
      inputType: "reference",
    },
    {
      label: "Rate",
      render: (rate) => rate.rate,
      sortValue: "rate",
      filterValue: "rate",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Rate",
      render: (rate) => rate.rate,
      sortValue: "rate",
      filterValue: "rate",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },

    {
      label: "Created At", //header label
      render: (rate) => {
        return new Date(rate.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },
    {
      label: "Updated At", //header label
      render: (rate) => {
        return new Date(rate.updatedAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "updatedAt",
      filterValue: "updatedAt",
      filterType: "Date",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (rate) => {
    return rate._id;
  };

  const recordNames = {
    title: "Rates",
    formLink: "rate",
    listLink: "rate", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={rateApi}
        useFetchQuery={useFetchRatesQuery}
        useExportQuery={useRateExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(RateList);
