import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchClientByIdQuery,
  useUpdateClientByIdMutation,
  useDeleteClientByIdMutation,
  useCreateClientMutation,
  useFetchProjectsQuery,
  useFetchRateCardsQuery,
} from "../store";
import { clientApi } from "../store/apis/clientApi";
import { projectApi } from "../store/apis/projectApi";
import { formViewApi } from "../store/apis/formViewApi";

import { useParams } from "react-router";
import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";
import { rateCardApi } from "../store/apis/rateCardApi";

const ClientForm = () => {
  const storeApi = {
    fetch: useFetchClientByIdQuery,
    update: useUpdateClientByIdMutation,
    delete: useDeleteClientByIdMutation,
    create: useCreateClientMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchFormViewByTableQuery("client");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel, secLevelProject] = useSecLevel(["client", "project"]);

  const config = [
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Client Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Client name must be at least 2 characters")
        .required("Client name is required"),
    },
    {
      label: "Client Id",
      field: "clientId",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.clientId,
    },
    {
      label: "Rate Card",
      field: "rateCard",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.rateCard?.name || "",
      id: (data) => data?.rateCard?._id || "",
      useFetchQuery: useFetchRateCardsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string().matches(/^[0-9a-fA-F]{24}$/, {
        message: "Please select a valid rate card",
        excludeEmptyString: true,
      }),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (rateCard) => rateCard.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Number",
            render: (rateCard) => rateCard.number,
            sortValue: "number",
            // render: (rateCard) => <div className={`p-3 m-2 ${rateCard.color}`}></div>,
            filterValue: "number",
            filterType: "String",
          },
          {
            label: "Active", //header label
            render: (rateCard) => rateCard.active.toString(), //How to render the cell
            sortValue: "active",
            filterValue: "active",
            filterType: "Boolean",
          },
        ],
        keyFn: (rateCard) => {
          return rateCard._id;
        },
        recordNames: {
          title: "Select a Rate Card",
          formLink: "rateCard",
          listLink: "rateCards",
        },
        listApi: rateCardApi,
        useFetchQuery: useFetchRateCardsQuery,
      },
    },
  ];

  const { id } = useParams();

  //This is an array of related lists. Each element will be a tab on the realted list

  let relatedListConfig = [
    //User Roles Related List
    {
      secLevel: secLevelProject,

      //Function used as the key on the list
      keyFn: (project) => {
        return project._id;
      },

      recordNames: {
        title: "Projects",
        formLink: "project",
        listLink: "project",
      },
      listApi: projectApi,

      useFetchQuery: useFetchProjectsQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `client._id=${id}^`,

      config: [
        {
          label: "i",
          render: (project) => `/project/${project._id}`,
        },
        {
          label: "Number",
          render: (project) => project.number,
          sortValue: "number",
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Project Name",
          render: (project) => project.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Status",
          render: (project) => project.status,
          sortValue: "status",
          filterValue: "status",
          filterType: "String",
        },
      ],
    },
  ];

  //Filter out any related lists the user doesn't have access to
  relatedListConfig = relatedListConfig.filter((rl) => {
    return rl.secLevel && rl.secLevel.read;
  });

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={clientApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={relatedListConfig}
        secLevel={secLevel}
        formTitle='Client'
        table='client'
        redirect='client'
      />
    ))
  );
};

export default requireAuth(ClientForm);
