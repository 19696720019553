// ConditionalLeftNav.js

import LeftNav from "./LeftNav";

const ConditionalLeftNav = () => {
  return (
    <>
      <LeftNav className='hidden md:block' isMobile={false} />

      <LeftNav
        className='rounded-2xl my-1 h-auto w-[99%] left-0 right-0 mx-auto md:hidden'
        isMobile={true}
      />
    </>
  );
};

export default ConditionalLeftNav;
