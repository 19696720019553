import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { departmentApi } from "../store/apis/departmentApi";
import {
  useFetchDepartmentsQuery,
  useDepartmentExportListQuery,
} from "../store";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
import { useFinalListConfig } from "../hooks/listHooks";
import { useSelector } from "react-redux";

const DepartmentList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("department");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (department) => `/department/${department._id}`,
    },
    {
      label: "Number",
      render: (department) => department.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Name",
      render: (department) => department.name,
      sortValue: "name",
      filterValue: "name",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Department Head",
      render: (department) => department?.departmentHead?.name || "",
      sortValue: "departmentHead",
      filterValue: "departmentHead",
      filterType: "String",
      //This is no longer needed as it is pulling from the list hook and column config
      inputType: "reference",
    },
    {
      label: "Department Id",
      render: (department) => department.departmentId,
      sortValue: "departmentId",
      filterValue: "departmentId",
      filterType: "String",
    },
    {
      label: "Description",
      render: (department) => department.description,
      sortValue: "description",
      filterValue: "description",
      filterType: "String",
    },
    {
      label: "Company",
      render: (department) => department?.company?.name,
      sortValue: "company",
      filterValue: "company",
      filterType: "String",
    },
    {
      label: "Created", //header label
      render: (department) => {
        return new Date(department.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },
    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (department) => {
    return department._id;
  };

  const recordNames = {
    title: "Departments",
    formLink: "department",
    listLink: "department", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={departmentApi}
        useFetchQuery={useFetchDepartmentsQuery}
        useExportQuery={useDepartmentExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(DepartmentList);
