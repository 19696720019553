import Table from "./Table";

const SortableTable = (props) => {
  // Handle click is not used in this component and is passed down to the table component
  // eslint-disable-next-line no-unused-vars
  const {
    // handleClick,
    config,
    data,
    sortBy,
    sortOrder,
    setSortColumn,
    canSort,
    hideLink,
    // isFetching,
  } = props;

  const updatedConfig = config.map((column) => {
    if (!column.sortValue) {
      return column;
    }

    return {
      ...column,
      header: () => (
        <th
          className={canSort ? "cursor-pointer" : ""}
          onClick={() => {
            if (!canSort) return;
            setSortColumn(column.sortValue);
          }}
        >
          <div className='flex justify-center'>
            {column.label}
            {getIcons(column.sortValue, sortBy, sortOrder)}
          </div>
        </th>
      ),
    };
  });

  return (
    <Table {...props} config={updatedConfig} data={data} hideLink={hideLink} />
  );
};

function getIcons(sortValue, sortBy, sortOrder) {
  if (sortValue !== sortBy) {
    return <div></div>;
  }

  if (sortOrder === null) {
    return <div></div>;
  } else if (sortOrder === "asc") {
    return (
      <img
        className='rotate-180 mx-2'
        src='/images/Sort Arrow.svg'
        alt='Sort'
      />
    );
  } else {
    return <img className='mx-2' src='/images/Sort Arrow.svg' alt='Sort' />;
  }
}

export default SortableTable;
