import { useParams, useNavigate, useLocation } from "react-router-dom";
import ContentHeader from "./ContentHeader";
import { useEffect, useState } from "react";
import { Container, Form, Button, Col, Row } from "react-bootstrap";
import Loader from "./Loader";
import ModalSJ from "./ModalSJ";
import List from "./List";
import ReferenceField from "./ReferenceField";
import SelectField from "./SelectField";
import { useCheckAuth } from "../hooks/checkAuth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Prompt from "./Prompt";
import { useDispatch } from "react-redux";
import { addMessage } from "../store/slices/messageSlice";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import BackButton from "./BackButton";
import LoadingButton from "./LoadingButton";

const FormSJ = ({
  storeApi,
  apiToReset,
  config,
  validationSchema,
  relatedListConfig,
  secLevel,
  formTitle,
  table,
  redirect,
  uiActions,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);
  const [originalFormData, setOriginalFormData] = useState({});

  //This is used to populate the form with data from the api
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const queryField = queryParams.get("queryField") || "";
  const queryFieldID = queryParams.get("queryFieldID") || "";

  const [secConfig, setSecConfig] = useState([...config]);

  ///Get APIs from the store
  const [
    updateById,
    { data: updateData, error: updateError, isLoading: updateIsloading },
  ] = storeApi.update();

  const [deleteById, { error: deleteError, isLoading: deleteIsLoading }] =
    storeApi.delete();

  const [
    create,
    { data: createData, error: createError, isLoading: createIsLoading },
  ] = storeApi.create();

  const { data, error, isFetching } = storeApi.fetch({
    id,
    queryField,
    queryFieldID,
  });

  //Setup State for all of the form elements

  const [state, setState] = useState(null);
  const [stateID, setStateID] = useState(null);

  //State for related lists
  //Setup state for the related list index that is selected
  const [selectedRLIndex, setSelectedRLIndex] = useState(0);
  const [relatedList, setRelatedList] = useState(
    relatedListConfig[selectedRLIndex] || null
  );

  //this ensures that the related list is updated when the id changes i.e., a new record is created
  useEffect(() => {
    setRelatedList(relatedListConfig[selectedRLIndex]);
    // eslint-disable-next-line
  }, [id]);

  const handleSwitchingRelatedTabs = (index) => {
    setSelectedRLIndex(index);
    setRelatedList(relatedListConfig[index]);
  };

  const relatedListTitles = relatedListConfig.map((rl, i) => {
    return {
      title: rl.recordNames.title,
      isSelected: selectedRLIndex === i,
      index: i,
    };
  });

  //Get front end security from authSlice
  const [canDelete, setCanDelete] = useState(secLevel.delete);
  const [canUpdate, setCanUpdate] = useState(secLevel.update);
  const [canCreate, setCanCreate] = useState(secLevel.create);

  useEffect(() => {
    //This is the data that is returned from the create api
    //Assumption is that if this is populated then a new record was just created and you should navigate to it.
    if (createData && createData.id) {
      if (id === createData.id) {
        return;
      }

      navigate(`/${redirect}/${createData.id}`);

      //navigate("/userlist");
    }
    // eslint-disable-next-line
  }, [navigate, createData]);

  //This populates the state of the form when the data changes i.e., it comes back form the api
  useEffect(() => {
    //Once you have data from the api spread it into the state
    if (data) {
      let newState = {};
      let newStateID = {};

      //Loop through data.securityLevel.fieldSecurityLevel[table][action] to get the fields that the user has access to
      //This data is coming from the Backend in the authentication.js controller under the getLoginObject function.
      //It is then stored on the user record and retrived with each request
      //Then loop through the config and remove the fields that the user does not have access to
      //The data is also removed on the server side and isn't sent to the client
      const fieldSec = data.securityLevel.fieldSecurityLevel[table]["read"];
      const fieldSecUpdate =
        data.securityLevel.fieldSecurityLevel[table]["update"];

      let tableLevel = false;
      if (data.securityLevel.tableSecurityLevel) {
        tableLevel = data.securityLevel.tableSecurityLevel[table];
        setCanDelete(tableLevel.delete);
        setCanUpdate(tableLevel.update);
        setCanCreate(tableLevel.create);
      }

      for (let i = 0; i < config.length; i++) {
        const item = config[i];
        item.isDisabled = false;

        if (!item.field) continue;

        // if (item.inputType === "custom") {
        //   continue;
        // }

        if (!fieldSec.includes(item.field)) {
          config.splice(i, 1);
          i--;
          continue;
        } else if (item.inputType === "reference") {
          if (!data.securityLevel.tableSecurityLevel[item.refTable].read) {
            config.splice(i, 1);
            i--;
            continue;
          } else if (!fieldSecUpdate || !fieldSecUpdate.includes(item.field)) {
            item.isDisabled = true;
          }
          // else if (!data.securityLevel.tableSecurityLevel[table].update)
          //   item.isDisabled = true;
        } else if (!fieldSecUpdate || !fieldSecUpdate.includes(item.field)) {
          item.isDisabled = true;
        } else if (!(canCreate && id === "-1") && !canUpdate) {
          item.isDisabled = true;
        }
        if (!tableLevel || !tableLevel.update) {
          item.isDisabled = true;
        }

        if (!item.isDisabled && item.handleIsDisabled) {
          item.isDisabled = item.handleIsDisabled(id, data);
        }
      }

      setSecConfig([...config]);

      const defaultState = config.reduce(
        (acc, item) => ({ ...acc, [item.field]: item.defaultValue }),
        {}
      );

      let stateIdHolder = {};
      for (let i = 0; i < config.length; i++) {
        const item = config[i];
        if (item.inputType === "reference") {
          stateIdHolder[item.field] = item.defaultValue;
        }
      }

      for (let i = 0; i < config.length; i++) {
        const item = config[i];

        //Populate the state field data from the api
        const field = item.field;
        //Added this because the isDirty flag was being set to true even if you changed a number value back b/c the original value was a string

        if (item.inputType === "number") {
          newState[field] = item.render(data.records);
          if (newState[field]) newState[field] = newState[field].toString();
        } else if (item.inputType !== "custom") {
          newState[field] = item.render(data.records);
        } else if (item.inputType === "custom") {
          newState[field] = data.records[field];
        }
        //Populate the stateID ids from the api
        if (item.inputType === "reference") {
          newStateID[item.field] = item.id(data.records);
        }

        // Check if a value is undefined using typeof
        if (typeof newState[field] === "undefined") {
          newState[field] = item.defaultValue || "";
        }

        // Check if a value is undefined using strict equality
        if (newState[field] === undefined) {
          newState[field] = item.defaultValue || "";
        }
      }

      setState({ ...defaultState, ...newState });
      setStateID({ ...stateIdHolder, ...newStateID });

      setIsDirty(false);
      setOriginalFormData(cloneDeep(newState));
    }

    // eslint-disable-next-line
  }, [data]);

  const handleIsDirty = (newFormData) => {
    setIsDirty(!isEqual(originalFormData, newFormData));
  };

  useCheckAuth(error, apiToReset);

  //Validation
  const [errors, setErrors] = useState({});

  ///Handle Change
  const handleChange = (e, isCheckbox) => {
    const { name } = e.target;
    let value = "";
    if (isCheckbox) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    setState({ ...state, [name]: value });
    handleIsDirty({ ...state, [name]: value });
  };

  let displayError = updateError || deleteError || createError;
  let displaySuccess = updateData || createData;

  //Ref Field changes
  const handleRefSelect = (newState, newStateID) => {
    setState({ ...state, ...newState });
    setStateID({ ...stateID, ...newStateID });
    handleIsDirty({ ...state, ...newState });
  };

  //////////Button Handlers
  //UPDATE
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const createDates = {};
      for (let i = 0; i < secConfig.length; i++) {
        const item = secConfig[i];
        if (item.inputType === "date") {
          createDates[item.field] = new Date(state[item.field]);
          if (createDates[item.field].toString() === "Invalid Date")
            createDates[item.field] = "";
        }
      }

      await validationSchema.validate(
        { ...state, ...stateID, ...createDates },
        { abortEarly: false }
      );
      await updateById({
        body: { ...state, ...stateID },
        id,
      }).unwrap();
      setErrors({});
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  //CREATE
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const createDates = {};
      for (let i = 0; i < secConfig.length; i++) {
        const item = secConfig[i];
        if (item.inputType === "date") {
          createDates[item.field] = new Date(state[item.field]);
          if (createDates[item.field].toString() === "Invalid Date")
            createDates[item.field] = "";
        }
      }

      await validationSchema.validate(
        { ...state, ...stateID, ...createDates },
        { abortEarly: false }
      );
      await create({ ...state, ...stateID }).unwrap();
      setErrors({});
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  //DELETE
  const handleDelete = async () => {
    try {
      await deleteById(id).unwrap(); //Without unwrap the error wont be caught

      //clear the form data on delete
      setState(
        config.reduce(
          (acc, item) => ({ ...acc, [item.field]: item.defaultValue }),
          {}
        )
      );

      navigate(-1);
    } catch (err) {
      // console.error("Error:", err);
    }
    handleClose();
  };

  ////////////Modal Section
  const [showModal, setShowModal] = useState(false);
  const handleModalClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  //---- Buttons on Modal
  const actionBar = (
    <div>
      <Button variant='primary' onClick={handleClose}>
        Cancel
      </Button>
      <Button variant='danger' className={"mx-3"} onClick={handleDelete}>
        Delete
      </Button>
    </div>
  );

  const recordTitle = formTitle?.toLowerCase() || "record";
  //--Content of modal and title
  const modal = (
    <ModalSJ
      onClose={handleClose}
      actionBar={actionBar}
      title={"Confirm Delete"}
    >
      <p>Are you sure you want to delete this {recordTitle}?</p>
    </ModalSJ>
  );

  //////////Bottom Buttons
  let bottomButtons = "";
  if (id === "-1" && canCreate)
    bottomButtons = (
      <Button onClick={handleCreate} variant='primary' className='my-3'>
        Create
      </Button>
    );
  else {
    bottomButtons = (
      <>
        {canDelete && (
          <>
            <Button
              onClick={handleModalClick}
              variant='danger'
              className='my-3'
            >
              Delete
            </Button>
          </>
        )}

        {!canDelete && <div></div>}

        {canUpdate && (
          // <Button type='submit' variant='primary' className='my-3'>
          //   Save
          // </Button>
          <LoadingButton
            type='submit'
            className={"mx-3 my-3"}
            isLoading={updateIsloading || createIsLoading}
          >
            Save
          </LoadingButton>
        )}
      </>
    );
  }

  ///////////////////INPUT MAIN FORM
  let input = <></>;
  if (secConfig && state) {
    input = secConfig.map((item) => {
      if (
        item.inputType === "text" ||
        item.inputType === "email" ||
        item.inputType === "number" ||
        item.inputType === "date" ||
        item.inputType === "textarea"
      ) {
        return (
          <Form.Group
            as={Col}
            md={item.inputType === "textarea" ? "12" : "6"}
            sm='11'
            xs='11'
            className='mx-4 md:mx-0 my-2'
            controlId={item.field}
            key={item.field}
          >
            <Form.Label>
              {item.label}
              {errors[item.field] &&
                errors[item.field]?.indexOf("required") !== -1 && (
                  <span className='required'> *</span>
                )}
            </Form.Label>

            <OverlayTrigger
              placement='top'
              overlay={
                (item.toolTip && <Tooltip>{item?.toolTip}</Tooltip>) || <></>
              }
              // show={item.toolTip ? true : false}
            >
              <Form.Control
                disabled={item.isDisabled}
                type={item.inputType}
                as={item.inputType === "textarea" ? "textarea" : "input"}
                rows={item.inputType === "textarea" ? 2 : 1}
                placeholder={`Enter ${item.label}`}
                // min={item.inputType === "number" && "min=0"}
                name={item.field}
                value={state[item.field] || ""}
                onChange={handleChange}
              ></Form.Control>
            </OverlayTrigger>
            {errors[item.field] && (
              <span className='form-field-error'>{errors[item.field]}</span>
            )}
          </Form.Group>
        );
      } else if (item.inputType === "checkbox") {
        return (
          <Form.Group
            as={Col}
            md='6'
            className='mx-4 md:mx-0 flex align-items-center px-4 my-2'
            controlId={item.field}
            key={item.field}
          >
            <OverlayTrigger
              placement='top'
              overlay={
                (item.toolTip && <Tooltip>{item?.toolTip}</Tooltip>) || <></>
              }
              show={item.toolTip ? true : false}
            >
              <Form.Check
                disabled={item.isDisabled}
                type='checkbox'
                label={item.label}
                name={item.field}
                checked={state[item.field]}
                onChange={(e) => {
                  handleChange(e, true);
                }}
                // onChange={(e) =>
                //   setState({ ...state, [item.field]: e.target.checked })
                // }
              ></Form.Check>
            </OverlayTrigger>
          </Form.Group>
        );
      } else if (item.inputType === "reference") {
        return (
          <ReferenceField
            key={item.field}
            item={item}
            canUpdate={!item.isDisabled}
            errors={errors}
            value={state[item.field]}
            valueID={stateID[item.field]}
            hideIcon={item.hideIcon || false}
            handleRefSelect={handleRefSelect}
            useFetchQuery={item.useFetchQuery}
            refModalListConfig={item.refModalListConfig}
            defaultFilter={item.defaultFilter || ""}
          ></ReferenceField>
        );
      } else if (item.inputType === "select") {
        return (
          <SelectField
            key={item.field}
            item={item}
            canUpdate={!item.isDisabled}
            handleChange={handleChange}
            errors={errors}
            state={state}
          ></SelectField>
        );
      } else if (item.inputType === "custom") {
        const Component = item.customComponent.component;
        return (
          <Component
            key={item.field}
            handleChange={handleChange}
            field={item.field}
            state={state}
            isDisabled={item.isDisabled}
            id={id}
            isLoading={isFetching || updateIsloading || createIsLoading}
            {...item.customComponent.component.props}
          />
        );
      }

      return <></>;
    });
  }

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: error?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (displayError) {
      dispatch(
        addMessage({
          message: displayError?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    }
  }, [displayError, dispatch]);

  useEffect(() => {
    if (displaySuccess) {
      dispatch(
        addMessage({
          message: displaySuccess.message,
          variant: "success",
        })
      );
    }
  }, [displaySuccess, dispatch]);

  ///////////////Content Section
  let content;
  if (isFetching || updateIsloading || createIsLoading || deleteIsLoading) {
    content = <Loader />;
    //I'm removing the && !displayError here not sure if it will cause any issues
  } else if (data) {
    content = (
      <>
        {showModal && modal}
        <Prompt isDirty={isDirty} />

        <Form onSubmit={submitHandler}>
          <Row>{input}</Row>
          <div className='my-2 flex justify-between'>{bottomButtons}</div>
        </Form>

        {uiActions &&
          id !== "-1" &&
          uiActions.map((uiAction, i) => {
            if (!uiAction.show(data.records)) return null;
            return (
              <div
                key={uiAction.label}
                className={i === uiActions.length - 1 ? "mb-3" : ""}
              >
                <button
                  onClick={() => {
                    if (uiAction.isLoading) return;
                    uiAction.action(id);
                  }}
                  className={
                    !uiAction.isLoading ? "link-button" : "link-loading"
                  }
                >
                  {uiAction.isLoading
                    ? uiAction.loadingMessage || ""
                    : uiAction.label}
                </button>
              </div>
            );
          })}

        {relatedListConfig?.length !== 0 && id !== "-1" && relatedList && (
          <List
            key={selectedRLIndex}
            config={relatedList.config}
            keyFn={relatedList.keyFn}
            listApi={relatedList.listApi}
            useFetchQuery={relatedList.useFetchQuery}
            recordNames={relatedList.recordNames}
            // handleClick={true}
            relatedListID={id}
            defaultFilter={relatedList.defaultFilter}
            newButtonLink={
              `/${relatedList.recordNames.formLink}/-1?queryField=${table}&queryFieldID=${id}` ||
              ""
            }
            relatedListTitle={data.records && data.records.name} //This is the name of the record i.e., John Hidalgo on the user its used as a subtitle on the RL
            relatedListTitles={relatedListTitles}
            handleSwitchingRelatedTabs={handleSwitchingRelatedTabs}
          ></List>
        )}
      </>
    );
  }

  return (
    <>
      <div className='sj-bg-C3 min-h-screen min-w-full'>
        <ContentHeader
          title={formTitle}
          subtitle={data && data.name}
          align='center'
        >
          <div>
            <BackButton createData={createData} />
          </div>
        </ContentHeader>

        <Container className='py-2'>{content}</Container>
      </div>
    </>
  );
};

export default FormSJ;
