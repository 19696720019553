import { Button } from "react-bootstrap";
import Loader from "./Loader";

const LoadingButton = ({
  isLoading,
  children,
  variant,
  onClick,
  className,
  type,
}) => {
  return (
    <Button
      variant={variant || "primary"}
      disabled={isLoading}
      onClick={onClick}
      className={className}
      type={type || ""}
    >
      {(isLoading && <Loader className='color-white' />) || children}
    </Button>
  );
};

export default LoadingButton;
