import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchDepartmentByIdQuery,
  useUpdateDepartmentByIdMutation,
  useDeleteDepartmentByIdMutation,
  useCreateDepartmentMutation,
  useFetchUsersQuery,
  useFetchTitlesQuery,
} from "../store";
import { departmentApi } from "../store/apis/departmentApi";
import { userApi } from "../store/apis/userApi";
import { titleApi } from "../store/apis/titleApi";
import { formViewApi } from "../store/apis/formViewApi";

import { useParams } from "react-router";
import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

const DepartmentForm = () => {
  const storeApi = {
    fetch: useFetchDepartmentByIdQuery,
    update: useUpdateDepartmentByIdMutation,
    delete: useDeleteDepartmentByIdMutation,
    create: useCreateDepartmentMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchFormViewByTableQuery("department");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel, secLevelTitle, secLevelUser] = useSecLevel([
    "department",
    "title",
    "user",
  ]);

  const config = [
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Department Name",
      field: "name",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.name,
      validation: Yup.string()
        .min(2, "Department name must be at least 2 characters")
        .required("CLient name is required"),
    },
    {
      label: "Department Id",
      field: "departmentId",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.departmentId,
    },
    {
      label: "Description",
      field: "description",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.description,
    },
    {
      label: "Department Head",
      field: "departmentHead",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.departmentHead?.name || "",
      id: (data) => data?.departmentHead?._id || "",
      useFetchQuery: useFetchUsersQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid user",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (user) => user.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Email",
            render: (user) => user.email,
            sortValue: "email",
            // render: (user) => <div className={`p-3 m-2 ${user.color}`}></div>,
            filterValue: "email",
            filterType: "String",
          },
          {
            label: "Active", //header label
            render: (user) => user.active.toString(), //How to render the cell
            sortValue: "active",
            filterValue: "active",
            filterType: "Boolean",
          },
        ],
        keyFn: (user) => {
          return user._id;
        },
        recordNames: {
          title: "Select a User",
          formLink: "user",
          listLink: "users",
        },
        listApi: userApi,
        useFetchQuery: useFetchUsersQuery,
      },
    },
  ];

  const { id } = useParams();

  let relatedListConfig = [
    //User Roles Related List
    {
      secLevel: secLevelTitle,

      //Function used as the key on the list
      keyFn: (title) => {
        return title._id;
      },

      recordNames: {
        title: "Titles",
        formLink: "title",
        listLink: "title",
      },
      listApi: titleApi,

      useFetchQuery: useFetchTitlesQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `department._id=${id}^`,
      config: [
        {
          label: "i",
          render: (title) => `/title/${title._id}`,
        },
        {
          label: "Number",
          render: (title) => title.number,
          sortValue: "number",
          filterValue: "number",
          filterType: "String",
        },
        {
          label: "Title Name",
          render: (title) => title.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Active",
          render: (title) => title.active.toString(),
          sortValue: "active",
          filterValue: "active",
          filterType: "Boolean",
        },
      ],
    },
    {
      secLevel: secLevelUser,

      //Function used as the key on the list
      keyFn: (user) => {
        return user._id;
      },

      recordNames: {
        title: "Users",
        formLink: "user",
        listLink: "user",
      },
      listApi: userApi,

      useFetchQuery: useFetchUsersQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `department._id=${id}^`,
      config: [
        {
          label: "i",
          render: (user) => `/user/${user._id}`,
        },
        {
          label: "User Name",
          render: (user) => user.name,
          sortValue: "name",
          filterValue: "name",
          filterType: "String",
        },
        {
          label: "Email",
          render: (user) => user.email,
          sortValue: "email",
          filterValue: "email",
          filterType: "String",
        },
        {
          label: "Active",
          render: (user) => user.active.toString(),
          sortValue: "active",
          filterValue: "active",
          filterType: "Boolean",
        },
      ],
    },
  ];

  //Filter out any related lists the user doesn't have access to
  relatedListConfig = relatedListConfig.filter((rl) => {
    return rl.secLevel && rl.secLevel.read;
  });

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={departmentApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={relatedListConfig}
        secLevel={secLevel}
        formTitle='Department'
        table='department'
        redirect='department'
      />
    ))
  );
};

export default requireAuth(DepartmentForm);
