import requireAuth from "../requireAuth";
import ContentHeader from "../components/ContentHeader";

import { timeSheetApi } from "../store/apis/timeSheetApi";
import { holidayApi } from "../store/apis/holidayApi";
import { reportingApi } from "../store/apis/reportingApi";
import {
  useFetchHomePageDataQuery,
  useFetchTimeSheetsQuery,
  useFetchHolidaysQuery,
} from "../store";
import { formatDate } from "../hooks/listHooks";
import { useSelector } from "react-redux";
import { useCheckAuth } from "../hooks/checkAuth";
import List from "../components/List";
import Loader from "../components/Loader";
import { useEffect } from "react";

const Homepage = () => {
  const { userId, usersName } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      userId: state.auth.id, //use this to limit access to the form
      usersName: state.auth.name,
    };
  });

  //Vacation YTD
  const { data, error, isFetching, refetch } = useFetchHomePageDataQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, reportingApi);

  //Current Time Sheets
  const timeSheetConfig = [
    {
      label: "i",
      render: (timeSheet) => `/timeSheet/${timeSheet._id}`,
    },
    {
      label: "Number",
      render: (timeSheet) => timeSheet.number,
      sortValue: "number",
      filterValue: "number",
      filterType: "String",
    },
    {
      label: "Week Starts", //header label
      render: (timeSheet) => {
        return formatDate(timeSheet.weekStarts) || "";
        // return new Date(timeSheet.startDate).toString();
      }, //How to render the cell
      sortValue: "weekStarts",
      filterValue: "weekStarts",
      filterType: "Date",
    },
    {
      label: "User",
      render: (timeSheet) => timeSheet?.user?.name || "",
      sortValue: "user",
      filterValue: "user",
      filterType: "String",
    },
    {
      label: "Total Hours",
      render: (timeSheet) => timeSheet.totalHours,
      sortValue: "totalHours",
      filterValue: "totalHours",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
  ];

  //What is the key for the data?
  const keyFn = (timeSheet) => {
    return timeSheet._id;
  };

  const recordNames = {
    title: "Time Sheets",
    formLink: "timeSheet",
    listLink: "timeSheet", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  //Upcoming Holidays
  const holidayConfig = [
    {
      label: "Name",
      render: (holiday) => holiday.name || "",
      sortValue: "name",
      filterValue: "name",
      filterType: "String",
    },
    {
      label: "Date", //header label
      render: (holiday) => {
        return formatDate(holiday.date) || "";
      }, //How to render the cell
      sortValue: "date",
      filterValue: "date",
      filterType: "Date",
    },
  ];

  //What is the key for the data?
  const holidayKeyFn = (holiday) => {
    return holiday._id;
  };

  const holidayRecordNames = {
    title: "Holidays",
    formLink: "holiday",
    listLink: "holiday", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const headerClasses =
    "h-[50px] text-xl lg:h-[75px] text-center shadow-sj bg-neutral-150 lg:text-2xl font-bold flex justify-center flex-col w-full z-20";
  const cardClasses =
    "py-3 rounded-sj border-solid border border-neutral-200 my-4 shadow-sj";
  return (
    <>
      <div className='min-h-screen min-w-full'>
        <ContentHeader title='Home' subtitle={usersName} align='center' />
        <div className='flex justify-center w-full grow flex-col lg:flex-row'>
          <div className='flex flex-col grow lg:w-7/12  mx-7 '>
            <div className={`${headerClasses} rounded-b-sj`}>
              Current Time Sheets
            </div>
            <div className={cardClasses}>
              <List
                config={timeSheetConfig}
                keyFn={keyFn}
                listApi={timeSheetApi}
                defaultFilter={`user._id=${userId}^`}
                useFetchQuery={useFetchTimeSheetsQuery}
                recordNames={recordNames}
                showHeader={false}
                showPagination={false}
                maxPageSize={4}
                defaultSortBy={"weekStarts"}
                canSort={false}
              ></List>
            </div>
          </div>
          <div className='flex flex-col grow lg:w-4/12 mx-7 '>
            <div className={`${headerClasses} rounded-sj lg:rounded-t-none`}>
              Vacation Used YTD
            </div>

            <div
              className={`${cardClasses} flex flex-col justify-center py-11`}
            >
              {isFetching && <Loader />}
              <div className=' text-8xl text-center'>
                {data && data?.YTDVacation}
              </div>
            </div>
            <div className={`${headerClasses} rounded-sj`}>
              Upcoming Holidays
            </div>
            <div className={cardClasses}>
              <List
                config={holidayConfig}
                keyFn={holidayKeyFn}
                listApi={holidayApi}
                defaultFilter={`date>2024-05-29^`}
                useFetchQuery={useFetchHolidaysQuery}
                recordNames={holidayRecordNames}
                showHeader={false}
                showPagination={false}
                maxPageSize={8}
                defaultSortBy={"date"}
                defaultSortOrder={"asc"}
                canSort={false}
                hideLink={true}
              ></List>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default requireAuth(Homepage);
