import "../contentHeader.css";

const RefModalHeader = ({
  title,
  subtitle,
  children,
  leftButtons,
  rightButtons,
}) => {
  return (
    <>
      <div
        className={
          "content-header-modal z-40 relative rounded-tl-2xl rounded-tr-2xl"
        }
      >
        <div className='mx-4 flex items-center justify-between grow'>
          {leftButtons}

          <div className='text-center font-bold text-2xl'>{title}</div>

          {rightButtons}

          {/* {children} */}
        </div>
      </div>
    </>
  );
};

export default RefModalHeader;
