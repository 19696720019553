import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchListViewByIdQuery,
  useUpdateListViewByIdMutation,
} from "../store";
import { listViewApi } from "../store/apis/listViewApi";

import * as Yup from "yup";
import { useSelector } from "react-redux";

const ListViewForm = () => {
  const storeApi = {
    fetch: useFetchListViewByIdQuery,
    update: useUpdateListViewByIdMutation,
    //I dont have a create or delete for this table so i am using a placeholder so formSJ doesnt break
    create: () => {
      const create = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [create, { data, error, isLoading }];
    },
    delete: () => {
      const deleteById = undefined;
      const data = undefined;
      const error = undefined;
      const isLoading = undefined;
      return [deleteById, { data, error, isLoading }];
    },
  };

  //Get front end security from authSlice
  const { secLevel } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      secLevel: state.auth.secLevel.listView || false, //use this to limit access to the form
    };
  });

  const config = [
    {
      label: "Table",
      field: "table",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.table.name,
      //No validation this will always be read only
    },
    {
      label: "Column",
      field: "column",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.column.name,
    },

    {
      label: "Column Label",
      field: "label",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.label,
      validation: Yup.string()
        .min(2, "Column label must be at least 2 characters")
        .required("Column label is required"),
    },
    {
      label: "Display Order",
      field: "order",
      defaultValue: 1,
      inputType: "number",
      render: (data) => data.order,
      validation: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .integer("Display order must be an integer")
        .min(1, "Display order must be at least 1")
        .max(1000, "Display order must be at most 1000")
        .required("Display order is required"),
    },
    {
      label: "Active",
      field: "active",
      defaultValue: true,
      inputType: "checkbox",
      render: (data) => data.active,
    },
  ];

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }

  const validationSchema = Yup.object().shape(yupShape);

  return (
    <FormSJ
      storeApi={storeApi}
      apiToReset={listViewApi}
      config={config}
      validationSchema={validationSchema}
      relatedListConfig={[]}
      secLevel={secLevel}
      formTitle='List View'
      table='listView'
      redirect='listView'
    />
  );
};

export default requireAuth(ListViewForm);
