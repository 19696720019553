import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const securityLevelApi = createApi({
  reducerPath: "securityLevels",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/securityLevel`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchSecurityLevels: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "SecurityLevel" }, { type: "SecurityLevelList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchSecurityLevelById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "SecurityLevel" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateSecurityLevelById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "SecurityLevel" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
      deleteSecurityLevelById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "SecurityLevelList" }];
        },
        query: (id) => {
          return {
            url: `/${id}`,
            method: "DELETE",
          };
        },
      }),
      createSecurityLevel: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "SecurityLevelList" }];
        },
        query: (data) => {
          return {
            url: `/`,
            method: "POST",
            body: data,
          };
        },
      }),
      securityLevelExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "SecurityLevel" }, { type: "SecurityLevelList" }];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchSecurityLevelsQuery,
  useFetchSecurityLevelByIdQuery,
  useUpdateSecurityLevelByIdMutation,
  useDeleteSecurityLevelByIdMutation,
  useCreateSecurityLevelMutation,
  useSecurityLevelExportListQuery,
} = securityLevelApi;
export { securityLevelApi };
