import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const fieldSecurityLevelApi = createApi({
  reducerPath: "fieldSecurityLevel",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/fieldSecurityLevel`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchFieldSecurityLevel: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [
            { type: "FieldSecurityLevel" },
            { type: "FieldSecurityLevelList" },
          ];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchFieldSecurityLevelById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "FieldSecurityLevel" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateFieldSecurityLevelById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "FieldSecurityLevel" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
      fieldSecurityLevelExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [
            { type: "FieldSecurityLevel" },
            { type: "FieldSecurityLevelList" },
          ];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchFieldSecurityLevelQuery,
  useFetchFieldSecurityLevelByIdQuery,
  useUpdateFieldSecurityLevelByIdMutation,
  useFetchFieldSecurityLevelByTableQuery,
  useFieldSecurityLevelExportListQuery,
} = fieldSecurityLevelApi;
export { fieldSecurityLevelApi };
