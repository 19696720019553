import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = ({ size, className }) => {
  let pixels = "20px";

  if (size === "large") pixels = "100px";
  else if (size === "medium") pixels = "50px";
  className = className || "sj-color-primary";
  return (
    <Spinner
      className={className}
      animation='border'
      role='status'
      style={{
        width: pixels,
        height: pixels,
        margin: "auto",
        display: "block",
      }}
    >
      <span className='sr-only'></span>
    </Spinner>
  );
};

Loader.defaultProps = {
  size: "small",
};

export default Loader;
