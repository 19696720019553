import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import "./LeftNav.css";
import { toggleLeftNavCollapse } from "../store/slices/utilSlice";
import LeftNavIcon from "../components/LeftNavIcon";
import { LinkContainer } from "react-router-bootstrap";

const Arrow = ({ show }) => {
  return (
    <img
      src='/images/Arrow Down.svg'
      alt='arrow down'
      className={
        ((!show && "rotate-90 transition-arrow-all") ||
          "rotate-0 transition-arrow-transform") + " inline"
      }
    />
  );
};

const LeftNav = ({ className, isMobile }) => {
  const dispatch = useDispatch();
  //Get front end security from authSlice
  const hasSecLevel = useSelector((state) => {
    if (state.auth.secLevel) return true;
    else return false;
  });

  //This was split out to reduce the number of re-renders.
  //Multiple useSelector Calls: You can call useSelector multiple times within
  //a single function component, with each call returning a single field value3.
  //This way, a change in one field won’t cause re-renders due to other fields.
  const { isLeftNavCollapsed, isMobileNavOpen } = useSelector(
    (state) => state.util
  );

  const handleCollapse = () => {
    setShowAdmin(!isLeftNavCollapsed);
    setShowUser(!isLeftNavCollapsed);
    setShowWork(!isLeftNavCollapsed);

    dispatch(toggleLeftNavCollapse());
  };

  const [showAdmin, setShowAdmin] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [showWork, setShowWork] = useState(false);
  const [showUser, setShowUser] = useState(false);

  const secWork = useSelector((state) => {
    return (
      state.auth?.secLevel?.client?.leftnav ||
      state.auth?.secLevel?.project?.leftnav ||
      state.auth?.secLevel?.task?.leftnav ||
      false
    );
  });
  const secUser = useSelector((state) => {
    return (
      state.auth.secLevel?.user?.leftnav ||
      state.auth.secLevel?.department?.leftnav ||
      state.auth.secLevel?.title?.leftnav ||
      false
    );
  });

  const hasTimeSheetRead = useSelector((state) => {
    return state.auth.secLevel?.timeSheet?.leftnav || false;
  });
  const hasDepartmentRead = useSelector((state) => {
    return state.auth.secLevel?.department?.leftnav || false;
  });
  const hasTitleRead = useSelector((state) => {
    return state.auth.secLevel?.title?.leftnav || false;
  });
  const hasUserRead = useSelector((state) => {
    return state.auth.secLevel?.user?.leftnav || false;
  });
  //These are coming from Backend authenication
  const hasProjectRead = useSelector((state) => {
    return state.auth?.secLevel?.project?.leftnav || false;
    // return state.auth.secLevel?.project?.leftnav || false;
  });
  const hasTaskRead = useSelector((state) => {
    return state.auth?.secLevel?.task?.leftnav || false;
    // return state.auth.secLevel?.task?.leftnav || false;
  });
  const hasClientRead = useSelector((state) => {
    return state.auth?.secLevel?.client?.leftnav || false;
    // return state.auth.secLevel?.client?.leftnav || false;
  });
  const hasRateCardRead = useSelector((state) => {
    return state.auth.secLevel?.rateCard?.leftnav || false;
  });
  const hasRateRead = useSelector((state) => {
    return state.auth.secLevel?.rate?.leftnav || false;
  });
  const hasHolidayRead = useSelector((state) => {
    return state.auth.secLevel?.holiday?.leftnav || false;
  });
  const hasNumberMaintenanceUpdate = useSelector((state) => {
    return state.auth.secLevel?.numberMaintenance?.update || false;
  });
  const hasListViewUpdate = useSelector((state) => {
    return state.auth.secLevel?.listView?.update || false;
  });
  const hasFormViewUpdate = useSelector((state) => {
    return state.auth.secLevel?.formView?.update || false;
  });
  // const hasFieldSecurityLevelRead = useSelector((state) => {
  //   return state.auth.secLevel?.fieldSecurityLevel?.leftnav || false;
  // });
  const hasSecurityLevelRead = useSelector((state) => {
    return state.auth.secLevel?.securityLevel?.leftnav || false;
  });
  const hasRoleRead = useSelector((state) => {
    return state.auth.secLevel?.role?.leftnav || false;
  });
  const pivotFilterRead = useSelector((state) => {
    return state.auth.secLevel?.pivotFilter?.leftnav || false;
  });
  const hasImpersonate = useSelector(
    (state) => state?.auth?.secLevel?.impersonate || false
  );

  const showNav = hasSecLevel && ((isMobile && isMobileNavOpen) || !isMobile);
  const isCollapsed = isLeftNavCollapsed && !isMobile;

  return (
    <>
      {showNav && (
        <div
          className={
            className +
            " left-nav sj-bg-primary text-white py-2 z-90 overflow-visible " +
            (isCollapsed ? "width-75 pl-0 text-center" : "width-275 px-5")
          }
        >
          {hasTimeSheetRead && (
            <LeftNavIcon
              imagePath='TimeSheets Icon.svg'
              link='timeSheet'
              text='Time Sheets'
              level={1}
              isToggle={isCollapsed}
            />
          )}
          {secUser && !isCollapsed && (
            <div
              className='cursor-pointer left-nav-link'
              onClick={() => {
                setShowUser(!showUser);
              }}
            >
              User Hierarchy <Arrow show={showUser} />
            </div>
          )}
          {showUser && (
            <>
              {hasDepartmentRead && (
                <LeftNavIcon
                  imagePath='Departments Icon.svg'
                  link='department'
                  text='Departments'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}

              {hasTitleRead && (
                <LeftNavIcon
                  imagePath='Titles Icon.svg'
                  link='title'
                  text='Titles'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {hasUserRead && (
                <LeftNavIcon
                  imagePath='Users Icon.svg'
                  link='user'
                  text='Users'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
            </>
          )}
          {secWork && !isCollapsed && (
            <div
              className='cursor-pointer left-nav-link'
              onClick={() => {
                setShowWork(!showWork);
              }}
            >
              Work Hierarchy <Arrow show={showWork} />
            </div>
          )}
          {showWork && (
            <>
              {hasClientRead && (
                <LeftNavIcon
                  imagePath='Clients Icon.svg'
                  link='client'
                  text='Clients'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {hasProjectRead && (
                <LeftNavIcon
                  imagePath='Projects Icon.svg'
                  link='project'
                  text='Projects'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {hasTaskRead && (
                <LeftNavIcon
                  imagePath='Tasks Icon.svg'
                  link='task'
                  text='Tasks'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
            </>
          )}
          {hasRateCardRead && (
            <>
              <LeftNavIcon
                imagePath='RateCards Icon.svg'
                link='rateCard'
                text='Rate Cards'
                isToggle={isCollapsed}
              />
            </>
          )}
          {hasRateRead && (
            <>
              <LeftNavIcon
                imagePath='Rates Icon.svg'
                link='rate'
                text='Rates'
                isToggle={isCollapsed}
              />
            </>
          )}
          {hasHolidayRead && (
            <>
              <LeftNavIcon
                imagePath='Holiday Icon.svg'
                link='holiday'
                text='Holidays'
                isToggle={isCollapsed}
              />
            </>
          )}
          {pivotFilterRead && (
            <>
              <LeftNavIcon
                imagePath='PivotReport Icon.svg'
                link='pivotReport/-1'
                text='Pivot Report'
                isToggle={isCollapsed}
              />
            </>
          )}
          {hasImpersonate && (
            <>
              <LeftNavIcon
                imagePath='Users Icon.svg'
                link='impersonate'
                text='Impersonate'
                isToggle={isCollapsed}
              />
            </>
          )}
          {hasListViewUpdate && !isCollapsed && (
            <div
              className='cursor-pointer left-nav-link'
              onClick={() => {
                setShowAdmin(!showAdmin);
              }}
            >
              Admin <Arrow show={showAdmin} />
            </div>
          )}
          {showAdmin && (
            <>
              {hasNumberMaintenanceUpdate && (
                <LeftNavIcon
                  imagePath='Number Maintenance Icon.svg'
                  link='numberMaintenance'
                  text='Number Maintenance'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {hasListViewUpdate && (
                <LeftNavIcon
                  imagePath='List View Icon.svg'
                  link='listview'
                  text='List View Config'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {hasFormViewUpdate && (
                <LeftNavIcon
                  imagePath='Form View Icon.svg'
                  link='formView'
                  text='Form View Config'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
            </>
          )}
          {hasListViewUpdate && !isCollapsed && (
            <div
              className='cursor-pointer left-nav-link'
              onClick={() => {
                setShowAccess(!showAccess);
              }}
            >
              Access <Arrow show={showAccess} />
            </div>
          )}
          {showAccess && (
            <>
              {hasRoleRead && (
                <LeftNavIcon
                  imagePath='Role Icon.svg'
                  link='role'
                  text='Roles'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {hasSecurityLevelRead && (
                <LeftNavIcon
                  imagePath='Table Security Icon.svg'
                  link='securityLevel'
                  text='Security Levels'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {/* {hasFieldSecurityLevelRead && (
                <LeftNavIcon
                  imagePath='Table Security Icon.svg'
                  link='tableSecurityLevel'
                  text='Table Level Access'
                  level={2}
                  isToggle={isCollapsed}
                />
              )}
              {hasFieldSecurityLevelRead && (
                <LeftNavIcon
                  imagePath='Field Security Icon.svg'
                  link='fieldSecurityLevel'
                  text='Field Level Access'
                  level={2}
                  isToggle={isCollapsed}
                />
              )} */}
            </>
          )}
          {isMobile && (
            <>
              <div className='border-t border-white my-1 w-full'></div>
              <LeftNavIcon
                imagePath=''
                link='support'
                text='Support'
                level={1}
                isToggle={isCollapsed}
              />
              {/* <LinkContainer to='/support' className='cursor-pointer'>
                <div>Support</div>
              </LinkContainer> */}
              <LinkContainer to='/logout' className='cursor-pointer'>
                <div>Logout</div>
              </LinkContainer>
            </>
          )}
          {!isMobile && (
            <img
              onClick={handleCollapse}
              src='/images/Collapse Arrow.svg'
              alt='Collapse Left Nav'
              style={{ bottom: "3%" }}
              className={
                "cursor-pointer " +
                ((!isCollapsed && "-rotate-90 left250 transition-arrow-all2") ||
                  "rotate-90 left60 transition-arrow-all2")
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default LeftNav;
