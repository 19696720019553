import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const listViewApi = createApi({
  reducerPath: "listView",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/listView`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchListView: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "ListView" }, { type: "ListViewList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
      fetchListViewByTable: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "ListViewByTable" }];
        },
        query: (table) => {
          return {
            url: `/table/${table}`,
            method: "GET",
          };
        },
      }),
      fetchListViewById: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "ListView" }];
        },
        query: (obj) => {
          const { id, queryField, queryFieldID } = obj;
          return {
            url: `/${id}?queryField=${queryField}&queryFieldID=${queryFieldID}`,
            method: "GET",
          };
        },
      }),
      updateListViewById: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "ListView" }];
        },
        query: (data) => {
          return {
            url: `/${data.id}`,
            method: "PUT",
            body: data.body,
          };
        },
      }),
      listViewExportList: builder.query({
        providesTags: (result, error, arg) => {
          return [{ type: "ListView" }, { type: "ListViewList" }];
        },
        query: (obj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = obj;
          return {
            url: `/exportList/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchListViewQuery,
  useFetchListViewByIdQuery,
  useUpdateListViewByIdMutation,
  useFetchListViewByTableQuery,
  useListViewExportListQuery,
} = listViewApi;
export { listViewApi };
