import { Button, Placeholder } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { addMessage } from "../store/slices/messageSlice";
import RecordFilter from "../components/RecordFilter";
import SortableTable from "../components/table/SortableTable";
import ContentHeader from "../components/ContentHeader";
import Pagination from "../components/table/Pagination";
import useSort from "../hooks/useSort";
import { useSelector } from "react-redux";
import RLContentHeader from "./table/RLContentHeader";
import RefModalHeader from "./table/RefModalHeader";
import { useCheckAuth } from "../hooks/checkAuth";
import { useEncodedFilter } from "../hooks/parseFilter";
import { useRef } from "react";
import ExportListToExcel from "./ExportListToExcel";

import { cloneDeep } from "lodash";
import Loader from "./Loader";

const List = ({
  handleClick,
  config,
  keyFn,
  listApi,
  useFetchQuery,
  useExportQuery,
  recordNames,
  newButtonLink,
  defaultFilter,
  relatedListTitle,
  relatedListTitles, //Array of {title , isSelected}
  handleSwitchingRelatedTabs, //State for keeping track of the selected tab on the RL
  isRefModal,
  selectedFilterItems, //This is used for the selectable list
  showHeader,
  showPagination,
  maxPageSize,
  defaultSortBy,
  defaultSortOrder,
  canSort,
  className,
  hideLink,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);

  if (!showHeader && showHeader !== false) showHeader = true;
  if (!showPagination && showPagination !== false) showPagination = true;
  if (!canSort && canSort !== false) canSort = true;

  const navigate = useNavigate();
  //This is a useEffect that will redirect to login if a 401 is returned from the server

  const [page, setPage] = useState(parseInt(queryParams.get("page")) || 1); // queryParams.get("page") || 1);
  const pageSize = Math.min(
    parseInt(queryParams.get("pageSize")) || maxPageSize || 20,
    100
  );

  defaultFilter = defaultFilter || "";

  let queryFilter = queryParams.get("filter") || "";

  //This is technically from the Backend but the code is being reused here
  const parsedFilter = useEncodedFilter(queryFilter, config) || [];

  const { secLevel } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      secLevel: state.auth.secLevel[recordNames.listLink] || false,
    };
  });

  const canCreate = secLevel.create;

  //Set scroll on the content section so that when you scroll to the right it is fixed
  const headerRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        window.requestAnimationFrame(() => {
          headerRef.current.style.transform = `translateX(${window.scrollX}px)`;
        });
      }

      if (filterRef.current) {
        window.requestAnimationFrame(() => {
          filterRef.current.style.transform = `translateX(${window.scrollX}px)`;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty array means this effect runs once on mount and clean up on unmount

  ////Filter
  const [localFilter, setLocalFilter] = useState([]);
  const handleLocalFilterChange = (newFilter) => {
    if (newFilter.length > 0) {
      newFilter[0].logic = "";
    }

    setLocalFilter([...newFilter]);

    handlePathChange(getQueryString(newFilter));

    if (newFilter.length === 0) {
      let queryString = defaultFilter || "";
      setFilter(encodeURIComponent(queryString));
      setIsFilter(false);
    }
  };

  const getQueryString = (myFilter) => {
    const compKey = {
      is: "=",
      "is not": "!=",
      contains: "ISLIKE",
      "does not contain": "NOTLIKE",
      "starts with": "STARTSWITH",
      "ends with": "ENDSWITH",
      on: "=",
      after: ">",
      before: "<",
      equals: "N=",
      "does not equal": "N!=",
      "is greater than": "N>",
      "is less than": "N<",
    };

    let queryString = defaultFilter || "";

    for (let i = 0; i < myFilter.length; i++) {
      const item = myFilter[i];

      queryString += `${item.logic}${item.field.name}${compKey[item.comp]}${
        item.value
      }`;
    }

    return queryString;
  };

  const handleRunFilter = () => {
    let queryString = getQueryString(localFilter);
    setPage(1);

    setFilter(encodeURIComponent(queryString));
    handlePathChange(queryString);
  };

  const handlePathChange = (queryString, newPage) => {
    //Dont put the url in the path if the default filter is being used (i.e., relatedlist)
    //Handleclick is for modal
    if (defaultFilter || handleClick) return;

    const myPage = newPage || page;

    navigate(
      location.pathname +
        `?page=${myPage}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${queryString}`
    );
  };

  const handleShowFilter = (myBool) => {
    if (myBool && localFilter.length === 0) {
      let initialFilter = [];
      //Loop through the config item to get the first filter item
      for (let i = 0; i < config.length; i++) {
        const item = config[i];

        if (item.filterValue) {
          let startingComp = "contains";
          if (item.filterType === "Date") startingComp = "on";
          else if (item.filterType !== "String") startingComp = "is";

          initialFilter.push({
            field: {
              label: item.label,
              name: item.filterValue,
              type: item.filterType,
            },
            comp: startingComp,
            value: "",
            logic: "",
          });
          break;
        }
      }

      setLocalFilter(initialFilter);
    }

    setIsFilter(myBool);
  };

  const handleClearFilter = () => {
    let queryString = defaultFilter || "";
    setFilter(encodeURIComponent(queryString));
    setLocalFilter([]);
    setIsFilter(false);

    if (!handleClick && !relatedListTitles) {
      navigate(location.pathname);
    }
  };

  ////Sorting
  const startingSort = defaultSortBy || queryParams.get("sortBy") || null;
  const startingSortOrder =
    defaultSortOrder || queryParams.get("sortOrder") || null;

  const { sortOrder, sortBy, setSortColumn } = useSort(
    startingSort,
    startingSortOrder
  );

  const [isFilter, setIsFilter] = useState(false);
  let startingFilter = encodeURIComponent(defaultFilter);

  //Place the parsed filter form the url into the local filter
  if (parsedFilter.length > 0 && !isFilter) {
    setIsFilter(true);
    handleLocalFilterChange(parsedFilter);
    startingFilter += encodeURIComponent(getQueryString(parsedFilter));
  }

  const [filter, setFilter] = useState(() => {
    return startingFilter;
  });

  //Getting the data from RTK Query API
  const { data, error, isFetching, refetch } = useFetchQuery({
    page,
    pageSize,
    sortOrder,
    sortBy,
    filter,
  });

  // This was added to fix BUG0001 - Bug undefined on reference Dropdown.
  // I was resetting the listApi in the useCheckAuth hook but that was causing the data to be removed and return undefined
  isRefModal = isRefModal || false;
  useCheckAuth(error, listApi, isRefModal);

  const handleNew = () => {
    //This is setup in the page component
    if (newButtonLink) navigate(newButtonLink);
    else navigate(`/${recordNames.formLink}/-1`);
  };

  ///////////Pagination
  const changePage = (dir) => {
    if (page + dir > 0) {
      setPage(page + dir);
      let queryString = getQueryString(localFilter);
      handlePathChange(queryString, page + dir);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(
        addMessage({
          message: error?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    }
  }, [error, dispatch]);

  ///////////Content
  let content;
  if (isFetching) {
    if (relatedListTitle) {
      content = (
        <Placeholder animation='glow' className='flex grow justify-center'>
          <Placeholder className='RLPlaceHolder'>
            <div className='my-2'>
              <Loader />
            </div>
          </Placeholder>
        </Placeholder>
      );
    } else {
      content = (
        <div className='my-4'>
          <Loader />
        </div>
      );
    }
  } else if (data) {
    //Loop through data.securityLevel.fieldSecurityLevel[table][action] to get the fields that the user has access to
    //This data is coming from the Backend in the authentication.js controller under the getLoginObject function.
    //It is then stored on the user record and retrived with each request
    //Then loop through the config and remove the fields that the user does not have access to
    //The data is also removed on the server side and isn't sent to the client

    let returnData = data;
    if (selectedFilterItems) returnData = cloneDeep(data);

    const fieldSec =
      data.securityLevel.fieldSecurityLevel[recordNames.formLink]["read"];

    for (let i = 0; i < config.length; i++) {
      const item = config[i];
      if (!item.filterValue) continue;

      if (!fieldSec.includes(item.filterValue)) {
        config.splice(i, 1);
        i--;
      } else if (item.inputType === "reference") {
        if (!data.securityLevel.tableSecurityLevel[item.refTable].read) {
          config.splice(i, 1);
          i--;
        }
      }
    }

    //This is used to add the checkbox to the selectable list
    if (selectedFilterItems) {
      for (let j = 0; j < returnData.records.length; j++) {
        const record = returnData.records[j];
        record.isSelected = false;

        for (let i = 0; i < selectedFilterItems.length; i++) {
          const selectedItem = selectedFilterItems[i];

          if (selectedItem._id === record._id) {
            record.isSelected = true;
            break;
          }
        }
      }
    }

    content = (
      <>
        <SortableTable
          data={returnData.records}
          handleClick={handleClick}
          config={config}
          keyFn={keyFn}
          setSortColumn={setSortColumn}
          sortBy={sortBy}
          sortOrder={sortOrder}
          isFetching={isFetching}
          isRefModal={isRefModal}
          canSort={canSort}
          hideLink={hideLink || false}
        ></SortableTable>
        {/* {data.rowCount !== 0 && ( */}
        {showPagination && (
          <Pagination
            rowCount={data.rowCount}
            changePage={changePage}
            page={page}
            pageSize={pageSize}
            recordNamePlural={(!isRefModal && recordNames.title) || "records"} //The recordNames.title is Select a Client on the reference modal
          />
        )}
      </>
    );
  }

  /////Related list tab change
  const handleChangeSelectedRL = (index) => {
    //This will change the default filter piece of state. Handle clear filter needs to be called
    //after the default filter changes. This is done with a callback function

    handleSwitchingRelatedTabs(index);
  };

  //THis is used to track onMount
  const isFirstMount = useRef(true);
  const startingFilterRef = useRef("");
  //This is needed for the tabs to work
  //I want to call handleClearFilter after the defaultFilter has changed
  useEffect(() => {
    //Don't run on the first mount
    if (!isFirstMount.current) {
      if (defaultFilter !== filter && startingFilterRef.current !== filter) {
        handleClearFilter();
      }
    } else {
      // Set isFirstMount to false after the initial mount
      isFirstMount.current = false;
      startingFilterRef.current = filter;
    }
    // eslint-disable-next-line
  }, [defaultFilter]);

  let header = "";

  if (handleClick) {
    //assumption is that a modal will always have a handle click
    const leftButtons = (
      <div>
        {!isFilter && (
          <Button variant='primary' className='px-2' onClick={handleShowFilter}>
            <img src='/images/Filter.svg' alt='filter' />
          </Button>
        )}
        {isFilter && (
          <>
            <Button
              variant='primary'
              className='mx-2'
              onClick={() => {
                handleRunFilter(true);
              }}
            >
              Run
            </Button>
            <Button
              variant='primary'
              className='mx-2'
              onClick={() => {
                handleClearFilter(true);
              }}
            >
              Clear
            </Button>
            {/* <Button variant='primary'>Save</Button> */}
          </>
        )}
      </div>
    );

    const rightButtons = <div> </div>;

    header = (
      <RefModalHeader
        title={recordNames.title}
        rightButtons={rightButtons}
        leftButtons={leftButtons}
        subtitle={relatedListTitle}
        handleClearFilter={handleClearFilter}
      ></RefModalHeader>
    );
  } else if (relatedListTitles) {
    //assumption is that a Related List will always have a default filter
    const leftButtons = (
      <div>
        {!isFilter && (
          <Button variant='primary' className='px-2' onClick={handleShowFilter}>
            <img src='/images/Filter.svg' alt='filter' />
          </Button>
        )}
        {isFilter && (
          <>
            <Button
              variant='primary'
              className='mx-2'
              onClick={() => {
                handleRunFilter(true);
              }}
            >
              Run
            </Button>
            <Button
              variant='primary'
              className='mx-2'
              onClick={() => {
                handleClearFilter(true);
              }}
            >
              Clear
            </Button>
            {/* <Button variant='primary'>Save</Button> */}
          </>
        )}
      </div>
    );

    const rightButtons = (
      <div>
        {canCreate && (
          <Button variant='primary' onClick={handleNew} className='mx-2'>
            New {recordNames.title}
          </Button>
        )}
        {/* {canCreate && canDelete && (
          <Button variant='primary' onClick={handleNew}>
            Edit
          </Button>
        )} */}
        <Button
          className='px-2 mx-2'
          variant='primary'
          onClick={() => {
            if (isFilter) handleRunFilter();
            else refetch();
          }}
        >
          <img src='/images/Refresh.svg' alt='refresh' />
        </Button>
      </div>
    );

    header = (
      <RLContentHeader
        title={recordNames.title}
        rightButtons={rightButtons}
        leftButtons={leftButtons}
        subtitle={relatedListTitle}
        relatedListTitles={relatedListTitles}
        handleChangeSelectedRL={handleChangeSelectedRL}
        handleClearFilter={handleClearFilter}
      ></RLContentHeader>
    );
  } else {
    header = (
      <div ref={headerRef}>
        <ContentHeader title={recordNames.title}>
          {/* Left section */}
          <div>
            {!isFilter && (
              <Button
                variant='primary'
                className='px-2'
                onClick={handleShowFilter}
              >
                <img src='/images/Filter.svg' alt='filter' />
              </Button>
            )}
            {isFilter && (
              <>
                <Button
                  variant='primary'
                  className='mx-2'
                  onClick={() => {
                    handleRunFilter(true);
                  }}
                >
                  Run
                </Button>
                <Button
                  variant='primary'
                  className='mx-2'
                  onClick={() => {
                    handleClearFilter(true);
                  }}
                >
                  Clear
                </Button>
                {/* <Button variant='primary'>Save</Button> */}
              </>
            )}
          </div>

          {/* Right Section */}
          <div>
            {canCreate && (
              <Button
                variant='primary'
                className='px-3 mx-2'
                onClick={handleNew}
              >
                New
              </Button>
            )}
            <Button
              className='px-3 mx-2'
              variant='primary'
              onClick={() => {
                if (isFilter) handleRunFilter();
                else refetch();
              }}
            >
              <img src='/images/Refresh.svg' alt='refresh' />
            </Button>

            {useExportQuery && (
              <ExportListToExcel
                useExportQuery={useExportQuery}
                page={page}
                pageSize={pageSize}
                sortOrder={sortOrder}
                sortBy={sortBy}
                filter={filter}
                config={config}
                title={recordNames.title}
              />
            )}
          </div>
        </ContentHeader>
      </div>
    );
  }

  return (
    <>
      {showHeader && header}
      {showHeader && (
        <div className='flex justify-between px-0' ref={filterRef}>
          {isFilter && (
            <RecordFilter
              handleRunFilter={handleRunFilter}
              config={config}
              localFilter={localFilter}
              handleLocalFilterChange={handleLocalFilterChange}
            />
          )}
        </div>
      )}

      {content}
    </>
  );
};

export default List;
