import { Fragment } from "react";
import "./table.css";
import { LinkContainer } from "react-router-bootstrap";
import Loader from "../Loader";
import Form from "react-bootstrap/Form";

const Table = ({
  data,
  handleClick,
  config,
  keyFn,
  isFetching,
  isRefModal,
  hideLink,
}) => {
  const renderedHeaders = config.map((column) => {
    if (column.label === "i" || column.label === "checkbox") {
      return (
        <th key={column.label}>{isFetching && <Loader size='small' />}</th>
      );
    }

    if (column.header) {
      //Fragment is an element that lets you add attribures without
      //actually adding an element.
      return (
        <Fragment key={`${column.label}_header`}>{column.header()}</Fragment>
      );
    }

    return <th key={column.label}>{column.label}</th>;
  });

  const renderedRows = data.map((rowData) => {
    let link = "";
    let headerSet = false;
    const renderedCells = config.map((column, i) => {
      if (column.label === "i") {
        link = column.render(rowData);
        return (
          <td
            key={column.label + "-i"}
            className=' min-w-10 hidden md:table-cell'
          >
            {!hideLink && (
              <LinkContainer to={link} className='cursor-pointer inline'>
                <img src='/images/i.svg' alt='i' className='w-6' />
              </LinkContainer>
            )}
          </td>
        );
      } else if (column.label === "checkbox") {
        return (
          <td key={column.label}>
            {/* {rowData.isSelected.toString()} */}
            <Form.Check
              disabled={false}
              type='checkbox'
              name={rowData._id}
              checked={rowData.isSelected}
              onChange={() => {}}
            ></Form.Check>
          </td>
        );
      } else if (((i === 1 && !isRefModal) || i === 0) && !headerSet) {
        headerSet = true;
        return (
          <>
            <td key={column.label} className='hidden md:table-cell p-1.5'>
              {column.render(rowData)}
            </td>
            <td
              key={`${column.label}-mobile`}
              className='shadow-sj-btm rounded-t-sj bg-header-grey min-h-[60px] md:hidden mb-3 md:mb-0 flex justify-center overflow-hidden max-w-100'
            >
              {!hideLink && (
                <span className='md:hidden'>
                  <LinkContainer
                    to={link}
                    className='cursor-pointer inline m-3'
                  >
                    <img src='/images/i.svg' alt='i' className='w-10' />
                  </LinkContainer>
                </span>
              )}

              <div className='flex flex-col w-full justify-center'>
                <div> {column.label}</div>
                <div className='text-2xl'>{column.render(rowData)}</div>
              </div>
              {!hideLink && <span className='w-10'> </span>}
            </td>
          </>
        );
      }
      return (
        <>
          <td key={column.label} className='hidden md:table-cell p-2'>
            {column.render(rowData)}
          </td>
          <td
            key={`${column.label}-mobile`}
            className='table-cell md:hidden py-1 w-11/12 text-xl text-ellipsis overflow-hidden max-w-96'
          >
            <div className='flex w-full'>
              <div className='font-bold min-w-32 text-left pl-6'>
                {column.label}:
              </div>
              <div className='pl-2 text-left'>{column.render(rowData)}</div>
            </div>
          </td>
        </>
      );
    });

    let rowClass = handleClick ? "cursor-pointer row-hover" : "";
    rowClass +=
      " w-11/12 mx-auto my-7 md:m-0 rounded-sj md:rounded-0 shadow-sj md:shadow-none flex flex-col md:table-row bg-neutral-150 md:bg-white md:odd:bg-neutral-150";
    rowClass += " pb-5 md:pb-0";

    return (
      <tr
        key={keyFn(rowData)}
        onClick={() => {
          if (handleClick) handleClick(rowData);
        }}
        className={rowClass}
      >
        {renderedCells}
      </tr>
    );
  });

  return (
    <>
      <table>
        <thead className='hidden md:table-header-group'>
          <tr>{renderedHeaders}</tr>
        </thead>
        <tbody className='bg-white'>{renderedRows}</tbody>
      </table>
    </>
  );
};

export default Table;
