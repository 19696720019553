/**
 * Renders an input component with label, tooltip, and error message.
 * @param {Object} item - The item object containing properties for the input component.
 * @param {string} item.field - The unique identifier for the input component.
 * @param {string} item.label - The label text for the input component.
 * @param {string} item.inputType - The type of input component (e.g., "text", "number", "textarea").
 * @param {boolean} item.isDisabled - Indicates whether the input component is disabled.
 * @param {string} item.toolTip - The tooltip text for the input component.
 */
import React from "react";
import { Form, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

const InputSJ = ({
  item,
  errors,
  handleChange,
  state,
  className,
  colspan,
  xxl,
  xl,
}) => {
  let columns = item.inputType === "textarea" ? "12" : "6";
  if (colspan) {
    columns = colspan;
  }

  return (
    <Form.Group
      as={Col}
      md={columns}
      xl={xl}
      xxl={xxl}
      className={className}
      controlId={item.field}
      key={item.field}
    >
      <Form.Label>
        {item.label}
        {errors[item.field] &&
          errors[item.field]?.indexOf("required") !== -1 && (
            <span className='required'> *</span>
          )}
      </Form.Label>

      <OverlayTrigger
        placement='top'
        overlay={(item.toolTip && <Tooltip>{item?.toolTip}</Tooltip>) || <></>}
        // show={item.toolTip ? true : false}
      >
        <Form.Control
          disabled={item.isDisabled}
          type={item.inputType}
          as={item.inputType === "textarea" ? "textarea" : "input"}
          rows={item.inputType === "textarea" ? 2 : 1}
          placeholder={`Enter ${item.label}`}
          // min={item.inputType === "number" && "min=0"}
          name={item.field}
          value={state[item.field] || ""}
          onChange={handleChange}
        ></Form.Control>
      </OverlayTrigger>
      {errors[item.field] && (
        <span className='form-field-error'>{errors[item.field]}</span>
      )}
    </Form.Group>
  );
};

export default InputSJ;
