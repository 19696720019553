import React, { useEffect } from "react";
import requireAuth from "../requireAuth";
import ReferenceModal from "../components/ReferenceModal";
import List from "../components/List";

import {
  useFetchImpersonateUsersQuery,
  useImpersonateMutation,
} from "../store";
import { impersonateApi } from "../store/apis/impersonateApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LoadingButton from "../components/LoadingButton";
import { useDispatch } from "react-redux";
import { setCredentials } from "../store/index";
import { addMessage } from "../store/slices/messageSlice";

const Impersonate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasImpersonate = useSelector(
    (state) => state?.auth?.secLevel?.impersonate || false
  );

  const [impersonate, isFetching] = useImpersonateMutation();

  const [showModal, setShowModal] = React.useState(true);

  useEffect(() => {
    if (!hasImpersonate) navigate("/");
  }, [hasImpersonate, navigate]);

  if (!hasImpersonate) {
    return <div>Not Authorized</div>;
  }

  const refModalListConfig = {
    config: [
      {
        label: "Name",
        render: (user) => user.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Company",
        render: (user) => user.company?.name,
        sortValue: "company",
        // render: (user) => <div className={`p-3 m-2 ${user.color}`}></div>,
        filterValue: "company",
        filterType: "String",
      },
      {
        label: "Active", //header label
        render: (user) => user.active.toString(), //How to render the cell
        sortValue: "active",
        filterValue: "active",
        filterType: "Boolean",
      },
    ],
    keyFn: (user) => {
      return user._id;
    },
    recordNames: {
      title: "Select a User",
      formLink: "user",
      listLink: "users",
    },
    listApi: impersonateApi,
    useFetchQuery: useFetchImpersonateUsersQuery,
  };

  const handleRowSelect = async (record) => {
    setShowModal(false);
    const data = await impersonate({ id: record._id });
    dispatch(setCredentials(data));
    if (data?.data?.roles.length === 0) {
      dispatch(
        addMessage({
          message:
            "You do not have any roles assigned to your account. Please contact your administrator.",
          variant: "danger",
        })
      );
    }
  };

  const modal = (
    <ReferenceModal
      onClose={() => {
        setShowModal(false);
      }}
    >
      {/* {refModalListConfig && ( */}
      <List
        handleClick={handleRowSelect}
        config={refModalListConfig.config}
        keyFn={refModalListConfig.keyFn}
        listApi={refModalListConfig.listApi}
        useFetchQuery={useFetchImpersonateUsersQuery}
        recordNames={refModalListConfig.recordNames}
        defaultFilter={""}
        // This was added to fix BUG0001 - Bug undefined on reference Dropdown.
        // I was resetting the listApi in the useCheckAuth hook but that was causing the data to be removed and return undefined
        isRefModal={true}
      ></List>
      {/* )} */}
    </ReferenceModal>
  );
  return (
    <>
      <div className='flex grow justify-center py-4'>
        <LoadingButton
          onClick={() => {
            setShowModal(true);
          }}
          isLoading={isFetching}
        >
          Pick User
        </LoadingButton>
      </div>
      {showModal && modal}
    </>
  );
};

export default requireAuth(Impersonate);
