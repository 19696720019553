import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const tableApi = createApi({
  reducerPath: "table",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/table`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      fetchTable: builder.query({
        providesTags: (result, error, arg) => {
          //Result is the data from the server
          // const tags = result.map((user) => {
          //   return { type: "User" };
          // });
          // return tags;
          return [{ type: "table" }, { type: "tableList" }];
        },
        query: (pageObj) => {
          const { page, pageSize, sortBy, sortOrder, filter } = pageObj;
          // console.log(filter);
          return {
            url: `/?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&filter=${filter}`,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { useFetchTableQuery } = tableApi;
export { tableApi };
