import { useFetchResetTokenQuery } from "../store";
import { useParams } from "react-router";
import Loader from "../components/Loader";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useResetPasswordMutation } from "../store";
import { useDispatch } from "react-redux";
import { addMessage } from "../store/slices/messageSlice";
import PasswordInput from "../components/PasswordInput";
import { LinkContainer } from "react-router-bootstrap";

const PasswordReset = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { data, error, isFetching } = useFetchResetTokenQuery(id);
  const [
    resetPassword,
    { data: resetData, error: resetError, isFetching: resetIsFetching },
  ] = useResetPasswordMutation();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});

  const loading = isFetching || resetIsFetching;
  const isError = error || resetError;
  const errorMessage = error?.data?.message || resetError?.data?.message;

  let yupShape = {
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/,
        "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character"
      )
      .required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confrim Password is required"),
  };

  const validationSchema = Yup.object().shape(yupShape);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          password,
          confirmPassword,
        },
        { abortEarly: false }
      );
      setErrors({});

      await resetPassword({
        id,
        password,
        confirmPassword,
      });
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (resetData?.success) {
      dispatch(
        addMessage({
          message: "Password succesfully updated!",
          variant: "success",
        })
      );
    } else if (!loading && resetData?.success === "false") {
      dispatch(
        addMessage({
          message: "Password Reset Failed",
          variant: "danger",
        })
      );
    }
  }, [resetData, loading, dispatch]);

  useEffect(() => {
    if (isError) {
      dispatch(addMessage({ message: errorMessage, variant: "danger" }));
    }
  }, [isError, errorMessage, dispatch]);

  return (
    <div className='my-20 px-16'>
      <div className='flex w-full justify-center'>
        <img className='logo mb-3' src='../images/Logo Dark.svg' alt='Logo' />
      </div>
      {loading && <Loader />}

      {resetData?.success && (
        <>
          <div className='flex flex-col justify-center text-center'>
            <h1 className='text-center text-2xl py-1'>Password Reset</h1>
            <p className='text-center mb-5 pt-4'>
              Please login with your new password!
            </p>
            <LinkContainer to='/login'>
              <div className='hover-underline cursor-pointer text-blue-500'>
                Return to Login
              </div>
            </LinkContainer>
          </div>
        </>
      )}

      {data?.success && !resetData && (
        <>
          <div className='flex flex-col justify-center text-center'>
            <h1 className='text-center text-2xl py-1'>Password Reset</h1>
            <p className='text-center mb-5 pt-4'>
              Please enter your new password.
            </p>
          </div>
          <Form
            onSubmit={submitHandler}
            className='max-w-md flex justify-center flex-col mx-auto py-10'
          >
            <Form.Group className='mb-3' controlId='password'>
              <Form.Label className='text-base'>
                Password
                {errors.password?.indexOf("Required") !== -1 && (
                  <span className='required font-bold'> *</span>
                )}
              </Form.Label>
              <PasswordInput
                password={password}
                onChange={setPassword}
                className='rounded-lg'
              />
              {errors.password && (
                <span className='form-field-error'>{errors.password}</span>
              )}
            </Form.Group>
            <Form.Group className='mb-3' controlId='confirmPassword'>
              <Form.Label className='text-base'>
                Confirm Password
                {errors.confirmPassword?.indexOf("Required") !== -1 && (
                  <span className='required font-bold'> *</span>
                )}
              </Form.Label>
              <PasswordInput
                password={confirmPassword}
                onChange={setConfirmPassword}
                className='rounded-lg'
              />
              {errors.confirmPassword && (
                <span className='form-field-error'>
                  {errors.confirmPassword}
                </span>
              )}
            </Form.Group>
            <div className='flex justify-center py-3'>
              <Button
                variant='primary'
                type='submit'
                disabled={loading}
                className='w-full my-1 bg-primary-light font-normal rounded-lg hover:rounded-lg hover:font-normal'
              >
                Submit
              </Button>
            </div>
          </Form>
        </>
      )}

      {(!data?.success || resetData?.success === false) && !loading && (
        <>
          <div className='flex flex-col justify-center text-center'>
            <h1 className='text-center text-2xl py-1'>
              Password Reset Link Expired
            </h1>
            <p className='text-center pt-4'>This link has expired.</p>
            <p className='text-center mb-5 pt-4'>
              Please get a new link by clicking the forgot password button on
              the login page.
            </p>
            <LinkContainer to='/login'>
              <div className='hover-underline cursor-pointer text-blue-500'>
                Return to Login
              </div>
            </LinkContainer>
          </div>
        </>
      )}
    </div>
  );
};

export default PasswordReset;
