import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { numberMaintenanceApi } from "../store/apis/numberMaintenanceApi";
import {
  useFetchNumberMaintenancesQuery,
  useNumberMaintenanceExportListQuery,
} from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
// import { useFinalListConfig } from "../hooks/listHooks";

const NumberMaintenanceList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } =
    useFetchListViewByTableQuery("numberMaintenance");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (numberMaintenance) =>
        `/numberMaintenance/${numberMaintenance._id}`,
    },
    {
      label: "Record Type",
      render: (numberMaintenance) => numberMaintenance?.recordType?.name,
      sortValue: "recordType",
      filterValue: "recordType",
      filterType: "String",
    },

    {
      label: "Prefix",
      render: (numberMaintenance) => numberMaintenance.prefix,
      sortValue: "prefix",
      filterValue: "prefix",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Counter",
      render: (numberMaintenance) => numberMaintenance.counter,
      sortValue: "counter",
      filterValue: "counter",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Digits",
      render: (numberMaintenance) => numberMaintenance.digits,
      sortValue: "digits",
      filterValue: "digits",
      filterType: "Number", //This is only used for the comparison logic on the front end.
    },

    {
      label: "Updated At", //header label
      render: (numberMaintenance) => {
        return new Date(numberMaintenance.updatedAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "updatedAt",
      filterValue: "updatedAt",
      filterType: "Date",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (numberMaintenance) => {
    return numberMaintenance._id;
  };

  const recordNames = {
    title: "Number Main.",
    formLink: "numberMaintenance",
    listLink: "numberMaintenance", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  // const { finalConfig } = useFinalListConfig(
  //   data,
  //   config,
  //   recordNames.formLink
  // );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={config}
        keyFn={keyFn}
        listApi={numberMaintenanceApi}
        useFetchQuery={useFetchNumberMaintenancesQuery}
        useExportQuery={useNumberMaintenanceExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(NumberMaintenanceList);
