import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchRateByIdQuery,
  useUpdateRateByIdMutation,
  useDeleteRateByIdMutation,
  useCreateRateMutation,
  useFetchRateCardsQuery,
  useFetchTitlesQuery,
} from "../store";
import { rateApi } from "../store/apis/rateApi";
import { rateCardApi } from "../store/apis/rateCardApi";
import { titleApi } from "../store/apis/titleApi";
import { formViewApi } from "../store/apis/formViewApi";

import * as Yup from "yup";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

const RateForm = () => {
  const storeApi = {
    fetch: useFetchRateByIdQuery,
    update: useUpdateRateByIdMutation,
    delete: useDeleteRateByIdMutation,
    create: useCreateRateMutation,
  };

  //Get the data from the form view. This will be used to configure the form
  const { data, error, isLoading } = useFetchFormViewByTableQuery("rate");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel] = useSecLevel(["rate"]);

  const config = [
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Rate",
      field: "rate",
      defaultValue: 0,
      inputType: "number",
      render: (data) => data.rate,
      validation: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, "Rate cannot be negative")
        .required("Rate is required"),
    },
    {
      label: "Rate Card",
      field: "rateCard",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.rateCard?.name || "",
      id: (data) => data?.rateCard?._id || "",
      useFetchQuery: useFetchRateCardsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid rate card",
          excludeEmptyString: true,
        })
        .required("Rate Card is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (rateCard) => rateCard.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Number",
            render: (rateCard) => rateCard.number,
            sortValue: "number",
            // render: (rateCard) => <div className={`p-3 m-2 ${rateCard.color}`}></div>,
            filterValue: "number",
            filterType: "String",
          },
          {
            label: "Active", //header label
            render: (rateCard) => rateCard.active.toString(), //How to render the cell
            sortValue: "active",
            filterValue: "active",
            filterType: "Boolean",
          },
        ],
        keyFn: (rateCard) => {
          return rateCard._id;
        },
        recordNames: {
          title: "Select a Rate Card",
          formLink: "rateCard",
          listLink: "rateCards",
        },
        listApi: rateCardApi,
        useFetchQuery: useFetchRateCardsQuery,
      },
    },
    {
      label: "Title",
      field: "title",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.title?.name || "",
      id: (data) => data?.title?._id || "",
      useFetchQuery: useFetchTitlesQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Select a valid title",
          excludeEmptyString: true,
        })
        .required("Title is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (title) => title.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
        ],
        keyFn: (title) => {
          return title._id;
        },
        recordNames: {
          title: "Select a Title",
          formLink: "title",
          listLink: "titles",
        },
        listApi: titleApi,
        useFetchQuery: useFetchTitlesQuery,
      },
    },
  ];

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={rateApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={[]}
        secLevel={secLevel}
        formTitle='Rate'
        table='rate'
        redirect='rate'
      />
    ))
  );
};

export default requireAuth(RateForm);
