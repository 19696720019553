import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchNumberMaintenanceByIdQuery,
  useUpdateNumberMaintenanceByIdMutation,
  useDeleteNumberMaintenanceByIdMutation,
  useCreateNumberMaintenanceMutation,
} from "../store";
import { numberMaintenanceApi } from "../store/apis/numberMaintenanceApi";
import { formViewApi } from "../store/apis/formViewApi";

import * as Yup from "yup";
import { useSecLevel } from "../hooks/formHooks";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

const NumberMaintenanceForm = () => {
  const storeApi = {
    fetch: useFetchNumberMaintenanceByIdQuery,
    update: useUpdateNumberMaintenanceByIdMutation,
    delete: useDeleteNumberMaintenanceByIdMutation,
    create: useCreateNumberMaintenanceMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } =
    useFetchFormViewByTableQuery("numberMaintenance");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  const [secLevel] = useSecLevel(["numberMaintenance"]);

  const config = [
    {
      label: "Record Type",
      field: "recordType",
      defaultValue: "",
      inputType: "text",
      render: (data) => data?.recordType?.name,
    },
    {
      label: "Prefix",
      field: "prefix",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.prefix,
    },
    {
      label: "Digits",
      field: "digits",
      defaultValue: "0",
      inputType: "number",
      render: (data) => data.digits,
      validation: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, "Digits cannot be negative")
        .required("Digits is required"),
    },
    {
      label: "Counter",
      field: "counter",
      defaultValue: "0",
      inputType: "number",
      render: (data) => data.counter,
      validation: Yup.number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0, "Counter cannot be negative")
        .required("Counter is required"),
    },
  ];

  //We are not allowing the user to change the layout so we dont need to use this
  // const { finalConfig, validationSchema } = useFinalConfig(data, config);

  let yupShape = {};
  for (let i = 0; i < config.length; i++) {
    const item = config[i];
    if (item.validation) yupShape[item.field] = item.validation;
  }
  const validationSchema = Yup.object().shape(yupShape);

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={numberMaintenanceApi}
        config={config}
        validationSchema={validationSchema}
        relatedListConfig={[]}
        secLevel={secLevel}
        formTitle='Number Main.'
        table='numberMaintenance'
        redirect='numberMaintenance'
      />
    ))
  );
};

export default requireAuth(NumberMaintenanceForm);
