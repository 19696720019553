import React from "react";
import { Alert } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./message.css";

const Message = ({ variant, children, clearMessage, i }) => {
  const [showAlert, setShowAlert] = useState(true);
  const [extraClasses, setExtraClasses] = useState();
  const timeDisplayed = 5000;
  const [timeLeft, setTimeLeft] = useState(timeDisplayed - 500); // 3000 milliseconds (3 seconds)

  const handleDismiss = () => {
    if (clearMessage) clearMessage();
    setShowAlert(false);
  };

  // Call dismiss after 5 seconds
  if (variant !== "danger") {
    setTimeout(() => {
      handleDismiss();
      setExtraClasses("");
    }, timeDisplayed);
    //remove fadeout from class after 5 seconds
    setTimeout(() => {
      setExtraClasses("fade-out");
    }, timeDisplayed - 500);
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 100); // Decrease timeLeft by 100 milliseconds
    }, 100);

    // Cleanup: clear the interval when the component unmounts
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const progressBarWidth = (timeLeft / (timeDisplayed - 300)) * 100;

  return (
    <>
      {showAlert && (
        <Alert
          variant={variant}
          className={`fixed z-1000 left-1-2 translate-x-2-4 w-9-12 flex justify-between items-center shadow-sj-btm rounded-2xl ${extraClasses} topM-${i}`}
        >
          {children}
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='alert'
            aria-label='Close'
            onClick={handleDismiss}
          ></button>
          {variant !== "danger" && (
            <div
              className='rounded-2xl'
              style={{
                width: `${progressBarWidth}%`,
                height: "4px",
                backgroundColor:
                  (variant === "success" && "#198754") ||
                  (variant === "warning" && "#ffc107") ||
                  "0d6efd",
                position: "absolute",
                bottom: -0.5,

                left: 10,
                transition: "width 100ms linear",
              }}
            />
          )}
        </Alert>
      )}
    </>
  );
};

Message.defaultProps = {
  variant: "info",
};

export default Message;
