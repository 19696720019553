import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "./CONSTANTS";

const ticketApi = createApi({
  reducerPath: "tickets",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/ticket`,
    credentials: "include",
  }),
  endpoints(builder) {
    return {
      createTicket: builder.mutation({
        invalidatesTags: (result, error, arg) => {
          return [{ type: "TicketList" }];
        },
        query: (data) => {
          return {
            url: `/`,
            method: "POST",
            body: data,
          };
        },
      }),
    };
  },
});

export const { useCreateTicketMutation } = ticketApi;
export { ticketApi };
