import { createSlice } from "@reduxjs/toolkit";
import { reset } from "../actions";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const authSlice = createSlice({
  name: "auth",
  initialState: {
    // token: (userInfoFromStorage && userInfoFromStorage.token) || "",
    name: (userInfoFromStorage && userInfoFromStorage.name) || "",
    id: (userInfoFromStorage && userInfoFromStorage.id) || "",
    company: (userInfoFromStorage && userInfoFromStorage.company) || "",
    roles: (userInfoFromStorage && userInfoFromStorage.roles) || "",
    secLevel: (userInfoFromStorage && userInfoFromStorage.secLevel) || "",
    leftNav: (userInfoFromStorage && userInfoFromStorage.leftNav) || "",
    timezone:
      (userInfoFromStorage && userInfoFromStorage.timezone) ||
      "America/Chicago",
  },
  reducers: {
    setCredentials(state, action) {
      const user = action.payload.data;
      // state.token = user.token;
      state.name = user.name;
      state.id = user.id;
      state.company = user.company;
      state.secLevel = user.secLevel; //This is populated on auth
      state.roles = user.roles;
      state.timezone = user.timezone || "America/Chicago";
      state.leftNav = user.leftNav || "";
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          // token: user.token,
          name: user.name,
          id: user.id,
          company: user.company,
          roles: user.roles,
          secLevel: user.secLevel,
          timezone: user.timezone || "America/Chicago",
          leftNav: user.leftNav || "",
        })
      );
    },
  },
  //Resetting the user record on logout
  extraReducers(builder) {
    builder.addCase(reset, (state, action) => {
      //What do you want to do when the movie/reset action is dispatched?
      localStorage.removeItem("userInfo");
      return { token: "", name: "", company: "" };
    });
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;
export const authReducer = authSlice.reducer;
