import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
  name: "messages",
  initialState: {
    data: [],
  },
  reducers: {
    addMessage(state, action) {
      for (let message of state.data) {
        if (message.message === action.payload.message) {
          return;
        }
      }

      state.data.push({
        message: action.payload.message,
        variant: action.payload.variant || "info",
        id: Date.now().toString(),
      });
    },
    removeMesaage(state, action) {
      state.data = state.data.filter((message) => {
        return message.id !== action.payload;
      });
    },
  },
});

export const { addMessage, removeMesaage } = messageSlice.actions;
export const messageReducer = messageSlice.reducer;
