import List from "../components/List";
import requireAuth from "../requireAuth";

import Loader from "../components/Loader";
import { Container } from "react-bootstrap";

import { holidayApi } from "../store/apis/holidayApi";
import { useFetchHolidaysQuery, useHolidayExportListQuery } from "../store";
import { useSelector } from "react-redux";

//List View imports
import { listViewApi } from "../store/apis/listViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchListViewByTableQuery } from "../store";
import { useFinalListConfig, formatDate } from "../hooks/listHooks";

const HolidayList = () => {
  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchListViewByTableQuery("holiday");

  //Get front end security from authSlice
  const { timezone } = useSelector((state) => {
    //state is the entire state object
    //You just need to reutnr the piece that you need
    return {
      timezone: state.auth.timezone, //use this to limit access to the form
    };
  });

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, listViewApi);

  ////////Config Columns/Headers
  //Columns
  const config = [
    {
      label: "i",
      render: (holiday) => `/holiday/${holiday._id}`,
    },

    {
      label: "Name",
      render: (holiday) => holiday?.name || "",
      sortValue: "name",
      filterValue: "name",
      filterType: "String", //This is only used for the comparison logic on the front end.
    },
    {
      label: "Date", //header label
      render: (holiday) => {
        return formatDate(holiday.date) || "";
        // return new Date(holiday.startDate).toString();
      }, //How to render the cell
      sortValue: "date",
      filterValue: "date",
      filterType: "Date",
    },

    {
      label: "Created At", //header label
      render: (holiday) => {
        return new Date(holiday.createdAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "createdAt",
      filterValue: "createdAt",
      filterType: "Date",
    },
    {
      label: "Updated At", //header label
      render: (holiday) => {
        return new Date(holiday.updatedAt).toLocaleString(undefined, {
          timeZone: timezone,
        });
      }, //How to render the cell
      sortValue: "updatedAt",
      filterValue: "updatedAt",
      filterType: "Date",
    },

    //Use this if you want to have a calcualted field!
    // {
    //   label: "Score Squared",
    //   render: (user) => user.score ** 2,
    //   sortValue: (user) => user.score ** 2,
    // },
  ];

  //What is the key for the data?
  const keyFn = (holiday) => {
    return holiday._id;
  };

  const recordNames = {
    title: "Holidays",
    formLink: "holiday",
    listLink: "holiday", //This is used in the list componenet to get the security level. It should match the name of the table in the Backend.
  };

  const { finalConfig } = useFinalListConfig(
    data,
    config,
    recordNames.formLink
  );

  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <List
        config={finalConfig}
        keyFn={keyFn}
        listApi={holidayApi}
        useFetchQuery={useFetchHolidaysQuery}
        useExportQuery={useHolidayExportListQuery}
        recordNames={recordNames}
      ></List>
    ))
  );
};

export default requireAuth(HolidayList);
