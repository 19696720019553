import { useLocation } from "react-router";
import { Outlet } from "react-router-dom";
import MessageArray from "./MessageArray";
import Header from "./Header";
import ConditionalLeftNav from "./ConditionalLeftNav";
import { useSelector } from "react-redux";

const ConditionalApp = () => {
  const location = useLocation();
  const notLoggedInRoutes = ["/login", "/register", "/passwordresetconfirm"];
  const isNotLoggedInRoute =
    notLoggedInRoutes.includes(location.pathname) ||
    location.pathname.includes("/reset");

  const secLevel = useSelector((state) => {
    if (state.auth.roles && state.auth.roles[0]) {
      return state.auth.roles[0] || false;
    } else {
      return false;
    }
  });
  const { isLeftNavCollapsed } = useSelector((state) => state.util);

  // .content{ margin-left: 275px; transition: margin-left .3s ease-in-out;}
  // .expandedContent{ margin-left: 75px; transition: margin-left .3s ease-in-out;}
  let contentClass = "md:content";
  if (isLeftNavCollapsed) contentClass = "md:expandedContent";
  if (!secLevel || isNotLoggedInRoute) contentClass = "";

  return (
    <>
      <MessageArray />
      {secLevel && !isNotLoggedInRoute && <Header />}
      {secLevel && !isNotLoggedInRoute && <ConditionalLeftNav />}

      <div className={contentClass}>
        <Outlet />
      </div>
    </>
  );
};

export default ConditionalApp;
