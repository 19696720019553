import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const PasswordInput = ({ password, onChange, className }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup>
      <Form.Control
        type={showPassword ? "text" : "password"}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={password}
        placeholder='Password'
        className={className}
        autoComplete='password'
      />
      <InputGroup.Text className={className}>
        <span
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          className='cursor-pointer'
        >
          {showPassword ? <EyeSlash /> : <Eye />}
        </span>
      </InputGroup.Text>
    </InputGroup>
  );
};

export default PasswordInput;
