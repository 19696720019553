/**
 * Renders an input component with label, tooltip, and error message.
 * @param {Object} item - The item object containing properties for the input component.
 * @param {string} item.field - The unique identifier for the input component.
 * @param {string} item.label - The label text for the input component.
 * @param {string} item.inputType - The type of input component (e.g., "text", "number", "textarea").
 * @param {boolean} item.isDisabled - Indicates whether the input component is disabled.
 * @param {string} item.toolTip - The tooltip text for the input component.
 */
import React from "react";
import { Form, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

const RadioButtonSJ = ({
  item,
  errors,
  handleChange,
  state,
  className,
  options,
  colspan,
  xl,
  xxl,
}) => {
  let columns = item.inputType === "textarea" ? "12" : "6";
  if (colspan) {
    columns = colspan;
  }

  const handleRadioClick = (e) => {
    handleChange({ target: { name: item.field, value: e.target.innerText } });
  };

  const optionContent = options.map((option, index) => {
    let optionClass = "grow cursor-pointer";
    if (index === 0) {
      optionClass += " rounded-left-2xl";
    } else if (index === options.length - 1) {
      optionClass += " rounded-right-2xl";
    }

    if (state[item.field] === option) {
      optionClass += " px-2 sj-bg-primary-light text-white";
    } else {
      optionClass += " bg-white pr-9 pl-5";
    }

    return (
      <div key={option} className={optionClass} onClick={handleRadioClick}>
        {option === state[item.field] && (
          <img
            src='/images/Radio Check.svg'
            alt='checked'
            className='mx-2 inline'
            style={{ transform: "translateY(-2px)" }}
          />
        )}
        {option}
      </div>
    );
  });

  return (
    <Form.Group
      as={Col}
      md={columns}
      xl={xl}
      xxl={xxl}
      className={className}
      controlId={item.field}
      key={item.field}
    >
      <Form.Label>
        {item.label}
        {errors[item.field] &&
          errors[item.field]?.indexOf("required") !== -1 && (
            <span className='required'> *</span>
          )}
      </Form.Label>

      <OverlayTrigger
        placement='top'
        overlay={(item.toolTip && <Tooltip>{item?.toolTip}</Tooltip>) || <></>}
        // show={item.toolTip ? true : false}
      >
        <div className='flex grow justify-center leading-10 shadow-sj rounded-2xl '>
          {optionContent}
        </div>
      </OverlayTrigger>
      {errors[item.field] && (
        <span className='form-field-error'>{errors[item.field]}</span>
      )}
    </Form.Group>
  );
};

export default RadioButtonSJ;
