/*
  UserForm > FormSJ > List (related list)

  The user form just contains config files and references to APIs. 
  You dont need to change any down stream componenets unless you are adding new functionality


*/

import FormSJ from "../components/FormSJ";
import requireAuth from "../requireAuth";
import {
  useFetchUserByIdQuery,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation,
  useCreateUserMutation,
  useFetchUsersRoleQuery,
  useFetchTimezoneQuery,
  useEmailUserInviteMutation,
  useResetUserPasswordMutation,
  useFetchUsersQuery,
} from "../store";
import * as Yup from "yup";
import { usersRoleApi } from "../store/apis/usersRoleApi";
import { userApi } from "../store/apis/userApi";
import { useParams } from "react-router";
import { useFetchDepartmentsQuery } from "../store";
import { useFetchTitlesQuery } from "../store";
import { departmentApi } from "../store/apis/departmentApi";
import { titleApi } from "../store/apis/titleApi";
import { timezoneApi } from "../store/apis/timezoneApi";

import { formViewApi } from "../store/apis/formViewApi";
import { useCheckAuth } from "../hooks/checkAuth";
import { useFetchFormViewByTableQuery } from "../store";
import { useSecLevel, useFinalConfig } from "../hooks/formHooks";
import { useDispatch } from "react-redux";
import { addMessage } from "../store/slices/messageSlice";

import { Container } from "react-bootstrap";
import Loader from "../components/Loader";
import { useEffect } from "react";

const UserForm = () => {
  const dispatch = useDispatch();
  const storeApi = {
    fetch: useFetchUserByIdQuery,
    update: useUpdateUserByIdMutation,
    delete: useDeleteUserByIdMutation,
    create: useCreateUserMutation,
  };

  //Get the data from the list view. This will be used to configure the list
  const { data, error, isLoading } = useFetchFormViewByTableQuery("user");

  //Checks to see if the user is logged in and has access to this page
  useCheckAuth(error, formViewApi);

  //Get front end security from authSlice
  const [secLevel, secLevelUsersRole] = useSecLevel(["user", "usersRole"]);

  const userRelatedListConfig = {
    config: [
      {
        label: "Name",
        render: (user) => user.name,
        sortValue: "name",
        filterValue: "name",
        filterType: "String", //This is only used for the comparison logic on the front end.
      },
      {
        label: "Email",
        render: (user) => user.email,
        sortValue: "email",
        // render: (user) => <div className={`p-3 m-2 ${user.color}`}></div>,
        filterValue: "email",
        filterType: "String",
      },
      {
        label: "Active", //header label
        render: (user) => user.active.toString(), //How to render the cell
        sortValue: "active",
        filterValue: "active",
        filterType: "Boolean",
      },
    ],
    keyFn: (user) => {
      return user._id;
    },
    recordNames: {
      title: "Select a User",
      formLink: "user",
      listLink: "users",
    },
    listApi: userApi,
    useFetchQuery: useFetchUsersQuery,
  };

  // This is the config array for the fields that will be shown on the form.
  let config = [
    {
      label: "First Name",
      field: "firstname",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.firstname,
      validation: Yup.string()
        .min(2, "First name must be at least 2 characters")
        .required("First name is required"),
    },
    {
      label: "Last Name",
      field: "lastname",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.lastname,
      validation: Yup.string()
        .min(2, "Last name must be at least 2 characters")
        .required("Last name is required"),
    },
    {
      label: "Email",
      field: "email",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.email,
      validation: Yup.string()
        .email("Invalid email address")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Invalid email address"
        )
        .required("Email is required"),
    },
    {
      label: "Department",
      field: "department",
      handleIsDisabled: (id, data) => true,
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.department?.name || "",
      id: (data) => data?.department?._id || "",
      useFetchQuery: useFetchDepartmentsQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Select a valid department",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (department) => department.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Department Id",
            render: (department) => department.departmentId,
            sortValue: "email",
            // render: (department) => <div className={`p-3 m-2 ${department.color}`}></div>,
            filterValue: "email",
            filterType: "String",
          },
        ],
        keyFn: (department) => {
          return department._id;
        },
        recordNames: {
          title: "Select a Department",
          formLink: "department",
          listLink: "departments",
        },
        listApi: departmentApi,
        useFetchQuery: useFetchDepartmentsQuery,
      },
    },
    {
      label: "Manager",
      field: "manager",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.manager?.name || "",
      id: (data) => data?.manager?._id || "",
      useFetchQuery: useFetchUsersQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid user",
          excludeEmptyString: true,
        })
        .required("Manager is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: userRelatedListConfig,
    },
    {
      label: "Title",
      field: "title",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.title?.name || "",
      id: (data) => data?.title?._id || "",
      useFetchQuery: useFetchTitlesQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Select a valid title",
          excludeEmptyString: true,
        })
        .required("Title is required"),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (title) => title.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
          {
            label: "Department",
            render: (title) => title?.department?.name || "",
            sortValue: "department",
            filterValue: "department",
            filterType: "reference",
          },
        ],
        keyFn: (title) => {
          return title._id;
        },
        recordNames: {
          title: "Select a Title",
          formLink: "title",
          listLink: "titles",
        },
        listApi: titleApi,
        useFetchQuery: useFetchTitlesQuery,
      },
    },
    {
      label: "Time Zone",
      field: "timezone",
      defaultValue: "",
      inputType: "reference",
      refDisplayField: "name",
      render: (data) => data?.timezone?.name || "",
      id: (data) => data?.timezone?._id || "",
      useFetchQuery: useFetchTimezoneQuery, //This is the api for getting the search results for the ref field dropdown and modal
      validation: Yup.string()
        .matches(/^[0-9a-fA-F]{24}$/, {
          message: "Please select a valid time zone",
          excludeEmptyString: true,
        })
        .notRequired(),
      //This is used to populate the modal list when you click the magnifying glass
      refModalListConfig: {
        config: [
          {
            label: "Name",
            render: (timezone) => timezone.name,
            sortValue: "name",
            filterValue: "name",
            filterType: "String", //This is only used for the comparison logic on the front end.
          },
        ],
        keyFn: (timezone) => {
          return timezone._id;
        },
        recordNames: {
          title: "Select a Time Zone",
          formLink: "timezone",
          listLink: "timezones",
        },
        listApi: timezoneApi,
        useFetchQuery: useFetchTimezoneQuery,
      },
    },
    {
      label: "Active",
      field: "active",
      render: (data) => data.active,
      defaultValue: true,
      inputType: "checkbox",
    },
    {
      label: "Number",
      field: "number",
      defaultValue: "",
      inputType: "text",
      render: (data) => data.number,
    },
    {
      label: "Start Date",
      field: "startDate",
      defaultValue: "",
      inputType: "date",

      render: (data) => {
        if (data.startDate) {
          return data?.startDate.toString().substring(0, 10);
        } else {
          return "";
        }
      },
      validation: Yup.date()
        .min(new Date(1900, 0, 1), "Date cannot be before the year 1900.")
        .max(new Date(2100, 0, 1), "Date cannot be after the year 2100.")
        .required("Start date is required")
        .nullable()
        .typeError("Start date must be a date"),
    },
    {
      label: "End Date",
      field: "endDate",
      defaultValue: "",
      inputType: "date",
      render: (data) => {
        if (data.endDate) {
          return data?.endDate.toString().substring(0, 10);
        } else {
          return "";
        }
      },
      validation: Yup.date()
        .optional()
        .min(new Date(1900, 0, 1), "Date cannot be before the year 1900.")
        .max(new Date(2100, 0, 1), "Date cannot be after the year 2100.")
        .nullable()
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        )
        .min(Yup.ref("startDate"), "End date must be after start date")
        .typeError("End date must be a date"),
    },
  ];

  const { id } = useParams();

  //This is an array of related lists. Each element will be a tab on the realted list

  let relatedListConfig = [
    //User Roles Related List
    {
      secLevel: secLevelUsersRole,

      //Function used as the key on the list
      keyFn: (usersRole) => {
        return usersRole._id;
      },

      recordNames: {
        title: "User Roles",
        formLink: "usersRole",
        listLink: "usersRole",
      },
      listApi: usersRoleApi,

      useFetchQuery: useFetchUsersRoleQuery,

      //Used to filter down the related list to the current record
      defaultFilter: `user._id=${id}^`,
      config: [
        {
          label: "i",
          render: (userRole) => `/usersRole/${userRole._id}`,
        },
        {
          label: "User",
          render: (userRole) => userRole.user.name,
          sortValue: "user.name",
          filterValue: "user",
          filterType: "String",
        },
        {
          label: "Role",
          render: (userRole) => userRole.role.name,
          sortValue: "role.name",
          filterValue: "role",
          filterType: "String",
        },
      ],
    },
  ];

  //Filter out any related lists the user doesn't have access to
  relatedListConfig = relatedListConfig.filter((rl) => {
    return rl.secLevel && rl.secLevel.read;
  });

  const { finalConfig, validationSchema } = useFinalConfig(data, config);

  const [
    emailUserInvite,
    { data: emailData, isLoading: emailLoading, error: emailError },
  ] = useEmailUserInviteMutation();

  const [
    resetUserPassword,
    { data: resetData, isLoading: resetLoading, error: resetError },
  ] = useResetUserPasswordMutation();

  useEffect(() => {
    if (emailError) {
      dispatch(
        addMessage({
          message: emailError?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    } else if (emailData) {
      dispatch(
        addMessage({
          message: emailData.message || "Email Invite Sent",
          variant: "success",
        })
      );
    }
  }, [emailData, emailError, dispatch]);

  useEffect(() => {
    if (resetError) {
      dispatch(
        addMessage({
          message: resetError?.data?.message || "Something went wrong",
          variant: "danger",
        })
      );
    } else if (resetData) {
      dispatch(
        addMessage({
          message: resetData.message || "User password reset email sent.",
          variant: "success",
        })
      );
    }
  }, [resetData, resetError, dispatch]);

  const uiActions = [
    {
      show: () => secLevel?.update || false,
      label: "Send Email Invite",
      action: async (id) => {
        try {
          await emailUserInvite({ id: id }).unwrap();
        } catch (error) {}
      },
      isLoading: emailLoading,
      loadingMessage: "Sending Email...",
    },
    {
      show: () => secLevel?.update || false,
      label: "Send Reset Password Email",
      action: async (id) => {
        try {
          await resetUserPassword({ id: id, reset: true }).unwrap();
        } catch (error) {}
      },
      isLoading: resetLoading,
      loadingMessage: "Resetting Password...",
    },
  ];
  return (
    (isLoading && (
      <Container className='py-4'>
        <Loader />
      </Container>
    )) ||
    (data && (
      <FormSJ
        storeApi={storeApi}
        apiToReset={userApi}
        config={finalConfig}
        validationSchema={validationSchema}
        relatedListConfig={relatedListConfig}
        secLevel={secLevel}
        formTitle='User'
        table='user'
        redirect='user'
        uiActions={uiActions}
      />
    ))
  );
};

export default requireAuth(UserForm);
