import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  useLoginUserMutation,
  useForgotPasswordMutation,
} from "../store/index";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../store/index";
import { addMessage } from "../store/slices/messageSlice";
import Loader from "../components/Loader";
import PasswordInput from "../components/PasswordInput";
import * as Yup from "yup";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const [loginUser, results] = useLoginUserMutation();
  const [forgotPassword, forgotPasswordResults] = useForgotPasswordMutation();

  useEffect(() => {
    if (results.isSuccess) {
      let redirect = localStorage.getItem("redirect") || "/";
      localStorage.removeItem("redirect");

      if (redirect === "/login") {
        redirect = "/";
      }

      // console.log(redirect);
      navigate(redirect, { replace: true });
    }
    //eslint-disable-next-line
  }, [results]);

  let yupShape = {
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .required("Email is Required"),
    password: Yup.string().required("Password is Required"),
  };

  const validationLoginSchema = Yup.object().shape(yupShape);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await validationLoginSchema.validate(
        {
          email,
          password,
        },
        { abortEarly: false }
      );
      setErrors({});

      const data = await loginUser({ email, password });
      dispatch(setCredentials(data));
      if (data?.data?.roles.length === 0) {
        dispatch(
          addMessage({
            message:
              "You do not have any roles assigned to your account. Please contact your administrator.",
            variant: "danger",
          })
        );
      }
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  let yupShapePasswordReset = {
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .required("Email is Required"),
  };

  const validationPasswordResetSchema = Yup.object().shape(
    yupShapePasswordReset
  );

  const submitForgotPassword = async (e) => {
    e.preventDefault();

    try {
      await validationPasswordResetSchema.validate(
        {
          email,
        },
        { abortEarly: false }
      );
      setErrors({});

      // console.log("Password Reset");
      //Update all of this to a new api call for password reset

      await forgotPassword({ email });
      navigate("/passwordresetconfirm");
    } catch (err) {
      if (err.inner) {
        const validationErrors = {};
        err.inner.forEach((e) => {
          validationErrors[e.path] = e.message;
        });
        setErrors(validationErrors);
      }
    }
  };

  let buttonText = "Log in";
  let loading = results.isLoading || forgotPasswordResults.isLoading;
  let isError = results.isError || forgotPasswordResults.isError;
  let errorMessage =
    results.error?.data?.message || forgotPasswordResults.error?.data?.message;

  if (loading) {
    buttonText = "Loading...";
  } else if (isForgotPassword) {
    buttonText = "Submit Password Reset";
  }

  useEffect(() => {
    if (isError) {
      dispatch(
        addMessage({
          message: errorMessage || "Something went wrong.",
          variant: "danger",
        })
      );
    }
  }, [isError, errorMessage, dispatch]);

  return (
    <div className='my-20 px-16'>
      <div className='flex w-full justify-center'>
        <img className='logo mb-3' src='../images/Logo Dark.svg' alt='Logo' />
      </div>
      {loading && <Loader />}

      {(isForgotPassword && (
        <>
          <h1 className='text-center text-2xl py-1'>Forgot Password</h1>
          <p className='text-center mb-5 pt-4'>
            Enter the email associated with your account.
          </p>
        </>
      )) || (
        <div className='flex flex-col justify-center text-center'>
          <p className='text-2xl py-1'>Log In</p>
          <p className='py-1'>or</p>
          <LinkContainer to='/register'>
            <div className='hover-underline cursor-pointer text-blue-500'>
              Create an account
            </div>
          </LinkContainer>
        </div>
      )}
      <Form
        onSubmit={!isForgotPassword ? submitHandler : submitForgotPassword}
        className='max-w-md flex justify-center flex-col mx-auto py-12'
      >
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label className='text-base'>
            Email
            {errors.email?.indexOf("Required") !== -1 && (
              <span className='required font-bold'> *</span>
            )}
          </Form.Label>
          <Form.Control
            type='email'
            autoComplete='email'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder='Enter email'
            className='rounded-lg'
          />
          {errors.email && (
            <span className='form-field-error'>{errors.email}</span>
          )}
        </Form.Group>
        {!isForgotPassword && (
          <Form.Group className='mb-3' controlId='formBasicPassword'>
            <Form.Label className='text-base'>
              Password
              {errors.password?.indexOf("Required") !== -1 && (
                <span className='required font-bold'> *</span>
              )}
            </Form.Label>
            <PasswordInput
              password={password}
              onChange={setPassword}
              className='rounded-lg'
            />
            {/* <Form.Control
                  type='password'
                  autoComplete='current-password'
                  value={password}
                  onChange={
                    setPassword(e.target.value);
                  }
                  placeholder='Password'
                /> */}
            {errors.password && (
              <span className='form-field-error'>{errors.password}</span>
            )}
          </Form.Group>
        )}
        <div className='flex justify-center py-3'>
          <Button
            variant='primary'
            type='submit'
            disabled={results.isLoading}
            className='w-full my-1 bg-primary-light font-normal rounded-lg hover:rounded-lg hover:font-normal'
          >
            {buttonText}
          </Button>
        </div>
        <div className='flex justify-between'>
          {(!isForgotPassword && (
            <div
              className='hover-underline cursor-pointer text-blue-500 mb-2 mx-2'
              onClick={() => {
                setIsForgotPassword(true);
              }}
            >
              Forgot Password?
            </div>
          )) || (
            <div
              className='hover-underline cursor-pointer text-blue-500 mb-2 mx-2'
              onClick={() => {
                setIsForgotPassword(false);
              }}
            >
              Back to the Login Page
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default Login;
