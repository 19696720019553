import "../contentHeader.css";
import "./timeSheetStyles.css";

import { Button } from "react-bootstrap";
import moment from "moment";
import React from "react";
import BackButton from "../BackButton";

const ContentHeader = ({
  title,
  subtitle,
  children,
  data,
  timeSheetState,
  handleSave,
  canUpdate,
  isLoading,
  isBlocking,
  createData,
  showNewTimeSheetModal,
  handleNewTimeEntryClick,
}) => {
  const backButtonContent = (
    <div className='ml-4'>
      <BackButton isBlocking={isBlocking} createData={createData} />
    </div>
  );

  let saveButtonContent = <></>;
  if (canUpdate) {
    saveButtonContent = (
      <div className='text-right my-3'>
        <Button
          onClick={handleSave}
          variant='primary'
          className='mr-5 mx-3'
          disabled={isLoading}
        >
          Save
        </Button>
      </div>
    );
  }

  const leftSide = (
    <div>
      <div className='content-header-title w-[255px] lg:w-[300px]'>
        <p className={"content-header-title-subtitle"}>{title}</p>
        {data && (
          <p className='content-header-subtitle'>{timeSheetState?.number}</p>
        )}
      </div>
      <span className='hidden lg:inline'>{backButtonContent}</span>
    </div>
  );

  const newTimeEntryButton = (
    <div className='flex grow justify-center'>
      {data && !showNewTimeSheetModal && (
        <Button onClick={handleNewTimeEntryClick} className='my-3 mx-3'>
          New Time Entry
        </Button>
      )}
    </div>
  );

  let timeEntryDayTotals = [];
  let timeEntryDayTotalsContent = <></>;

  if (data && data?.timeSheetUtil?.emptyWeek && timeSheetState.timeEntries) {
    timeEntryDayTotals = data.timeSheetUtil.emptyWeek.map((x) => {
      return {
        date: x.date,
        total: 0,
        hasNonWorkingTime: false,
        hasHolidayTime: false,
      };
    });

    for (let i = 0; i < timeSheetState.timeEntries.length; i++) {
      const timeEntry = timeSheetState.timeEntries[i];

      for (let k = 0; k < timeEntry.timeEntryDays.length; k++) {
        const timeEntryDay = timeEntry.timeEntryDays[k];

        const foundDay = timeEntryDayTotals.find((x) => {
          return moment(x.date).isSame(timeEntryDay.date, "day");
        });

        if (foundDay && timeEntry.hours !== 0) {
          foundDay.total += parseFloat(timeEntryDay.hours) || 0;

          if (
            timeEntry.nonWorkingCategory === "Holiday" &&
            !timeEntry.isWorkingTime &&
            timeEntryDay.hours > 0
          ) {
            foundDay.hasHolidayTime = true;
          }

          //If it is already set as non working time, don't change it
          if (
            !foundDay.hasNonWorkingTime &&
            timeEntryDay.hours > 0 &&
            !timeEntry.isWorkingTime
          )
            foundDay.hasNonWorkingTime = true;
        }
      }
    }

    timeEntryDayTotalsContent = timeEntryDayTotals.map((x, index) => {
      let extraClasses = "";
      if (x.hasHolidayTime) extraClasses = "timeEntry-holiday-circle";
      else if (x.hasNonWorkingTime)
        extraClasses = "timeEntry-nonWorking-circle";
      return (
        // React.Fragment is used to avoid the need for a wrapping div
        <React.Fragment key={x.date}>
          <div
            className={`timeEntryDayTotal font-normal text-base md:text-xl   ${extraClasses}`}
          >
            <div className='text-center lg:leading-loose'>
              <span>{moment.utc(x.date).format("ddd")}</span>
              <span className='hidden lg:inline'> - </span>
              <span className='hidden lg:inline'>
                {moment.utc(x.date).format("M/D")}
              </span>
              <div className='block lg:hidden'>
                {moment.utc(x.date).format("M/D")}
              </div>
            </div>
            <div className='text-center leading-loose'>
              <strong>
                {Number.isInteger(x.total) ? x.total : x.total.toFixed(2)}
              </strong>
            </div>
          </div>
          {index.toString() !== (timeEntryDayTotals.length - 1).toString() && (
            <div className='separator'></div>
          )}
        </React.Fragment>
      );
    });
  }

  let totalHours = 0;

  if (timeSheetState.timeEntries) {
    totalHours = 0;
    for (let i = 0; i < timeSheetState.timeEntries.length; i++) {
      const timeEntry = timeSheetState.timeEntries[i];

      for (let k = 0; k < timeEntry.timeEntryDays.length; k++) {
        const timeEntryDay = timeEntry.timeEntryDays[k];
        totalHours += parseFloat(timeEntryDay.hours) || 0;
      }
    }
  }

  let timeSheetInfoContent = <div></div>;
  if (data && timeSheetState.user) {
    timeSheetInfoContent = (
      <>
        <div className='mt-2 lg:my-3 text-xl text-center md:flex flex-col justify-center'>
          <div>
            <strong>User</strong> : {timeSheetState?.user?.name}
            <br />
            <strong>Week Starts </strong> :
            {timeSheetState?.weekStarts.toString().substring(0, 10)}
          </div>
          <span className='hidden lg:inline'>{newTimeEntryButton}</span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className='content-header z-30 relative h-[245px]  lg:h-[185px]'>
        {/* This is just here to make the justify between work with the back button and have the title center */}

        <div className='flex items-start justify-between grow'>
          {leftSide}
          <span className='hidden lg:inline'>{timeSheetInfoContent}</span>
          <div>
            <div className='content-header-title content-header-title-right flex flex-col justify-center xl:flex-row text-lg xl:text-[32px] w-[95px] xl:w-[300px] items-center '>
              <div className='mt-1 xl:mt-0'>Total</div>
              <span className='hidden xl:inline'>&nbsp;</span>
              <div>
                {Number.isInteger(totalHours)
                  ? totalHours
                  : totalHours.toFixed(2)}
              </div>
            </div>
            <span className='hidden lg:inline'>{saveButtonContent}</span>
          </div>
        </div>
        <div className='flex justify-center lg:hidden'>
          {timeSheetInfoContent}
        </div>
        <div className='flex justify-center lg:hidden'>
          {backButtonContent}
          {newTimeEntryButton}
          {saveButtonContent}
        </div>
      </div>
      <div className='flex items-center justify-center grow'>
        <div className='content-header-title timeSheetTotals w-[95%] xl:w-[75%] -translate-y-[50px] z-40 flex items-center justify-between px-4 xl:px-8 my-2 lg:my-0'>
          {timeEntryDayTotalsContent}
        </div>
      </div>
    </>
  );
};

export default ContentHeader;
