const Pagination = ({
  changePage,
  rowCount,
  page,
  pageSize,
  recordNamePlural,
}) => {
  let pageStart = (page - 1) * pageSize + 1;
  const pageEnd = Math.min(page * pageSize, rowCount);

  if (pageEnd === 0) pageStart = 0;

  const minPage = -(page - 1);
  const maxPage = Math.ceil(rowCount / pageSize) - page;

  return (
    <div className='flex flex-col justify-center py-3 bg-white rounded-br-2xl rounded-bl-2xl'>
      <div className='flex justify-center'>
        <div className='flex justify-center mr-5 min-w-32'>
          {pageStart !== 1 && pageStart !== 0 && (
            <>
              <img
                className='rotate-180 cursor-pointer mx-3 w-12'
                src='/images/Double Forward.svg'
                alt='Prior Page'
                onClick={() => {
                  changePage(minPage);
                }}
              />
              <img
                className='rotate-180 cursor-pointer w-6'
                src='/images/Forward.svg'
                alt='Prior Page'
                onClick={() => {
                  changePage(-1);
                }}
              />
            </>
          )}
        </div>

        <div className='text-center font-bold text-2xl' md={2}>
          {pageStart} to {pageEnd}
        </div>

        <div className='flex justify-center ml-5 min-w-32'>
          {pageEnd !== rowCount && (
            <>
              <img
                className='cursor-pointer w-6'
                src='/images/Forward.svg'
                alt='Prior Page'
                onClick={() => {
                  changePage(1);
                }}
              />
              <img
                className='cursor-pointer mx-3 w-12'
                src='/images/Double Forward.svg'
                alt='Prior Page'
                onClick={() => {
                  changePage(maxPage);
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className='flex grow justify-center'> of </div>
      <div className='flex grow justify-center text-2xl'>
        {rowCount} {recordNamePlural}
      </div>
    </div>
  );
};

export default Pagination;
