const useFinalListConfig = (data, config, formLink) => {
  if (!data) {
    return { finalConfig: [] };
  }

  let finalConfig = [
    {
      label: "i",
      render: (record) => `/${formLink}/${record._id}`,
    },
  ];

  if (data) {
    for (let j = 0; j < data.records.length; j++) {
      const dataItem = data.records[j];

      for (let i = 0; i < config.length; i++) {
        const configItem = config[i];

        if (dataItem.column.name === configItem.filterValue) {
          configItem.label = dataItem.label;

          //Add the reference table name to the configItem
          if (dataItem.column.type === "Reference") {
            configItem.inputType = "reference";
            configItem.refTable = dataItem.column.referenceTable.name;
          }

          finalConfig.push(configItem);
          break;
        }
      }
    }
  }

  return { finalConfig };
};

const formatDate = (date) => {
  if (!date) return "";
  let year = parseInt(date.substring(0, 4));
  let month = parseInt(date.substring(5, 7));
  let day = parseInt(date.substring(8, 10));
  return `${month}/${day}/${year}`;
};

export { useFinalListConfig, formatDate };
