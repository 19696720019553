import React from "react";
import { Button } from "react-bootstrap";

const CloseButton = ({ onClose }) => {
  return (
    <Button
      variant='danger'
      className='btn-filter absolute top-0 right-0 z-50 flex justify-center content-center btn-danger-modal px-'
      onClick={onClose}
    >
      <img src='/images/X.svg' alt='' className='btn-delete' />
    </Button>
  );
};

export default CloseButton;
