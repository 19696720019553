import React from "react";
import { Button } from "react-bootstrap";
import ModalSJ from "./ModalSJ";
import { useBeforeUnload } from "react-router-dom";
import { useBlocker } from "react-router-dom";
import { useEffect, useState } from "react";

const usePrompt = (message, isDirty) => {
  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (isDirty) {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, isDirty]
    ),
    { capture: true }
  );
};

const Prompt = ({ isDirty }) => {
  usePrompt(
    "You have unsaved changes, are you sure you want to leave?",
    isDirty
  );

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    // console.log(
    //   currentLocation.pathname,
    //   nextLocation.pathname,
    //   blocker.state,
    //   currentLocation.pathname !== nextLocation.pathname,
    //   "isDirty ",
    //   isDirty
    // );
    return isDirty && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (blocker.state === "blocked") {
      setShowModal(true);
    }
  }, [blocker]);

  ////////////Modal Section
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  //---- Buttons on Modal
  const actionBar = (
    <div>
      <Button
        variant='primary'
        onClick={() => {
          blocker.reset();
        }}
      >
        Cancel
      </Button>
      <Button
        variant='danger'
        className={"mx-3"}
        onClick={() => {
          blocker.proceed();
        }}
      >
        Leave
      </Button>
    </div>
  );

  //--Content of modal and title
  const modal = (
    <ModalSJ
      onClose={handleClose}
      actionBar={actionBar}
      title={"Confirm Leave"}
    >
      <p>You have unsaved changes are you sure you want to leave?</p>
    </ModalSJ>
  );

  return showModal && blocker.state === "blocked" ? modal : null;
};

export default Prompt;
