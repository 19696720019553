import Modal from "react-bootstrap/Modal";
import { useEffect, useState, useRef } from "react";
import CloseButton from "./CloseButton";

import { Button, Row, Col } from "react-bootstrap";
import "./slushBucket.css";

import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

function SlushBucket({
  onClose,
  actionBar,
  children,
  title,
  options,
  handleConfirm,
  handleChange,
  selectedColumns,
  canTotal,
}) {
  options = options.map((option) => {
    return { name: option, isTotal: false };
  });

  const [selected, setSelected] = useState(selectedColumns || []);
  const [available, setAvailable] = useState(
    options.filter((option) => {
      let found = false;
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].name === option.name) {
          found = true;
          break;
        }
      }
      return !found;
    })
  );
  const [selcetedItem, setSelectedItem] = useState(null);
  canTotal = canTotal || false;

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      handleChange(selected);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  //Clear Selections
  const handleReset = () => {
    setSelected([]);
    setAvailable(options);
    // console.log("Reset Clicked");
  };

  const availableHtml = createHtmlBuckets(
    available,
    selected,
    setAvailable,
    setSelected
  );

  const selectedHtml = createHtmlBuckets(
    selected,
    available,
    setSelected,
    setAvailable
  );

  function createHtmlBuckets(fromArry, toArray, setFrom, setTo) {
    return fromArry.map((option, i) => {
      let myClass = "option";

      if (i % 2 !== 0) {
        myClass = "option option-odd";
      }

      if (selcetedItem === option) {
        myClass = "option option-selected";
      }

      if (i === fromArry.length - 1) {
        myClass += " rounded-b-sj lg:rounded-b-none";
      }

      return (
        <div
          key={option.name}
          onClick={() => setSelectedItem(option)}
          onDoubleClick={() => {
            flipSide(fromArry, toArray, setFrom, setTo, option);
          }}
          className={myClass}
        >
          {option.name}
        </div>
      );
    });
  }

  function flipSide(fromArry, toArray, setFrom, setTo, option) {
    option.isTotal = false;
    setTo([...toArray, option]);
    setFrom(fromArry.filter((item) => item.name !== option.name));
  }

  //Makes sure all of the totals line up
  useEffect(() => {
    const checkIfTotalsLineUp = () => {
      if (canTotal === false) return;

      const selectedCopy = cloneDeep(selected);
      //You should not have an option that is totaled below one that is not totaled
      let foundFalse = false;
      for (let i = 0; i < selected.length; i++) {
        if (!selected[i].isTotal) {
          foundFalse = true;
        }

        if (foundFalse) {
          selected[i].isTotal = false;
        }
      }

      if (!isEqual(selectedCopy, selected)) {
        setSelected([...selected]);
      }
    };

    if (selected.length > 0) {
      checkIfTotalsLineUp();
    }
  }, [selected, canTotal]);

  const handleMiddleArrowClick = (direction) => () => {
    let isAvailable = false;
    // is the selected item on the available side or the selected side
    for (let i = 0; i < available.length; i++) {
      if (available[i].name === selcetedItem.name) {
        isAvailable = true;
        break;
      }
    }

    if (selcetedItem === null || selcetedItem.name === "") return;

    if (direction === "left" && !isAvailable) {
      flipSide(selected, available, setSelected, setAvailable, selcetedItem);
    } else if (direction === "right" && isAvailable) {
      flipSide(available, selected, setAvailable, setSelected, selcetedItem);
    }

    // checkIfTotalsLineUp();
  };

  const hadnleUpDownArrowsClick = (direction) => () => {
    let isSelected = false;
    let index = -1;
    // is the selected item on the available side or the selected side
    for (let i = 0; i < selected.length; i++) {
      if (selected[i] === selcetedItem) {
        isSelected = true;
        index = i;
        break;
      }
    }

    if (selcetedItem === null || selcetedItem.name === "" || !isSelected)
      return;

    if (direction === "up" && index > 0) {
      let temp = selected[index - 1];
      selected[index - 1] = selected[index];
      selected[index] = temp;
      setSelected([...selected]);
    } else if (direction === "down" && index < selected.length - 1) {
      let temp = selected[index + 1];
      selected[index + 1] = selected[index];
      selected[index] = temp;
      setSelected([...selected]);
    }
    // checkIfTotalsLineUp();
  };

  const totalHtmlOptions = createTotalBuckets();

  function flipTotal(option) {
    option.isTotal = !option.isTotal;

    if (option.isTotal === true) {
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].name === option.name) {
          break;
        } else {
          selected[i].isTotal = true;
        }
      }
    }

    setSelected([...selected]);
    return;
  }

  function createTotalBuckets() {
    return selected.map((option, i) => {
      let myClass = "option";

      if (i % 2 !== 0) {
        myClass = "option option-odd";
      }

      if (selcetedItem === option) {
        myClass = "option option-selected";
      }

      if (i === selected.length - 1) {
        myClass += " rounded-br-sj lg:rounded-b-none";
      }

      return (
        <div
          key={option.name + "total"}
          onClick={() => {
            flipTotal(option);
          }}
          className={myClass}
        >
          {/* {option.isTotal.toString()} */}
          &nbsp;
          {option.isTotal && (
            <img
              src='/images/Checkbox Sum.svg'
              alt='Total On'
              className='checkbox inline'
            />
          )}
          {!option.isTotal && (
            <img
              src='/images/Checkbox Empty.svg'
              alt='Dont Total On'
              className='checkbox'
            />
          )}
          &nbsp;
        </div>
      );
    });
  }

  const totalHtml = (
    <Col xs='3' lg='1' className='my-4 pl-0 z-70'>
      <div
        className='font-bold text-2xl md:text-xl xl:text-2xl  sj-bg-grey-100 rounded-tr-2xl shadow-sj-btm py-3 z-70 text-center'
        style={{ lineHeight: "2rem" }}
      >
        Total
      </div>
      <div className='h:auto lg:h-[350px] min-h-[40px] rounded-br-2xl shadow-sj-btm bg-white z-50 text-center '>
        {totalHtmlOptions}
      </div>
    </Col>
  );

  return (
    <>
      <Modal show={true} onHide={onClose} className='rounded-2xl' size='xl'>
        <Modal.Header className='sj-bg-grey-100 flex justify-center rounded-top-2xl shadow-sj-btm z-100'>
          <Modal.Title className='font-bold text-2xl'>{title}</Modal.Title>
          <CloseButton onClose={onClose} />
        </Modal.Header>
        <Modal.Body className='sj-bg-grey-200 shadow-sj-btm z-50'>
          <Row>
            <Col lg='1'></Col>
            <Col xs='10' lg='4' className='my-4'>
              <div className='flex justify-center font-bold text-2xl sj-bg-grey-100 grow w-full rounded-top-2xl shadow-sj-btm py-3 z-80'>
                Available
              </div>
              <div className='h-auto min-h-[40px] lg:h-[350px] rounded-bottom-2xl shadow-sj-btm bg-white z-50'>
                {availableHtml}
              </div>
            </Col>
            <Col xs='2' lg='1' className='flex flex-col justify-center'>
              <Button
                className='my-4'
                style={{ paddingBottom: "14px" }}
                onClick={handleMiddleArrowClick("right")}
              >
                <img
                  src='/images/Arrow Down Stroke.svg'
                  alt='arrow right'
                  className='-rotate-90 inline'
                />
              </Button>
              <Button
                style={{ paddingBottom: "14px" }}
                onClick={handleMiddleArrowClick("left")}
              >
                <img
                  src='/images/Arrow Down Stroke.svg'
                  alt='arrow left'
                  className='rotate-90 inline'
                />
              </Button>
            </Col>
            <Col
              xs={canTotal ? "7" : "10"}
              lg='4'
              className={"my-4 z-80 " + (canTotal ? "pr-0" : "")}
            >
              <div
                className='flex justify-center font-bold text-2xl sj-bg-grey-100 grow w-full rounded-t-sj shadow-sj-btm py-3 z-80'
                style={
                  (canTotal && {
                    borderTopRightRadius: "0px",
                  }) || { color: "inherit" }
                }
              >
                Selected
              </div>
              <div
                className='h-auto min-h-[40px] lg:h-[350px] rounded-b-sj shadow-sj-btm bg-white z-50'
                style={
                  (canTotal && {
                    borderBottomRightRadius: "0px",
                  }) || { color: "inherit" }
                }
              >
                {selectedHtml}
              </div>
            </Col>
            {canTotal && totalHtml}
            <Col xs='2' lg='1' className='flex flex-col justify-center'>
              <Button
                className='my-4'
                style={{ paddingBottom: "14px" }}
                onClick={hadnleUpDownArrowsClick("up")}
              >
                <img
                  src='/images/Arrow Down Stroke.svg'
                  alt='arrow up'
                  className='rotate-180 inline'
                />
              </Button>
              <Button
                style={{ paddingBottom: "14px" }}
                onClick={hadnleUpDownArrowsClick("down")}
              >
                <img
                  src='/images/Arrow Down Stroke.svg'
                  alt='arrow down'
                  className='inline'
                />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='sj-bg-C3 rounded-bottom-2xl border-none flex grow justify-between'>
          <Button onClick={handleReset}>Reset Columns</Button>
          <Button
            variant='primary'
            onClick={() => {
              handleConfirm(selected);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SlushBucket;
